import { createApi } from "./ServerService";
import { DELETE, PATCH, POST } from "./types";

export const fetchAllUsers = () => {
  return createApi(`/api/v1/users/company/all/`);
};
export const fetchUsers = (roles, currentPage) => {
  let urlString = "/api/v1/users/?";
  if (roles?.length > 0) {
    roles.forEach(role => {
      urlString = urlString + "role=" + role + "&";
    })
  }
  if (currentPage) {
    urlString = urlString + "page=" + currentPage;
  }
  return createApi(urlString);
};

export const fetchUsersByRole = (role) => {
  return createApi(`/api/v1/users/${role}/`);
};

export const createUser = (form) => {
  return createApi(`/api/v1/users/`, { method: POST, body: form });
};
export const updateUser = (form, id) => {
  return createApi(`/api/v1/users/${id}/`, { method: PATCH, body: form });
};
export const getUser = (id) => {
  return createApi(`/api/v1/users/${id}/`);
};
export const removeUser = (id) => {
  return createApi(`/api/v1/users/${id}/`, {method: DELETE});
};
