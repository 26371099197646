import React, { useState, useCallback } from 'react'
import Logotype_white_red from '../assets/Logotype_white_red.png'
import DMS_Logo_dark from '../assets/DMS_Logo_dark.png'
import ion_archive_white from '../assets/icons/ion_archive_white.svg'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CDropdownMenu,
  CDropdown,
  CDropdownItem,
  CDropdownToggle,
  CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  TheHeaderDropdown
}  from './index'
import {set, setSearch, setSound} from "../redux/actions/settingsActions"

import i18n from 'src/internationalize/internationalize'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ATSCard from './ATSCard'
import audio from "src/assets/sounds/accept.mp3";
import Search from 'src/components/Search'


const TheHeader = (props) => {
  
  const {t} = useTranslation();

  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.settings.sidebarShow)
  const breadCrumbTitle = useSelector(state => state.settings.breadCrumbTitle)
  const status = useSelector(state => state.settings.status)
  const searchText = useSelector(state => state.settings.searchText)
  const [searchValue, setSearchValue] = useState(searchText);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch(set(val))
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch(set(val))
  }

  const [lang, setLang] = useState(localStorage.getItem('lang'));

  const switchLang = (language) => {
    setLang(language);
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  }

  const selectedOrders = useSelector(state => state.order.selectedOrders);
  const accessToken = useSelector(state => state.auth.accessToken);

  const atsCard = useSelector(state => state.settings.atsCard)
  const isSoundOn = useSelector(state => state.settings.isSoundOn)
  const callingClient = useSelector(state => state.settings.callingClient)


  const toggleVoiceBtn = () => {
    dispatch(setSound(!isSoundOn))
    if(!isSoundOn){
      new Audio(audio).play()
    }
  }

  const createTestOrder = () => {
    fetch("https://dms.kg/api/v1/partner/orders/", {
      method: "POST",
      body: JSON.stringify(
        {"address_from": "GUM",
        "address_to": "Tsum",
        "city_id_from": 3,
        "city_id_to": 3,
        "sender_phone": "+996777114477",
        "sender_name": "sender Test",
        "receiver_phone": "+996777112233",
        "receiver_name": "Test User",
        "description": "description of partner order",
        "pays_receiver": true,
        "payment_method": "cash",
        "partner_order_id": "1",
        "login": "nurlande",
        "password": "nurlan"}
      )
    })
  }
  const createTestATS = () => {
    fetch("https://dms.kg/api/v1/clients/test_call/?phone_number=%2B996700556556", {
      method: "GET",
      headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
    })
  }

  const searchSubmit = useCallback((e) => {
    e.preventDefault();
    dispatch(setSearch(searchValue));
    !location.pathname?.includes("search-orders") && history.push("/search-orders")
  }, [dispatch, location, history, searchValue])
  

  return (
    <CHeader withSubheader>
      {atsCard && 
        <div className="rounded position-absolute position-relative bg-white text-dark" 
            style={{right: "0", top: "70vh", border: "1px Solid gray", width: "500px", zIndex: "9999999999999"}}>
              <ATSCard client={callingClient}/>
        </div>
      }
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <img alt="header logo" src={DMS_Logo_dark || Logotype_white_red} height="48"/>
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          {breadCrumbTitle.map((bc,i) => <span key={i}>
            {i > 0 && " / "}
            {t(bc)}
          </span>)}
        </CHeaderNavItem>
      </CHeaderNav>
      

      <CHeaderNav className="px-3">
        <CHeaderNavItem>
          <Search 
            placeholder={t("Search order...")} 
            searchSubmit={searchSubmit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </CHeaderNavItem>
        {selectedOrders.length >0 && <CHeaderNavItem>
          <Link to="/group-order">
            <CButton style={{borderRadius: "25px"}} size="sm" color="primary">
              <img alt="cart" src={ion_archive_white}/>
              {t("Упаковать")} ({selectedOrders.length})
            </CButton>
          </Link>
        </CHeaderNavItem>}
        <CHeaderNavItem>
          <span className="mx-1">{status}</span>
        </CHeaderNavItem>
        <CHeaderNavItem className="d-none">
          <CButton className="btn btn-primary" onClick={createTestOrder}>Order</CButton>
        </CHeaderNavItem>
        <CHeaderNavItem className="d-none">
          <CButton className="btn btn-primary" onClick={createTestATS}>ATS</CButton>
        </CHeaderNavItem>
        <CDropdown placement="end"> 
          <CDropdownToggle>
            {lang === "ru" ? 
            <><CIcon name="cif-ru" className="mr-1"/></>
          : <><CIcon name="cif-kg" className="mr-1"/></>
          }
          </CDropdownToggle>
          <CDropdownMenu placement="bottom-end">
              {
                lang === "ky" ?
                <CDropdownItem onClick={() => switchLang('ru')}>
                  <CIcon name="cif-ru" className="mr-1"/> Русский
                </CDropdownItem>
              : <CDropdownItem onClick={() => switchLang('ky')}>
                  <CIcon name="cif-kg" className="mr-1"/> Кыргызча
                </CDropdownItem> 
              }
          </CDropdownMenu>
        </CDropdown>
         <CHeaderNavItem>
           <CIcon onClick={toggleVoiceBtn} name={isSoundOn ? "cilVolumeHigh" : "cilVolumeOff"}/>
        </CHeaderNavItem>
        <TheHeaderDropdown/>
      </CHeaderNav>
    </CHeader>
  )
}

export default TheHeader
