import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateOrder from "./order-form/CreateOrder";
import EditOrder from "./order-form/EditOrder";
import OrderList from "./order-list/OrderList";

export const routelist = [
  { component: <CreateOrder />, path: "create-order" },
  { component: <EditOrder />, path: "edit-order/:id" },
  { component: <OrderList />, path: "orders" },
  { component: <OrderList />, path: "", exact: true },
];
export default function PartnerRouter() {
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        {routelist.map((r, i) => (
          <Route path={`${path}/${r.path}`} key={i} exact={r.exact}>
            <div>{r.component}</div>
          </Route>
        ))}
      </Switch>
    </>
  );
}
