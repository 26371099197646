import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastify } from "src/helpers/toast";
import { useConfirmModal, useModalState } from "src/helpers/useModal";
import { orderStatusChangeFetch } from "src/services/OrderService";

export const useTable = () => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose, onOpen } = useModalState();
  const { isConfirmOpen, onConfirmToggle, onConfirmClose, onConfirmOpen } =
    useConfirmModal();

  const [selectedOrder, setSelectedOrder] = useState();
  const [newField, setNewField] = useState({});
  const openDetails = useCallback(
    (order) => {
      setSelectedOrder(order);
      onOpen();
    },
    [onOpen]
  );
  const openConfirmChange = useCallback(
    (order, field, value) => {
      setSelectedOrder(order);
      onConfirmOpen();
      setNewField({ field: field, value: value });
    },
    [onConfirmOpen]
  );

  const confirmChange = useCallback(() => {
    orderStatusChangeFetch(selectedOrder?.id, newField?.value)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Operation succeeded"));
          onConfirmClose();
          setSelectedOrder(null);
          setNewField({});
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => toastify("error", "Error occured"));
  }, [newField?.value, onConfirmClose, selectedOrder?.id, t]);

  return {
    openDetails,
    openConfirmChange,
    selectedOrder,
    setSelectedOrder,
    isOpen,
    onToggle,
    onClose,
    onOpen,
    isConfirmOpen,
    onConfirmToggle,
    onConfirmClose,
    onConfirmOpen,
    field: newField.field,
    confirmChange,
  };
};
