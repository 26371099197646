import CIcon from "@coreui/icons-react";
import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastify } from "src/helpers/toast";
import { useModalState } from "src/helpers/useModal";
import { fetchPartners } from "src/services/PartnerService";
import PartnerForm from "./PartnerForm";

export default function PartnersTable({ partners, setPartners }) {
  const { isOpen, onToggle } = useModalState();

  const { t } = useTranslation();

  const [modalTitle, setModalTitle] = useState("");
  const [selectedPartner, setSelectedPartner] = useState(null);

  const onSuccess = () => {
    toastify("success", "Operation succeeded");
    fetchPartners().then((res) =>
      res.json().then((data) => {
        setPartners(data);
      })
    );
    onToggle();
  };

  const editPartner = (p) => {
    setSelectedPartner(p);
    onToggle();
    setModalTitle(t("Edit"));
  };
  const deletePartner = (p) => {
    setSelectedPartner(p);
    onToggle();
    setModalTitle(t("Delete"));
  };

  return (
    <div>
      <CButton
        variant="outline"
        color="success"
        className="float-right my-1"
        onClick={() => {
          setModalTitle("Create");
          setSelectedPartner(null);
          onToggle();
        }}
      >
        {t("Create")}
      </CButton>
      <div className="table-responsive-sm">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("ID")}</th>
              <th>{t("Name")}</th>
              <th>{t("Source")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {partners &&
              partners.map((p) => (
                <tr key={p.id}>
                  <td>{p.id}</td>
                  <td>{p.name}</td>
                  <td>{p.source}</td>
                  <td>
                    <CDropdown>
                      <CDropdownToggle color="secondary" size="sm">
                        <CIcon name="cil-menu" />
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem onClick={() => editPartner(p)}>
                          {t("Edit")}
                        </CDropdownItem>
                        <CDropdownItem onClick={() => deletePartner(p)}>
                          {t("Delete")}
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PartnerForm
        isOpen={isOpen}
        onToggle={onToggle}
        modalTitle={modalTitle}
        onSuccess={onSuccess}
        selectedPartner={selectedPartner}
      />
    </div>
  );
}
