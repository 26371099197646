import React from "react";
import { CCard, CRow, CCol, CAlert } from "@coreui/react";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { ClientFields } from "./ClientFields";
import { ParcelFields } from "./ParcelFields";
import { TariffFields } from "./TariffFields";
import { CourierFields } from "./CourierFields";
import { CommentFields } from "./CommentFields";
import { FormMap } from "./FormMap";
import { ButtonBar } from "./ButtonBar";

export function FormContainer({
  loading,
  errorField,
  allClientsSender,
  allClientsReceiver,
  emptySender,
  emptyAddressSender,
  selectClient,
  orderData,
  setPointSender,
  setPointReceiver,
  updateOrderData,
  getDistrictsFor,
  cities,
  geoSelect,
  districtsSender,
  districtsReceiver,
  promiseAddressOptions,
  promiseCityOptions,
  promiseDistrictOptions,
  allAddressesSender,
  allAddressesReceiver,
  setAllAddressesReceiver,
  handleSenderAddress,
  handleReceiverAddress,
  promiseClientOptions,
  profile,
  transports,
  transportSelect,
  sortedCourierList,
  courierList,
  packagesTypes,
  detectRedemptionFee,
  detectWeightFee,
  buttonClickable,
  submitCreate,
  edit,
  clearFields,
  tariffs,
  tariffSelect,
  setButtonClickable,
  sidebarShow,
  setMapApi,
  pointSender,
  pointReceiver,
  center,
  zoom,
  allFees,
  open,
  setOpen,
  cCourier,
  setCCourier,
  map,
}) {
  return (
    <CCard className="pb-0 mb-0">
      {loading ? (
        <div className="text-center py-5">
          <MiniSpinner size="lg" />
        </div>
      ) : (
        <div className="m-2 px-1">
          {errorField && (
            <CAlert color="danger">
              Error on {errorField.title}. {errorField.reason}
            </CAlert>
          )}
          <CRow>
            <CCol md="8">
              <CRow>
                <CCol className="mx-n2">
                  <ClientFields
                    role={"Sender"}
                    allClients={allClientsSender}
                    setAllAddressesReceiver={setAllAddressesReceiver}
                    emptyClient={emptySender}
                    emptyAddress={emptyAddressSender}
                    selectClient={selectClient}
                    orderData={orderData}
                    setPoint={setPointSender}
                    updateOrderData={updateOrderData}
                    cities={cities}
                    getDistrictsFor={getDistrictsFor}
                    geoSelect={geoSelect}
                    districts={districtsSender}
                    promiseAddressOptions={promiseAddressOptions}
                    promiseCityOptions={promiseCityOptions}
                    promiseDistrictOptions={promiseDistrictOptions}
                    allAddresses={allAddressesSender}
                    handleAddress={handleSenderAddress}
                    promiseClientOptions={(params) => promiseClientOptions('sender', params)}
                  />
                </CCol>
                <CCol className="mx-n2">
                  <ClientFields
                    role={"Receiver"}
                    allClients={allClientsReceiver}
                    setAllAddressesReceiver={setAllAddressesReceiver}
                    // emptyClient={emptyReceiver} emptyAddress={emptyAddressReceiver}
                    selectClient={selectClient}
                    orderData={orderData}
                    setPoint={setPointReceiver}
                    updateOrderData={updateOrderData}
                    cities={cities}
                    getDistrictsFor={getDistrictsFor}
                    geoSelect={geoSelect}
                    districts={districtsReceiver}
                    promiseAddressOptions={promiseAddressOptions}
                    promiseCityOptions={promiseCityOptions}
                    promiseDistrictOptions={promiseDistrictOptions}
                    allAddresses={allAddressesReceiver}
                    handleAddress={handleReceiverAddress}
                    promiseClientOptions={(params) => promiseClientOptions('receiver', params)}
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol className="mx-n2">
                  <CourierFields
                    orderData={orderData}
                    updateOrderData={updateOrderData}
                    sortedCourierList={sortedCourierList}
                    courierList={courierList}
                  />
                  <CommentFields
                    orderData={orderData}
                    updateOrderData={updateOrderData}
                    profile={profile}
                    detectRedemptionFee={detectRedemptionFee}
                  />
                </CCol>
                {!profile?.roles?.includes("partner") && (
                  <CCol>
                    <TariffFields
                      tariffs={tariffs}
                      tariffSelect={tariffSelect}
                      profile={profile}
                      setButtonClickable={setButtonClickable}
                      orderData={orderData}
                      updateOrderData={updateOrderData}
                    />
                  </CCol>
                )}
              </CRow>
            </CCol>
            <CCol md="4">
              <ParcelFields
                packagesTypes={packagesTypes}
                updateOrderData={updateOrderData}
                profile={profile}
                orderData={orderData}
                detectRedemptionFee={detectRedemptionFee}
                detectWeightFee={detectWeightFee}
              />
              <FormMap
                orderData={orderData}
                setPointSender={setPointSender}
                setPointReceiver={setPointReceiver}
                updateOrderData={updateOrderData}
                handleSenderAddress={handleSenderAddress}
                handleReceiverAddress={handleReceiverAddress}
                courierList={courierList}
                sidebarShow={sidebarShow}
                setMapApi={setMapApi}
                pointSender={pointSender}
                pointReceiver={pointReceiver}
                center={center}
                zoom={zoom}
                allFees={allFees}
                open={open}
                setOpen={setOpen}
                cCourier={cCourier}
                setCCourier={setCCourier}
                map={map}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="8">
              <ButtonBar
                buttonClickable={buttonClickable}
                submitCreate={submitCreate}
                edit={edit}
                clearFields={clearFields}
              />
            </CCol>
          </CRow>
        </div>
      )}
    </CCard>
  );
}
