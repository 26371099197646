import {
  CAlert,
  CButton,
  CCol,
  CFormText,
  CInput,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchCities,
  fetchDistricts,
  fetchDistrictSearch,
  fetchSearchedCities,
} from "src/services/CityService";
import { fetchTransports } from "src/services/TransportService";
import { fetchCreateImg, fetchRoutes } from "src/services/ServerService";
import { toastify } from "src/helpers/toast";
import {
  fetchCreateCourier,
  fetchUpdateCourier,
} from "src/services/CourierService";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { useTranslate } from "src/helpers/useTranslate";
import { fetchOffices } from "src/services/CompanyService";
import PhoneInput from "react-phone-input-2";
import PassportData from "./components/PassportData";
import CourierContract from "./components/CourierContract";
import TransportAndRoutes from "./components/TransportAndRoutes";

export default function CourierForm({
  toggle,
  modal,
  modalTitle,
  reFetchCouriers,
  edit,
  selectedCourier,
  setSelectedCourier,
}) {
  const { tr } = useTranslate();
  const { t } = useTranslation();
  const [btnState, setBtnState] = useState(false);
  const [office, setOffice] = useState({
    label: tr("Выберите офис", "Офис танданыз"),
  });
  const [city, setCity] = useState({
    label: tr("Выберите город", "Шаарды танданыз"),
  });
  const [district, setDistrict] = useState({
    label: tr("Выберите район", "Районду танданыз"),
  });
  const [selectedTransport, setSelectedTransport] = useState({
    label: tr("Выберите транспорт", "Унаа түрүн танданыз"),
  });
  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);
  const [transports, setTransports] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [route, setRoute] = useState("");
  const [roles, setRoles] = useState(null);
  const [courierService, setCourierService] = useState(null);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");

  const [radioTypeIsActive, setRadioTypeIsActive] = useState({
    personalData: false,
    passportData: false,
    transportAndRoutes: false,
    contractData: false,
  });
  const [fieldChecked, setFieldChecked] = useState({
    username: false,
    firstname: false,
    lastname: false,
  });

  const [activePage, setActivePage] = useState(1);
  const sideBarPages = [
    { id: 1, name: "personalData", title: "Personal information" },
    { id: 2, name: "passportData", title: "Passport data" },
    { id: 3, name: "transportAndRoutes", title: "Transport and routes" },
    { id: 4, name: "contractData", title: "Contract with company" },
  ];

  const [initForm, setInitForm] = useState({
    photo: null,
    username: "",
    password: "",
    firstname: "",
    middlename: "",
    lastname: "",
    passport_front: null,
    passport_back: null,
    routes: [],
    transport_id: "",
    transport_model: "",
    auto_number: "",
    transport_images: [],
    max_weight: "",
    max_volume: "",
    city_id: "",
    district_id: "",
    delivery_office_id: office?.id,
    roles: [],
    contract: null,
  });

  useEffect(() => {
    if (selectedCourier) {
      if (selectedCourier?.username?.length) {
        setFieldChecked((state) => ({ ...state, username: true }));
      }
      if (selectedCourier?.firstname?.length) {
        setFieldChecked((state) => ({ ...state, firstname: true }));
      }
      if (selectedCourier?.lastname?.length) {
        setFieldChecked((state) => ({ ...state, lastname: true }));
      }
    }
  }, [selectedCourier]);
  const getDataHelper = (fetch, setData) => {
    fetch()
      .then((res) => res.ok && res.json().then((data) => setData(data)))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDataHelper(fetchCities, (data) => console.log("of", data));
    fetchOffices()
      .then((res) => res.ok && res.json().then((data) => setOffices(data)))
      .catch((err) => console.log(err));
    fetchCities()
      .then((res) => res.ok && res.json().then((data) => setCities(data)))
      .catch((err) => console.log(err));
    fetchTransports()
      .then((res) => res.ok && res.json().then((data) => setTransports(data)))
      .catch((err) => console.log(err));
    fetchRoutes()
      .then((res) => res.ok && res.json().then((data) => setRoutes(data)))
      .catch((err) => console.log(err));
    console.log(selectedCourier);
    if (selectedCourier) {
      setInitForm((state) => ({
        ...state,
        photo: selectedCourier.photo || null,
        username: selectedCourier.username || "",
        firstname: selectedCourier.firstname || "",
        lastname: selectedCourier.lastname || "",
        middlename: selectedCourier.middlename || "",
        max_weight: selectedCourier.max_weight || 0,
        max_volume: selectedCourier.max_volume || 0,
        city_id: selectedCourier.city?.id || "",
        district_id: selectedCourier.district?.id || "",
        transport_id: selectedCourier.transport?.id || "",
        auto_number: selectedCourier.auto_number || "",
        passport_front: selectedCourier.passport_front || "",
        passport_back: selectedCourier.passport_back || "",
        personal_number: selectedCourier.personal_number || "",
        roles: roles?.map((r) => r.value),
      }));
      setCourierService(null);
      selectedCourier.routes &&
        takeRoute(
          selectedCourier.routes.map((r) => {
            return { value: r.id, label: tr(r.name_ru, r.name_kg) };
          })
        );
      selectedCourier.routes &&
        setRoute(
          selectedCourier.routes.map((r) => {
            return { value: r.id, label: tr(r.name_ru, r.name_kg) };
          })
        );
      selectedCourier.city &&
        setCity({
          value: selectedCourier.city.id,
          label: tr(selectedCourier.city.name_ru, selectedCourier.city.name_kg),
        });
      selectedCourier.routes &&
        takeRole(
          selectedCourier.roles.map((r) => {
            return { value: r.value, label: r.label };
          })
        );
      selectedCourier.roles &&
        setRoles(
          selectedCourier.roles.map((r) => {
            return { value: r, label: r };
          })
        );
      selectedCourier.district &&
        setDistrict({
          value: selectedCourier.district.id,
          label: tr(
            selectedCourier.district.name_ru,
            selectedCourier.district.name_kg
          ),
        });
      selectedCourier.transport &&
        setSelectedTransport({
          value: selectedCourier.transport.id,
          label: tr(
            selectedCourier.transport.name_ru,
            selectedCourier.transport.name_kg
          ),
        });
      selectedCourier.delivery_office &&
        setOffice({
          value: selectedCourier.delivery_office.id,
          label: selectedCourier.delivery_office.name,
          ...selectedCourier.delivery_office,
        });
    }
  }, [roles, selectedCourier, t, tr]);

  useEffect(() => {
    setRadioTypeIsActive((prevState) => ({
      ...prevState,
      personalData: !Object.values(fieldChecked).includes(false),
    }));
  }, [fieldChecked]);

  const onGoToNextPage = (e) => {
    const pagesNumber = sideBarPages.length;
    if (activePage < pagesNumber) {
      setActivePage((activePage) => (activePage += 1));
      console.log(initForm, "initForm to submit");
    }
    if (activePage === 4) {
      submitCourier();
    }
  };

  const closeModal = () => {
    toggle();
    setSelectedCourier(null);
  };

  const takeRoute = (e) => {
    const arr = [];
    e.map((r) => arr.push(r.value));
    setInitForm((state) => ({ ...state, routes: arr }));
  };
  const takeRole = (e) => {
    const arr = [];
    e.map((r) => arr.push(r.value));
    setInitForm((state) => ({ ...state, roles: arr }));
  };

  const promiseCityOptions = (inputValue) => {
    return new Promise((resolve) => {
      fetchSearchedCities(inputValue).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            resolve(
              d.map((dd) => {
                return {
                  label: tr(dd.name_ru, dd.name_kg),
                  value: dd.id,
                  ...dd,
                };
              })
            );
          })
      );
    });
  };

  const promiseDistrictOptions = (inputValue, role) => {
    let params = inputValue;
    params += city ? "&city_id=" + city.id : "";
    return new Promise((resolve) => {
      params.includes("&city_id=")
        ? fetchDistrictSearch(params).then(
            (res) =>
              res.ok &&
              res.json().then((d) => {
                resolve(
                  d.map((dd) => {
                    return {
                      label: tr(dd.name_ru, dd.name_kg),
                      value: dd.id,
                      ...dd,
                    };
                  })
                );
              })
          )
        : resolve([]);
    });
  };

  const handleInputChange = (e) => {
    setInitForm((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
    let name = e.target.value;
    setPassword(name);
    if (name.length >= 1) {
      setFieldChecked((state) => ({ ...state, [e.target.name]: true }));
    } else {
      setFieldChecked((state) => ({ ...state, [e.target.name]: false }));
    }
  };

  const handleUserNameChange = (e) => {
    let phoneNum = e;
    setInitForm((state) => ({
      ...state,
      username: "+" + phoneNum,
    }));
    if (phoneNum.length >= 4) {
      setFieldChecked((state) => ({ ...state, username: true }));
    } else {
      setFieldChecked((state) => ({ ...state, username: false }));
    }
  };

  const handleCityChange = (e) => {
    let res = e;
    setCity(res);
    setInitForm((state) => ({ ...state, city_id: res?.value }));
    fetchDistricts(res?.value)
      .then((res) => res.ok && res.json().then((data) => setDistricts(data)))
      .catch((err) => console.log(err));
  };

  const onUploadPhoto = (e, imgName) => {
    let img = e.target.files[0];
    checkSize(img) &&
      imgToString(img)
        .then((res) => {
          if (res.status === 200) {
            setInitForm((state) => {
              if (imgName === "transport_images") {
                return { ...state, [imgName]: [res.data] };
              } else {
                return { ...state, [imgName]: res.data };
              }
            });
          } else {
            console.log(res.status);
          }
        })
        .catch((err) => console.log(err));
  };

  const checkSize = (img) => {
    if (img?.size > 1085760) {
      toastify("error", "Слишком большой размер картинки, выберите другую");
      return false;
    }
    return true;
  };

  const imgToString = (img) => {
    let formData = new FormData();
    formData.append("image", img);
    return fetchCreateImg(formData);
  };

  const submitCourier = () => {
    const courierForm = { ...initForm };
    if (edit) {
      updateCourier(courierForm);
    } else {
      if (password?.length < 6) {
        toastify("error", t("Password must be 6 digits"));
        setError("Password must be 6 digits");
      }
      createCourier(courierForm);
    }
  };

  const createCourier = (courierForm) => {
    setBtnState(true);
    let form = courierForm;
    console.log(form, "form to submit");
    fetchCreateCourier(form)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Новый курьер успешно создан"));
          reFetchCouriers();
          setBtnState(false);
          toggle();
          setError(null);
        } else {
          res.json().then((d) => {
            toastify("error", d.detail);
            if (typeof d.detail === "string") {
              setError(d.detail);
            } else {
              setError("Error occured");
            }
          });
          toastify("error", "Ошибка при создании курьера");
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };

  const updateCourier = (courierForm) => {
    let form = courierForm;
    console.log(form, "updated form");
    if (courierService) {
      form.delivery_service_id = courierService.id;
    }
    setBtnState(true);
    fetchUpdateCourier(form, selectedCourier.id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Курьер успешно отредактирован"));
          reFetchCouriers();
          setBtnState(false);
          setError(null);
          toggle();
        } else {
          setError("Произошла ошибка редактирования курьера");
          toastify("error", t("Произошла ошибка редактирования курьера"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  return (
    <CModal show={modal} onClose={closeModal} size="lg">
      <CModalHeader closeButton>{modalTitle}</CModalHeader>
      <CModalBody style={{ minHeight: "60vh" }}>
        {error && <CAlert color="danger">{error}</CAlert>}
        <CRow>
          <CCol xs={4} className="border-right">
            <CRow>
              {sideBarPages.map((page) => (
                <CCol xs="12">
                  <input
                    type="radio"
                    id={page.name}
                    name={page.name}
                    checked={radioTypeIsActive[page.name]}
                    onClick={(e) => setActivePage(page.id)}
                  />
                  <label className="ml-2" htmlFor={page.name}>
                    {activePage === page.id ? (
                      <b>{t(page.title)}</b>
                    ) : (
                      t(page.title)
                    )}
                  </label>
                </CCol>
              ))}
            </CRow>
          </CCol>

          {/*Right Table*/}

          <CCol xs={8} className="border-right">
            {
              <>
                <CRow
                  className="ml-2"
                  style={{ display: activePage === 1 ? "block" : "none" }}
                >
                  <CCol xs={10}>
                    <CRow>
                      <CCol xs={4}>
                        <div
                          style={{
                            width: "95px",
                            height: "95px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            src={initForm.photo}
                            alt="phot"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </CCol>

                      <CCol xs={8}>
                        <CInput
                          type="file"
                          className="mt-2"
                          placeholder={t("Upload a photo")}
                          style={{ width: "87%" }}
                          onChange={(e) => onUploadPhoto(e, "photo")}
                        />
                        <CFormText
                          style={{ fontSize: "10px" }}
                          className="mt-2 help-block"
                        >
                          Разрешается png, jpg. Макс размер файла 800к.
                        </CFormText>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow
                  className="ml-2 mt-2"
                  style={{ display: activePage === 1 ? "block" : "none" }}
                >
                  <CCol xs={10}>
                    <CLabel>{t("Логин")}*</CLabel>
                    <CInputGroup>
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "100%" }}
                      >
                        <PhoneInput
                          onlyCountries={["kg"]}
                          country={"kg"}
                          value={initForm.username}
                          name="username"
                          onChange={(e) => handleUserNameChange(e)}
                          placeholder="+996 xxx xxx xxx"
                          masks={{ kg: "(...) ..-..-.." }}
                          inputStyle={{ width: "100%" }}
                        />

                        <CIcon
                          name={
                            fieldChecked.username
                              ? "cilCheckCircle"
                              : "cilWarning"
                          }
                          className={
                            "ml-2 " +
                            (fieldChecked.username
                              ? "text-success"
                              : "text-danger")
                          }
                          size="xl"
                        />
                      </div>
                      {!initForm.username && (
                        <span style={{ fontSize: "10px", color: "red" }}>
                          Введите номер
                        </span>
                      )}
                    </CInputGroup>

                    <CLabel className="mt-3">{t("Password")}</CLabel>
                    <CInput
                      style={{ width: "91%" }}
                      value={initForm.password}
                      name="password"
                      type="text"
                      placeholder={t("Password")}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <CLabel className="mt-3">{t("Firstname")}</CLabel>
                    <CInputGroup>
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "100%" }}
                      >
                        <CInput
                          value={initForm.firstname}
                          name="firstname"
                          type="text"
                          onChange={(e) => handleInputChange(e)}
                          placeholder={t("Firstname")}
                        />
                        <CIcon
                          name={
                            fieldChecked.firstname
                              ? "cilCheckCircle"
                              : "cilWarning"
                          }
                          className={
                            "ml-2 " +
                            (fieldChecked.firstname
                              ? "text-success"
                              : "text-danger")
                          }
                          size="xl"
                        />
                      </div>
                    </CInputGroup>

                    {!initForm.firstname && (
                      <span
                        style={{ fontSize: "10px", color: "red" }}
                        className="d-block"
                      >
                        Вы не указали имя
                      </span>
                    )}

                    <CLabel className="mt-3">{t("Lastname")}</CLabel>
                    <CInputGroup>
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "100%" }}
                      >
                        <CInput
                          value={initForm.lastname}
                          name="lastname"
                          type="text"
                          placeholder={t("Lastname")}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <CIcon
                          name={
                            fieldChecked.lastname
                              ? "cilCheckCircle"
                              : "cilWarning"
                          }
                          className={
                            "ml-2 " +
                            (fieldChecked.lastname
                              ? "text-success"
                              : "text-danger")
                          }
                          size="xl"
                        />
                      </div>
                    </CInputGroup>

                    <CLabel className="mt-3">{t("Middlename")}</CLabel>
                    <CInput
                      style={{ width: "91%" }}
                      value={initForm.middlename}
                      name="middlename"
                      type="text"
                      placeholder={t("Middlename")}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </CCol>
                </CRow>
              </>
            }

            {
              <>
                <PassportData
                  activePage={activePage}
                  setRadioTypeIsActive={setRadioTypeIsActive}
                  onUploadPhoto={onUploadPhoto}
                  initForm={initForm}
                  setInitForm={setInitForm}
                  cities={cities}
                  promiseCityOptions={promiseCityOptions}
                  promiseDistrictOptions={promiseDistrictOptions}
                  handleCityChange={handleCityChange}
                  city={city}
                  district={district}
                  districts={districts}
                  setDistrict={setDistrict}
                />
              </>
            }
            {
              <>
                <TransportAndRoutes
                  selectedCourier={selectedCourier}
                  activePage={activePage}
                  transports={transports}
                  routes={routes}
                  onUploadPhoto={onUploadPhoto}
                  setRadioTypeIsActive={setRadioTypeIsActive}
                  takeRoute={takeRoute}
                  initForm={initForm}
                  setInitForm={setInitForm}
                  selectedTransport={selectedTransport}
                  setSelectedTransport={setSelectedTransport}
                  setRoute={setRoute}
                  route={route}
                />
              </>
            }
            {
              <>
                <CourierContract
                  takeRole={takeRole}
                  selectedCourier={selectedCourier}
                  activePage={activePage}
                  offices={offices}
                  onUploadPhoto={onUploadPhoto}
                  setRadioTypeIsActive={setRadioTypeIsActive}
                  initForm={initForm}
                  setInitForm={setInitForm}
                  setOffice={setOffice}
                  office={office}
                  setRoles={setRoles}
                  roles={roles}
                />
              </>
            }
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          className="btn-outline-primary"
          disabled={btnState}
          onClick={(e) => onGoToNextPage(e)}
        >
          {btnState ? (
            <MiniSpinner />
          ) : activePage === 4 ? (
            selectedCourier?.id ? (
              t("Update")
            ) : (
              t("Create")
            )
          ) : (
            t("Next")
          )}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
