import React from "react";
import {
  CButton,
  CSelect,
  CTooltip,
  CButtonGroup,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { toastify } from "src/helpers/toast";
import { addOrderToCart } from "src/redux/actions/orderActions";
import { date } from "src/helpers/date";
import ion_archive from "src/assets/icons/ion_archive.svg";
import vector_unarchive from "src/assets/icons/Vector_unarchive.svg";
import CIcon from "@coreui/icons-react";
import { addOrderDetailToClipboard } from "../Actions";
import { useTranslate } from "src/helpers/useTranslate";
import { useTranslation } from "react-i18next";

export default function OrderRow({
  allowedToUpdate,
  order,
  hexToRgb,
  statusList,
  openDetails,
  profile,
  openConfirmChange,
  couriers,
  company,
  isArchive,
  ungroupOrder,
  selectedOrders,
  dispatch,
}) {
  const { tr } = useTranslate();
  const { t } = useTranslation();
  return (
    <tr
      className="order_table_row"
      key={order.id}
      style={{
        // display: "block",
        background:
          order.status === "created" && order.source !== "operator web"
            ? "linear-gradient(to top, yellow, white)"
            : statusList &&
              statusList.length &&
              "linear-gradient(to right, " +
                statusList.filter((s) => s.value === order.status)[0].color +
                " " +
                statusList.filter((s) => s.value === order.status)[0].percent +
                `%, ${hexToRgb(
                  statusList.filter((s) => s.value === order.status)[0].color
                )} 0%)`,
      }}
    >
      <td style={{ padding: "5px", width: "10%" }}>
        <b>{order.oid}</b>
      </td>
      <td
        onClick={() => openDetails(order)}
        style={{ cursor: "pointer", padding: "5px", width: "30%" }}
      >
        <span style={{ color: "black" }}>
          {order.city_from?.id !== order.city_to?.id &&
            tr(order.city_from?.name_ru, order.city_from?.name_kg) + ", "}
          {tr(order.location_from?.name_ru, order.location_from?.name_kg)}{" "}
          <CIcon name="cilArrowRight" />{" "}
          {order.city_from?.id !== order.city_to?.id &&
            tr(order.city_to?.name_ru, order.city_to?.name_kg) + ", "}
          {tr(order.location_to?.name_ru, order.location_to?.name_kg)}
        </span>
        <br />
        <b>{(order.sender_name?.substring(0, 15) || "") + " "}</b>{" "}
        {order.description.substring(0, 40)}
        {order.description.length > 40 && "..."}
      </td>
      {!profile?.roles?.includes("partner") &&
        (allowedToUpdate ? (
          <td style={{ padding: "5px", width: "8%" }} className="text-center">
            {order.delivery_offices?.length > 0 ? (
              <span
                onClick={() =>
                  openConfirmChange(
                    order,
                    "office",
                    order.delivery_offices?.map((off) => {
                      return { label: off.name, value: off.id, ...off };
                    })
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {order.delivery_offices?.map(
                  (off, i) => (i !== 0 ? "," : "") + off.name
                )}
              </span>
            ) : (
              <CIcon
                name="cilPlus"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openConfirmChange(
                    order,
                    "office",
                    order.delivery_offices?.map((off) => {
                      return { label: off.name, value: off.id, ...off };
                    })
                  )
                }
              />
            )}
          </td>
        ) : (
          <td>
            {order.delivery_offices?.map(
              (off, i) => (i !== 0 ? "," : "") + off.name
            )}
          </td>
        ))}
      {allowedToUpdate ? (
        <td style={{ padding: "5px", width: "8%" }} className="text-center">
          {profile?.roles.includes("partner") ||
          order.delivery_service_id !== company?.id ? (
            <>{order.courier?.fullname}</>
          ) : (
            <CTooltip
              content={order.courier?.fullname}
              placement="bottom"
            >
              <CSelect
                size="sm"
                onChange={(e) =>
                  openConfirmChange(order, "courier", e.target.value)
                }
                value={order.courier?.id || "Никто"}
              >
                <option>{t("Никто")}</option>
                {couriers
                  .sort((a, b) => a.fullname?.localeCompare(b.fullname))
                  .map((c, i) => (
                    <option value={c.id} key={i}>
                      {c.city
                        ? `${c.fullname} (${tr(
                            c.city.name_ru,
                            c.city.name_kg
                          )})`
                        : c.fullname}
                    </option>
                  ))}
              </CSelect>
            </CTooltip>
          )}
        </td>
      ) : (
        <td>{order.courier?.fullname}</td>
      )}
      {allowedToUpdate ? (
        <td style={{ width: "12%", padding: "5px" }}>
          {profile?.roles.includes("partner") ? (
            <div className="text-center">
              {t(order.status)}
              <CButton
                className="btn btn-block btn-sm btn-danger"
                onClick={() => openConfirmChange(order, "status", "cancelled")}
              >
                {t("Cancel")}
              </CButton>
            </div>
          ) : statusList ? (
            <>
              <CButtonGroup
                role="group"
                aria-label="Button group with nested dropdown"
                size="sm"
              >
                <CDropdown variant="btn-group">
                  <CDropdownToggle color="light">
                    {t(order.status)}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {statusList.map((s, i) => (
                      <CDropdownItem
                        className={
                          s.value === "on carrier"
                            ? "my-1 py-1 border-top border-bottom"
                            : "my-0 py-0"
                        }
                        key={i}
                        onClick={() =>
                          openConfirmChange(order, "status", s.value)
                        }
                      >
                        {tr(s?.name_ru, s?.name_kg)}
                      </CDropdownItem>
                    ))}
                  </CDropdownMenu>
                </CDropdown>
              </CButtonGroup>
              {/* <CSelect size="sm"
            onChange={(e) => openConfirmChange(order, "status", e.target.value)}
            value={order.status}
            >                          <option style={{borderTop: '2px White solid'}}>tr(s?.name_ru, s?.name_kg)</option>
            {props?.statusList && statusList.map((s,i) => 
                <option key={i} value={s.value}>{tr(s?.name_ru, s?.name_kg)}</option>
            )}
            </CSelect> */}
            </>
          ) : (
            order.status
          )}
        </td>
      ) : (
        <td>{t(order.status)}</td>
      )}
      <td style={{ padding: "5px" }} className="text-right">
        {date(order.created_at)}
      </td>
      <td style={{ padding: "5px" }}>
        <img
          alt=""
          src={order.package_type_photo || ""}
          className="rounded-circle mr-1"
          width="15"
        />
        {order.tariff_name}
      </td>
      <td style={{ padding: "5px" }} className="text-center">
        {order.final_price} <u>c</u>
      </td>
      {!isArchive && (
        <td style={{ padding: "5px" }} className="text-center">
          <CTooltip content={t("Copy")}>
            <CIcon
              style={{ cursor: "pointer" }}
              name="cilCopy"
              onClick={() => addOrderDetailToClipboard(order, tr, t, toastify)}
            />
          </CTooltip>
          {false &&
            order.status !== "completed" &&
            order.status !== "cancelled" &&
            !order.in_pool &&
            (order.parent_type === "group" ? (
              <CTooltip content={t("Ungroup")} placement="bottom">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => ungroupOrder(order)}
                >
                  <img alt="to cart" src={vector_unarchive} width="20" />
                </span>
              </CTooltip>
            ) : (
              <CTooltip
                content={
                  selectedOrders.some((or) => or.id === order.id)
                    ? t("Remove from Cart")
                    : t("Add to Cart")
                }
                placement="bottom"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => dispatch(addOrderToCart(order))}
                >
                  {selectedOrders.some((or) => or.id === order.id) ? (
                    <CIcon name="cilX" />
                  ) : (
                    <img alt="to cart" src={ion_archive} width="20" />
                  )}
                </span>
              </CTooltip>
            ))}
          <CTooltip content={t("Copy link")} placement="bottom">
            <CIcon
              name="cilLink"
              style={{ cursor: "pointer" }}
              className="ml-1"
              onClick={() => {
                navigator.clipboard.writeText(
                  "http://dms.kg/orders/" + order.pid
                );
                toastify("info", "Link is copied");
              }}
            />
          </CTooltip>
        </td>
      )}
    </tr>
  );
}
