import { createApi } from './ServerService';
import { DELETE, PATCH, POST } from './types';

// /api/v1/couriers/
export const fetchCouriers = () => {
    return createApi("/api/v1/users/?role=courier");
}

// /api/v1/couriers/{courier_id}
export const fetchCourier = (courier_id) => {
    return createApi("/api/v1/couriers/" + courier_id + "/");
}

// /api/v1/couriers/{courier_id}
export const fetchDeleteCourier = (courier_id) => {
    return createApi("/api/v1/couriers/" + courier_id + "/", { method: DELETE });
}

// /api/v1/couriers/{courier_id}/complete_registration
export const fetchCourierCompleteRegistration = (courier, courier_id) => {
    return createApi("/api/v1/couriers/" + courier_id + "/complete_registration/", {method: POST, body: courier});
}

//create courier /api/v1/couriers/
export const fetchCreateCourier = (courier) => {
    return createApi("/api/v1/couriers/", {method: POST, body: courier});
}
//update courier /api/v1/couriers/
export const fetchUpdateCourier = (courier, id) => {
    return createApi("/api/v1/couriers/" + id + "/", {method: PATCH, body: courier});
}

//replenish balance courier /api/v1/couriers/courier_id/balance
export const fetchCourierBalanceReplenish = (replenish, id) => {
    return createApi("/api/v1/couriers/" + id + "/balance/", {method: POST, body: replenish});
}

// courier balance /api/v1/couriers/
export const fetchCourierBalanceHistory = (params) => {
    let url = "/api/v1/payments/courier/"
    if(params) {
        url = url+"?"+params;
    }
    return createApi(url);
}
//update courier balance comment/api/v1/couriers/
export const fetchCourierBalanceHistoryUpdate = (id, data) => {
    return createApi("/api/v1/payments/courier/" + id + "/", {method: PATCH, body: data});
}

//update courier /api/v1/couriers/{id}/blocking/
export const fetchCourierBlock = (id, block) => {
    return createApi("/api/v1/couriers/" + id + "/blocking/", {method: POST, body: {block: block}});
}

//update courier /api/v1/couriers/{id}/password
export const fetchCourierPassword = (id, password) => {
    return createApi("/api/v1/couriers/" + id + "/password/", {method: PATCH, body: password});
}

//update courier /api/v1/couriers/all
export const fetchCouriersAll = () => {
    return createApi("/api/v1/couriers/all/");
}

export const fetchCouriersShifts = () => {
    return createApi("/api/v1/couriers/shifts/");
}

export const fetchCarriers = () => {
    return createApi("/api/v1/carriers/");
}

export const fetchCreateCarrier = (form) => {
    return createApi("/api/v1/carriers/", {method: POST, body: form});
}