import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastify } from "src/helpers/toast";
import { useTranslate } from "src/helpers/useTranslate";
import { getPackagesTypes } from "src/redux/actions/addressActions";
import { fetchClientsSearh } from "src/services/ClientService";
import {
  fetchCompanyAddressesSearch,
  fetchOffices,
} from "src/services/CompanyService";
import { fetchCreatesAndSubmit } from "./helper";

export const useOrderForms = ({ onSubmit }) => {
  const { tr } = useTranslate();
  const [orderData, setOrderData] = useState({ packages: [{}] });

  const packagesTypes = useSelector((state) => state.addresses.packagesTypes);
  const profile = useSelector((state) => state.auth.profile);
  const defaultOffice = useMemo(
    () => profile?.partner?.default_office,
    [profile]
  );
  const dispatch = useDispatch();

  const [emptySender, setEmptySender] = useState(false);
  const [emptyReceiver, setEmptyReceiver] = useState(false);
  const [emptyAddress, setEmptyAddress] = useState(false);

  const [allClientsSender, setAllClientsSender] = useState([]);
  const [allClientsReceiver, setAllClientsReceiver] = useState([]);

  const [allAddressSender, setAllAddressSender] = useState([]);
  const [allAddressReceiver, setAllAddressReceiver] = useState([]);

  const [offices, setOffices] = useState([]);

  useEffect(() => {
    dispatch(getPackagesTypes());
    fetchOffices((res) =>
      res.json().then((d) => {
        setOffices(d);
      })
    );
  }, [dispatch]);

  const onFieldChange = useCallback((field, value) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  }, []);

  const promiseClientOptions = (role, inputValue) => {
    let key = inputValue;
    if (key[0] === "+") {
      key = key.substring(1, key.length);
    }
    return new Promise((resolve) => {
      fetchClientsSearh(key).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            role === "sender"
              ? setAllClientsSender(d)
              : setAllClientsReceiver(d);
            resolve(
              d.map((cl) => {
                return {
                  value: cl.id,
                  label: cl.fullname,
                  ...cl,
                };
              })
            );
          })
      );
    });
  };

  const promiseAddressOptions = (role, inputValue) => {
    let addrParams = inputValue;
    if (role === "Sender") {
      addrParams += orderData.selectedCitySender
        ? "&city_id=" + orderData.selectedCitySender.id
        : "";
      addrParams += orderData.selectedDistrictSender
        ? "&district_id=" + orderData.selectedDistrictSender.id
        : "";
    } else {
      addrParams += orderData.selectedCityReceiver
        ? "&city_id=" + orderData.selectedCityReceiver.id
        : "";
      addrParams += orderData.selectedDistrictReceiver
        ? "&district_id=" + orderData.selectedDistrictReceiver.id
        : "";
    }
    return new Promise((resolve, reject) => {
      fetchCompanyAddressesSearch(addrParams).then((res) =>
        res.ok
          ? res.json().then((d) => {
              console.log(d);
              resolve(
                d.map((dd) => {
                  return {
                    label:
                      tr(dd.name_ru, dd.name_kg) +
                      (dd.district
                        ? ", " + tr(dd.district.name_ru, dd.district.name_kg)
                        : "") +
                      (dd.city
                        ? ", " + tr(dd.city.name_ru, dd.city.name_kg)
                        : ""),
                    value: dd.id,
                    ...dd,
                  };
                })
              );
            })
          : resolve([])
      );
    });
  };

  const updatePack = useCallback(
    (i, field, val) => {
      let packs = orderData.packages;
      packs[i] = { ...packs[i], [field]: val };
      onFieldChange("packages", packs);
    },
    [onFieldChange, orderData]
  );

  const deletePack = useCallback(
    (index) => {
      let packs = orderData.packages;
      onFieldChange(
        "packages",
        packs.filter((_, i) => index !== i)
      );
    },
    [onFieldChange, orderData]
  );

  const checkForValidations = useCallback(() => {
    let validResults = [];
    if (!orderData.sender?.label) {
      setEmptySender(true);
      validResults.push("sender");
    }
    if (!orderData.receiver?.label) {
      setEmptyReceiver(true);
      validResults.push("receiver");
    }
    if (!orderData.addressSender?.label) {
      setEmptyAddress(true);
      validResults.push("address");
    }
    if (validResults.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [orderData]);

  const submitForm = useCallback(() => {
    const data = orderData;
    data.packages.forEach((pack, i) => {
      if (!pack.package_type_id) {
        data.packages[i].package_type_id = packagesTypes[0]?.id;
      }
    });
    if (!data.delivery_office_id) {
      data.delivery_office_id = defaultOffice?.id;
    }
    console.log(data);
    const isValidForm = checkForValidations();
    if (isValidForm) {
      fetchCreatesAndSubmit(data, onSubmit);
    } else {
      toastify("warning", "Fill the fields required");
    }
  }, [
    checkForValidations,
    onSubmit,
    orderData,
    packagesTypes,
    defaultOffice?.id,
  ]);

  return {
    emptySender,
    emptyReceiver,
    allClientsReceiver,
    allClientsSender,
    orderData,
    onFieldChange,
    promiseClientOptions,
    offices,
    defaultOffice,

    emptyAddress,
    promiseAddressOptions,
    allAddressSender,
    allAddressReceiver,

    updatePack,
    deletePack,
    packagesTypes,
    submitForm,
  };
};
