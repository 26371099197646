import React, { useEffect, useState } from "react";
import {
  CModal,
  CModalBody,
  CButton,
  CSelect,
  CTooltip,
  CLabel,
  CBadge,
} from "@coreui/react";
import { useConfirmModal, useModalState } from "src/helpers/useModal";
import OrderDetails from "src/pages/orders/Order-Details/OrderDetails";
import { useTranslation } from "react-i18next";
import {
  orderConfirmFetch,
  orderCourierChangeFetch,
  orderStatusChangeFetch,
  orderUngroupFetch,
  updateOrderFetch,
} from "src/services/OrderService";
import {
  orderProcessPool,
  orderRemoveFromPool,
  orderReturnToPool,
  orderTransferToPool,
} from "src/services/OrderService";
import { toastify } from "src/helpers/toast";
import { useDispatch, useSelector } from "react-redux";
import { addOrderToCart, getOrders } from "src/redux/actions/orderActions";
import { date } from "src/helpers/date";
import ion_archive from "src/assets/icons/ion_archive.svg";
import vector_unarchive from "src/assets/icons/Vector_unarchive.svg";
import CIcon from "@coreui/icons-react";
import { useTranslate } from "src/helpers/useTranslate";
import birzha from "src/assets/icons/birzha.svg";
import birzha_sent from "src/assets/icons/birzha_sent.svg";
import birzha_received from "src/assets/icons/birzha_received.svg";
import birzha_taken from "src/assets/icons/birzha_taken.svg";
import birzha_taken2 from "src/assets/icons/birzha_taken2.svg";
import birzha_taken3 from "src/assets/icons/birzha_taken3.svg";
import { useHistory } from "react-router";
import { fetchOffices } from "src/services/CompanyService";
import Select from "react-select";
import { addOrderDetailToClipboard } from "../Actions";

export default function OrdersTable(props) {
  const hexToRgb = (hex) => {
    let arr = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return `rgb(${arr[0] + 40}, ${arr[1] + 40}, ${arr[2] + 40})`;
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tr } = useTranslate();
  const history = useHistory();

  const { isOpen, onToggle } = useModalState();
  const { onConfirmOpen, onConfirmClose, isConfirmOpen, onConfirmToggle } =
    useConfirmModal();

  const company = useSelector((state) => state.auth.company);
  const profile = useSelector((state) => state.auth.profile);

  const selectedOrders = useSelector((state) => state.order.selectedOrders);
  const couriers = useSelector((state) => state.courier.couriers);
  const [offices, setOffices] = useState([]);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newOrderValue, setNewOrderValue] = useState(null);
  const [field, setField] = useState(null);
  const [condCursor, setCondCursor] = useState("pointer");

  useEffect(() => {
    fetchOffices().then(
      (res) =>
        res.ok &&
        res.json().then((d) => {
          setOffices(d);
        })
    );

    return () => console.log("clearing");
  }, []);

  const openDetails = (order) => {
    setSelectedOrder(order);
    onToggle();
  };

  const openConfirmChange = (order, field, newValue) => {
    setSelectedOrder(order);
    setNewOrderValue(newValue);
    setField(field);
    onConfirmOpen();
  };

  const confirmChange = () => {
    switch (field) {
      case "status":
        confirmStatusChange();
        break;
      case "courier":
        confirmCourierChange();
        break;
      case "office":
        confirmOfficeChange();
        break;
      default:
        console.log("confirmation");
    }
  };
  const confirmStatusChange = () => {
    orderStatusChangeFetch(selectedOrder.id, newOrderValue)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Operation succeeded"));
          onConfirmClose();
          setSelectedOrder(null);
          setNewOrderValue(null);
          setField(null);
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => toastify("error", "Error occured"));
  };

  const confirmCourierChange = () => {
    orderCourierChangeFetch(
      selectedOrder.id,
      newOrderValue === "Никто" ? null : newOrderValue
    )
      .then((res) => {
        if (res.ok) {
          onConfirmClose();
          setSelectedOrder(null);
          setNewOrderValue(null);
          setField(null);
          toastify("success", t("Operation succeeded"));
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", t("Operation failed"));
      });
  };

  const confirmOfficeChange = () => {
    updateOrderFetch(selectedOrder.id, {
      delivery_offices: newOrderValue.map((v) => v.id),
    })
      .then((res) => {
        if (res.ok) {
          onConfirmClose();
          setSelectedOrder(null);
          setNewOrderValue(null);
          setField(null);
          toastify("success", t("Operation succeeded"));
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", t("Operation failed"));
      });
  };

  const ungroupOrder = (order) => {
    orderUngroupFetch(order.id)
      .then((res) => {
        if (res.ok) {
          res.json().then((d) => {
            console.log(d);
            toastify("success", t("Operation succeeded"));
            dispatch(getOrders());
          });
        } else {
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", "Error occured");
      });
  };
  // pool actions

  const transferToPool = (id) => {
    poolAction(orderTransferToPool(id));
  };

  const removeFromPool = (id) => {
    poolAction(orderRemoveFromPool(id));
  };

  const processPoolOrder = (id) => {
    poolAction(orderProcessPool(id), { type: "process", id: id });
  };

  const confirmPoolOrder = (order) => {
    // poolAction(orderConfirmPool(id))
    poolAction(
      orderConfirmFetch(order.id, {
        tariff_id: order.tariff?.id,
        package_type_id: order.package_type?.id,
      })
    );
  };

  const returnToPool = (id) => {
    poolAction(orderReturnToPool(id));
  };

  const poolAction = (fetch, action) => {
    if (condCursor !== "wait") {
      setCondCursor("wait");
      fetch
        .then((res) => {
          setCondCursor("pointer");
          if (res.ok) {
            if (action?.type === "process") {
              history.push("/confirm-order/" + action.id);
            } else {
              toastify("success", t("Operation succeeded"));
            }
          } else {
            res.json().then((d) => {
              console.log(d?.detail?.toString());
              toastify("error", d?.detail?.toString());
              setCondCursor("pointer");
            });
          }
        })
        .catch((err) => {
          setCondCursor("pointer");
          console.log(err);
          toastify("error", err.toString());
        });
    }
  };

  return (
    <div>
      <table className="table table-responsive-lg">
        <thead>
          <tr>
            <th>#</th>
            <th>
              {t("Sender")} <CIcon name="cilArrowRight" /> {t("Receiver")}
            </th>
            {!profile?.roles?.includes("partner") && <th>{t("Офис")}</th>}
            <th>{t("Courier")}</th>
            <th>{t("Status")}</th>
            <th>{t("Date")}</th>
            <th>{t("Tariff")}</th>
            <th>{t("Redemption")}</th>
            <th>{t("Price")}</th>
            {!props.archive && <th>{t("Actions")}</th>}
          </tr>
        </thead>
        <tbody>
          {props.currentOrders.length > 0 &&
            props.currentOrders.map((order) => (
              <tr
                className="order_table_row"
                key={order.id}
                style={{
                  // display: "block",
                  background:
                    order.status === "created" &&
                    order.source !== "operator web"
                      ? "linear-gradient(to top, yellow, white)"
                      : props.statusList &&
                        props.statusList.length &&
                        "linear-gradient(to right, " +
                          props.statusList.filter(
                            (s) => s.value === order.status
                          )[0].color +
                          " " +
                          props.statusList.filter(
                            (s) => s.value === order.status
                          )[0].percent +
                          `%, ${hexToRgb(
                            props.statusList.filter(
                              (s) => s.value === order.status
                            )[0].color
                          )} 0%)`,
                }}
              >
                <td style={{ padding: "5px", width: "10%" }}>
                  <b>{order.oid}</b>
                </td>
                <td
                  onClick={() => openDetails(order)}
                  style={{ cursor: "pointer", padding: "5px", width: "30%" }}
                >
                  <span style={{ color: "black" }}>
                    {order.city_from?.id !== order.city_to?.id &&
                      tr(order.city_from?.name_ru, order.city_from?.name_kg) +
                        ", "}
                    {tr(
                      order.location_from?.name_ru,
                      order.location_from?.name_kg
                    )}{" "}
                    <CIcon name="cilArrowRight" />{" "}
                    {order.city_from?.id !== order.city_to?.id &&
                      tr(order.city_to?.name_ru, order.city_to?.name_kg) + ", "}
                    {tr(order.location_to?.name_ru, order.location_to?.name_kg)}
                  </span>
                  <br />
                  <b>
                    {(order.sender_name?.substring(0, 15) || "") + " "}
                  </b>{" "}
                  {order.description.substring(0, 40)}
                  {order.description.length > 40 && "..."}
                </td>
                {!profile?.roles?.includes("partner") && (
                  <td
                    style={{ padding: "5px", width: "8%" }}
                    className="text-center"
                  >
                    {order.delivery_offices?.length > 0 ? (
                      <span
                        onClick={() =>
                          openConfirmChange(
                            order,
                            "office",
                            order.delivery_offices?.map((off) => {
                              return { label: off.name, value: off.id, ...off };
                            })
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {order.delivery_offices?.map(
                          (off, i) => (i !== 0 ? "," : "") + off.name
                        )}
                      </span>
                    ) : (
                      <CIcon
                        name="cilPlus"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          openConfirmChange(
                            order,
                            "office",
                            order.delivery_offices?.map((off) => {
                              return { label: off.name, value: off.id, ...off };
                            })
                          )
                        }
                      />
                    )}
                  </td>
                )}
                <td
                  style={{ padding: "5px", width: "8%" }}
                  className="text-center"
                >
                  {profile?.roles.includes("partner") ||
                  order.delivery_service_id !== company?.id ? (
                    <>{order.courier?.user?.fullname}</>
                  ) : (
                    <CTooltip
                      content={order.courier?.user?.username}
                      placement="bottom"
                    >
                      <CSelect
                        size="sm"
                        onChange={(e) =>
                          openConfirmChange(order, "courier", e.target.value)
                        }
                        value={order.courier?.id || "Никто"}
                      >
                        <option>{t("Никто")}</option>
                        {couriers
                          .sort((a, b) => a.fullname.localeCompare(b.firstname))
                          .map((c, i) => (
                            <option value={c.id} key={i}>
                              {c.city
                                ? `${c.fullname} (${tr(
                                    c.city.name_ru,
                                    c.city.name_kg
                                  )})`
                                : c.firstname}
                            </option>
                          ))}
                      </CSelect>
                    </CTooltip>
                  )}
                </td>
                <td style={{ width: "12%", padding: "5px" }}>
                  {profile?.roles.includes("partner") ? (
                    <div className="text-center">
                      {t(order.status)}
                      <CButton
                        className="btn btn-block btn-sm btn-danger"
                        onClick={() =>
                          openConfirmChange(order, "status", "cancelled")
                        }
                      >
                        {t("Cancel")}
                      </CButton>
                    </div>
                  ) : order.status === "created" &&
                    order.previous_company_id !== company?.id ? (
                    <CButton
                      className="btn btn-block btn-sm btn-info"
                      onClick={() => processPoolOrder(order.id)}
                    >
                      {t("Process")}
                    </CButton>
                  ) : order.previous_company_id !== company?.id &&
                    order.delivery_service_id === company?.id &&
                    order.status === "in processing" ? (
                    <CButton
                      className="btn btn-block btn-sm btn-info"
                      onClick={() => confirmPoolOrder(order)}
                    >
                      {t("Confirm")}
                    </CButton>
                  ) : props.statusList ? (
                    <CSelect
                      size="sm"
                      onChange={(e) =>
                        openConfirmChange(order, "status", e.target.value)
                      }
                      value={order.status}
                    >
                      {props?.statusList &&
                        props.statusList.map((s, i) => (
                          <option key={i} value={s.value}>
                            {tr(s?.name_ru, s?.name_kg)}
                          </option>
                        ))}
                    </CSelect>
                  ) : (
                    order.status
                  )}
                </td>
                <td style={{ padding: "5px" }} className="text-right">
                  {date(order.created_at)}
                </td>
                <td style={{ padding: "5px" }}>
                  <img
                    alt=""
                    src={order.package_type_photo || ""}
                    className="rounded-circle mr-1"
                    width="15"
                  />
                  {order.tariff_name}
                </td>
                <td style={{ padding: "5px" }} className="text-center">
                  {order.redemption_amount > 0 && (
                    <CBadge
                      color={
                        order.redemption_payment_type === "redemption"
                          ? "success"
                          : "danger"
                      }
                    >
                      {order.redemption_amount}
                      <u>c</u>
                    </CBadge>
                  )}
                </td>
                <td style={{ padding: "5px" }} className="text-center">
                  {order.final_price} <u>c</u>
                </td>
                {!props.archive && (
                  <td style={{ padding: "5px" }} className="text-center">
                    <CTooltip content={t("Copy")}>
                      <CIcon
                        style={{ cursor: "pointer" }}
                        name="cilCopy"
                        onClick={() =>
                          addOrderDetailToClipboard(order, tr, t, toastify)
                        }
                      />
                    </CTooltip>
                    {false &&
                      order.status !== "completed" &&
                      order.status !== "cancelled" &&
                      !order.in_pool &&
                      (order.parent_type === "group" ? (
                        <CTooltip content={t("Ungroup")} placement="bottom">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => ungroupOrder(order)}
                          >
                            <img
                              alt="to cart"
                              src={vector_unarchive}
                              width="20"
                            />
                          </span>
                        </CTooltip>
                      ) : (
                        <CTooltip
                          content={
                            selectedOrders.some((or) => or.id === order.id)
                              ? t("Remove from Cart")
                              : t("Add to Cart")
                          }
                          placement="bottom"
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => dispatch(addOrderToCart(order))}
                          >
                            {selectedOrders.some((or) => or.id === order.id) ? (
                              <CIcon name="cilX" />
                            ) : (
                              <img alt="to cart" src={ion_archive} width="20" />
                            )}
                          </span>
                        </CTooltip>
                      ))}
                    {company?.in_pool &&
                      !profile?.roles?.includes("partner") && (
                        <span style={{ cursor: condCursor }} className="ml-1">
                          {!order.in_pool &&
                            order.status === "approved" &&
                            !order.previous_company_id && (
                              <CTooltip
                                content={t("Transfer to pool")}
                                placement="bottom"
                              >
                                <img
                                  alt="birzha"
                                  src={birzha}
                                  width="20"
                                  onClick={() => transferToPool(order.id)}
                                />
                              </CTooltip>
                            )}
                          {order.in_pool &&
                            order.previous_company_id === company?.id &&
                            !order.delivery_service_id && (
                              <CTooltip
                                content={t("Remove from pool")}
                                placement="bottom"
                              >
                                <img
                                  alt="birzha"
                                  src={birzha_sent}
                                  width="20"
                                  onClick={() => removeFromPool(order.id)}
                                />
                              </CTooltip>
                            )}
                          {order.in_pool &&
                            order.previous_company_id !== company?.id &&
                            !order.delivery_service_id && (
                              <CTooltip
                                content={t("Pool order")}
                                placement="bottom"
                              >
                                <img
                                  alt="birzha"
                                  src={birzha_received}
                                  width="20"
                                />
                              </CTooltip>
                            )}
                          {order.in_pool &&
                            order.delivery_service_id === company?.id &&
                            order.status === "in processing" && (
                              <CTooltip
                                content={t("Return to pool")}
                                placement="bottom"
                              >
                                <img
                                  alt="birzha"
                                  className="mr-1"
                                  src={birzha_taken}
                                  width="20"
                                  onClick={() => returnToPool(order.id)}
                                />
                              </CTooltip>
                            )}
                          {order.in_pool &&
                            order.previous_company_id !== company?.id &&
                            order.delivery_service_id === company?.id && (
                              <CTooltip
                                content={t("Pool order")}
                                placement="bottom"
                              >
                                <img
                                  alt="birzha"
                                  src={birzha_taken3}
                                  width="20"
                                />
                              </CTooltip>
                            )}
                          {order.in_pool &&
                            order.previous_company_id === company?.id &&
                            order.delivery_service_id && (
                              <CTooltip
                                content={t("Pool order")}
                                placement="bottom"
                              >
                                <img
                                  alt="birzha"
                                  src={birzha_taken2}
                                  width="20"
                                />
                              </CTooltip>
                            )}
                        </span>
                      )}
                    <CTooltip content={t("Copy link")} placement="bottom">
                      <CIcon
                        name="cilLink"
                        style={{ cursor: "pointer" }}
                        className="ml-1"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "http://dms.kg/orders/" + order.pid
                          );
                          toastify("info", "Link is copied");
                        }}
                      />
                    </CTooltip>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {selectedOrder && (
        <OrderDetails
          isOpen={props.selectedOrderId ? true : isOpen}
          onToggle={onToggle}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}

      {selectedOrder && (
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalBody>
            {field === "office" ? (
              <>
                <CLabel>{t("Offices")}</CLabel>
                <Select
                  isMulti
                  className="my-2"
                  options={offices.map((o) => {
                    return { label: o.name, value: o.id, ...o };
                  })}
                  onChange={(res) => setNewOrderValue(res)}
                  value={[...newOrderValue]}
                />
              </>
            ) : (
              <h5 className="text-center">
                {t("Confirm")} {field} change?
              </h5>
            )}
            <div className="text-right">
              <CButton
                variant="outline"
                color="dark"
                onClick={onConfirmToggle}
                className="mx-2"
              >
                {t("Close")}
              </CButton>
              <CButton
                variant="outline"
                color="primary"
                onClick={confirmChange}
              >
                {t("Confirm")}
              </CButton>
            </div>
          </CModalBody>
        </CModal>
      )}
    </div>
  );
}
