import {
    CDropdownItem,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CDropdownDivider,
  } from "@coreui/react";
  import React from "react";
  
  import CIcon from "@coreui/icons-react";
  import { date } from "src/helpers/date";
  import online from "src/assets/courier_icons/online.svg";
  import { useTranslation } from "react-i18next";
import { useTranslate } from "src/helpers/useTranslate";
import {useHistory} from 'react-router-dom'

export default function CouriersTable ({couriers, setModalForm, 
    selectCourier, onToggle, setModalTitle, verifyForm, search}) {

    const { t } = useTranslation();
    const { tr } = useTranslate();
    
    const history = useHistory()
    console.log(couriers)
    
    return (
      <>
        <table className="table table-hover table-responsive-xl">
          <thead>
            <tr>
              <th>{t("Photo")}</th>
              <th>{t("Firstname")}</th>
              <th>{t("Username")}</th>
              <th>{t("Office")}</th>
              <th>{t("Thermal Bag")}</th>
              <th>{t("Transport type")}</th>
              <th>{t("Status")}</th>
              <th>{t("Last update")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {couriers && couriers.filter(c => 
              search ?
                c.username?.toLowerCase().includes(search.toLowerCase()) || 
                c.firstname?.toLowerCase().includes(search.toLowerCase())
              : true)
              .sort((a,b) => a.status === "on_delivery" ? -1 : 1)
              .sort((a,b) => a.status==="online" ? -1 : 1).map((c) => (
                <tr key={c.id}>
                  <td className="p-1">
                    <img
                      className="sp-package-mini"
                      src={c.photo || online}
                      alt={c.firstname}
                    />
                  </td>
                  <td className="p-1" style={{cursor: "pointer"}} onClick={() => history.push("/courier-details/" + c.id)}>{c.firstname}
                    {c.is_verified && <CIcon
                      name="cilCheck"
                      className="text-success"
                    />}
                  </td>
                  <td className="p-1" style={{cursor: "pointer"}} onClick={() => history.push("/courier-details/" + c.id)}>{c.username}
                  </td>
                  <td>{c.delivery_office?.name}</td>
                  <td className="p-1 text-center">{c.has_thermal_bag ? <CIcon name="cilCheckAlt"/> : <CIcon name="cilX"/>}</td>
                  <td className="p-1">{tr(c.transport?.name_ru, c.transport?.name_kg)}</td>
                  <td className="p-1">
                    {c.status === "offline" ? (
                      <span className="badge badge-secondary">{c.status}</span>
                    ) : c.status === "emergency" ? (
                      <span className="badge badge-danger">{c.status}</span>
                    ) : (c.status === "online" ? (<span className="badge badge-success">{c.status}</span>
                    ) : (
                    <span className="badge badge-primary">{c.status}</span>))}
                  </td>
                  <td className="p-1">{date(c.last_updated)}
                  </td>
                  <td className="p-1">
                  <CDropdown>
                    <CDropdownToggle
                        color="secondary" size="sm" >
                            <CIcon name="cil-menu"/>
                    </CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem onClick={() => {setModalForm("depositBalance"); selectCourier(c); setModalTitle(t("Deposit Courier Balance")); onToggle()}}>{t("Пополнить баланс")}</CDropdownItem>
                        <CDropdownItem onClick={() => {setModalForm("edit"); selectCourier(c); onToggle(); setModalTitle(t("Edit Courier"))}}>{t("Редактировать")}</CDropdownItem>
                        <CDropdownItem onClick={() => {setModalForm("password"); selectCourier(c); onToggle(); setModalTitle("Edit Courier")}}>{t("Поменять пароль")}</CDropdownItem>
                        <CDropdownDivider/>
                        <CDropdownItem onClick={() => {setModalForm("delete"); selectCourier(c); onToggle(); setModalTitle(t("Confirm delete"))}}>{t("Delete")}</CDropdownItem>
                        <CDropdownItem onClick={() => {setModalForm("block"); selectCourier(c); onToggle(); setModalTitle(t("Block Courier"))}}>{c.is_blocked ? t("Разблокировать") : t("Блокировать")}</CDropdownItem>
                        {!c.is_verified && <CDropdownItem onClick={() => {selectCourier(c); verifyForm()}}>{t("Verify")}</CDropdownItem>}
                    </CDropdownMenu>
                </CDropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        </>
    )
}