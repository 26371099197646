import {
  CButton,
  CForm,
  CFormText,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { toastify } from "src/helpers/toast";
import {
  fetchCompanyAddressCreate,
  fetchCreateOffice,
  fetchOffice,
  fetchUpdateOffice,
} from "src/services/CompanyService";
import { useTranslation } from "react-i18next";
import { useTranslate } from "src/helpers/useTranslate";
import AddressSearchInput from "../../components/AddressSearchInput";

export default function OfficeForm(props) {
  const { t } = useTranslation();
  const { tr } = useTranslate();

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);

  const [office, setOffice] = useState({
    name: "",
    identifier: "",
    contact_phone: "",
  });

  useEffect(() => {
    if (props.selectedOffice) {
      fetchOffice(props.selectedOffice.id).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            let off = d;
            if (d.location) {
              off.location_id = d.location.id;
            }
            setOffice(off);
            setSelectedCities(
              off.working_cities?.map((dd) => ({
                label: tr(dd.name_ru, dd.name_kg),
                value: dd.id,
                ...dd,
              }))
            );
            setSelectedLocation(
              d.location
                ? {
                    label: tr(d.location.name_kg, d.location.name_kg),
                    value: d.location.id,
                    ...d.location,
                  }
                : null
            );
          })
      );
    } else {
      setOffice({
        name: "",
        identifier: "",
        contact_phone: "",
      });
      setSelectedLocation(null);
    }
    console.log(props);
  }, [props, tr]);

  const handleChange = (e) => {
    let val = e.target.value;
    let field = e.target.name;

    setOffice({ ...office, [field]: val });
  };

  const submitForm = () => {
    let formBody = {
      ...office,
      working_cities: selectedCities.map((city) => city.id),
    };

    if (!office.location_id && office.location_name) {
      fetchCompanyAddressCreate({
        name_kg: office.location_name,
        name_ru: office.location_name,
      })
        .then((res) => {
          if (res.ok) {
            res.json().then((d) => {
              formBody.location_id = d.id;
              sendRequest(formBody);
            });
          } else {
            toastify("error", t("Operation failed"));
          }
        })
        .catch((err) => {
          toastify("error", t("Operation failed"));
          console.log(err);
        });
    } else {
      sendRequest(formBody);
    }
  };

  const sendRequest = (body) => {
    (props.selectedOffice?.id
      ? fetchUpdateOffice(body, props.selectedOffice?.id)
      : fetchCreateOffice(body)
    )
      .then((res) => {
        if (res.ok) {
          props.onSuccess();
          toastify("success", t("Operation succeeded"));
        } else {
          res.json().then((d) => console.log(d, "create error"));
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", t("Operation failed"));
      });
    console.log(office);
  };

  return (
    <CModal show={props.isOpen} onClose={props.onToggle} size="lg">
      <CModalHeader>{t(props.modalTitle)}</CModalHeader>
      <CModalBody>
        <CForm>
          <CLabel>{t("Name")}</CLabel>
          <CInput
            name="name"
            value={office.name}
            onChange={handleChange}
            placeholder="name"
          />
          <CLabel>{t("Contact phone")}</CLabel>
          <CInput
            name="contact_phone"
            value={office.contact_phone}
            onChange={handleChange}
            placeholder="phone"
          />
          <CLabel>{t("Identifier")}</CLabel>
          <CInput
            name="identifier"
            value={office.identifier}
            onChange={handleChange}
            placeholder="identifier"
          />
          <CLabel>{t("Location")}</CLabel>

          <AddressSearchInput
            isClearable={true}
            onChange={(res) => {
              setSelectedLocation(res);
              if (res) {
                setOffice({
                  ...office,
                  location_name: res?.value,
                  location_id: res?.id,
                  city_id: res?.city?.id,
                  district_id: res?.district?.id,
                });
              }
            }}
            value={selectedLocation}
          />

          {/*<AsyncCreatableSelect */}
          {/*    className="mt-2"*/}
          {/*    loadOptions={promiseAddressOptions}*/}
          {/*    formatCreateLabel={res => t("Create") + " " + res}*/}
          {/*    isClearable*/}
          {/*    placeholder={t("Address")}*/}
          {/*    onChange={res => {*/}
          {/*        setSelectedLocation(res)*/}
          {/*        console.log(res)*/}
          {/*        if(res) {*/}
          {/*            setOffice({...office, location_name: res?.value, location_id: res?.id, city_id: res?.city?.id, district_id: res?.district?.id})*/}
          {/*        }*/}
          {/*    }} */}
          {/*    value={selectedLocation}*/}
          {/*    />*/}
          {selectedLocation && (
            <CFormText>
              {tr(selectedLocation.name_ru, selectedLocation.name_kg) +
                (selectedLocation.city
                  ? ", " +
                    tr(
                      selectedLocation.city.name_ru,
                      selectedLocation.city.name_kg
                    )
                  : "") +
                (selectedLocation.district
                  ? ", " +
                    tr(
                      selectedLocation.district.name_ru,
                      selectedLocation.district.name_kg
                    )
                  : "")}
            </CFormText>
          )}
        </CForm>
      </CModalBody>
      <CModalFooter className="text-right">
        <CButton color="secondary" className="mx-1" onClick={props.onToggle}>
          {t("Close")}
        </CButton>
        <CButton color="success" className="mx-1" onClick={submitForm}>
          {t(props.selectedOffice?.id ? "Save" : "Create")}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
