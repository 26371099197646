import React, { useState, useEffect } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useSelector, useDispatch } from "react-redux";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CRow,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CModalFooter,
  CButton,
  CFormGroup,
  CLabel,
  CTooltip,
  CForm,
} from "@coreui/react";
import { getAddresses } from "src/redux/actions/addressActions";
import { getOrganizations } from "src/redux/actions/organizationActions";
import CIcon from "@coreui/icons-react";
import { toastify } from "src/helpers/toast";
import {
  fetchCreateClient,
  fetchUpdateClient,
  fetchClientLastOrders,
} from "src/services/ClientService";
import { fetchCurrency } from "src/services/Currency";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { useTranslate } from "src/helpers/useTranslate";
import { dateParse } from "../reports/Reports";

import { YMaps, Map, Placemark } from "react-yandex-maps";
import {
  fetchClientRequisites,
  fetchDeleteClientRequisite,
  fetchUpdateClientRequisite,
} from "../../services/Requisites";
import RequisitePropertyForm from "./RequisitePropertyForm";

import { useConfirmModal } from "../../helpers/useModal";
import { setBreadcrumb } from "../../redux/actions/settingsActions";

const apiKey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

const ClientModals = (props) => {
  const { t, i18n } = useTranslation();
  const { tr } = useTranslate();
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const [modalTitle, setModalTitle] = useState("Modal Title");

  const center = [42.88, 74.58];
  const zoom = 6;

  const lang = i18n.language;
  const dispatch = useDispatch();
  const organizations = useSelector(
    (state) => state.organizations.organizations
  );
  const [clientOrders, setClientOrders] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "KGS",
    value: 1,
  });
  const [propertyForm, setPropertyForm] = useState(false);
  const [type, setType] = useState("indiv");
  const [id, setId] = useState(null);
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [desc, setDesc] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [orgId, setOrgId] = useState({
    label: lang === "ru" ? "Выбрать организацию" : "Мекемени тандоо",
    value: 0,
  });
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [telegram, setTelegram] = useState("");
  const [userInitials, setUserInitials] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [selectedRequisite, setSelectedRequisite] = useState(null);
  const [requisiteTypes, setRequisiteTypes] = useState([]);
  const [selectedRequisiteType, setSelectedRequisiteType] = useState({
    label: "Выбрать тип реквизита",
    value: "Выбрать тип реквизита",
  });

  const resetPropertyFormFields = () => {
    setPropertyForm(false);
    setAccountNumber("");
    setUserInitials("");
    setSelectedRequisiteType({
      label: "Выбрать тип реквизита",
      value: "Выбрать тип реквизита",
    });
    setSelectedCurrency({
      label: "KGS",
      value: 1,
    });
  };

  const onAddRequisite = () => {
    setSelectedRequisite(null);
    setAccountNumber("");
    setUserInitials("");
    setSelectedRequisiteType({
      label: "Выбрать тип реквизита",
      value: "Выбрать тип реквизита",
    });
    setSelectedCurrency({
      label: "KGS",
      value: 1,
    });
    setPropertyForm(!propertyForm);
  };

  const onUpdateRequisite = () => {
    let requisiteForm = {
      client_id: id,
      account_number: accountNumber,
      fullname: userInitials,
      requisite_type_title: selectedRequisiteType.label,
      currency_id: selectedCurrency.value,
    };

    if (selectedRequisite) {
      fetchUpdateClientRequisite(requisiteForm, selectedRequisite.id)
        .then((res) => {
          if (res.ok) {
            toastify("success", t("Реквизит успешно обновлён"));
            resetPropertyFormFields();
            fetchClientRequisites(id)
              .then((res) => {
                if (res.ok) {
                  res.json().then((data) => {
                    console.log(data, "requisites");
                    setRequisiteTypes(data);
                  });
                }
              })
              .catch((err) => console.log(err));
          } else {
            toastify("error", t("Произошла ошибка обновления реквизита"));
          }
        })
        .catch((err) => {
          toastify("error", t("Произошла ошибка"));
          console.log(err, "error");
        });
    }
  };

  const onShowDeleteRequisite = (requisite) => {
    setSelectedRequisite(requisite);
    dispatch(setBreadcrumb(["Requisites", t("Delete Requisite")]));
    setModalTitle(t("Delete Requisite"));
    onConfirmToggle();
  };

  const onDeleteRequisite = () => {
    fetchDeleteClientRequisite(selectedRequisite.id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("реквизит удален"));
          fetchClientRequisites(id)
            .then((res) => {
              if (res.ok) {
                res.json().then((data) => {
                  setRequisiteTypes(data);
                  onConfirmToggle();
                });
              }
            })
            .catch((err) => console.log(err));
        } else {
          toastify("error", "Произошла ошибка удаления");
        }
      })
      .catch((err) =>
        toastify("error", "Произошла ошибка, проверьте интернет")
      );
  };

  const showEditRequisite = (requisite) => {
    setSelectedRequisite(requisite);
    console.log(selectedRequisite, "selected requisite");
    setPropertyForm(!propertyForm);
    setSelectedRequisiteType({
      label: requisite.requisite_type?.title,
      value: requisite.requisite_type?.id,
    });
    setAccountNumber(requisite.account_number);
    setUserInitials(requisite.fullname);
    setSelectedCurrency({
      label: requisite.currency?.name,
      value: requisite.currency?.id,
    });
  };

  const onCancel = () => {
    props.toggle();
    setId("");
    setType("indiv");
    setClientOrders([]);
    setFullname("");
    setPhone("");
    setWhatsapp("");
    setDesc("");
    setOrgId("");
    setEmail("");
    setPosition("");
    setTelegram("");
    setPropertyForm(false);
    setRequisiteTypes([]);
    setAccountNumber("");
    setUserInitials("");
    setSelectedRequisiteType({
      label: "Выбрать тип реквизита",
      value: "Выбрать тип реквизита",
    });
    setSelectedCurrency({
      label: "KGS",
      value: 1,
    });
  };

  useEffect(() => {
    fetchCurrency()
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            console.log("currency", data);
            setCurrencies(data);
          });
        } else {
          console.log(res.statusText);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  }, []);

  useEffect(() => {
    if (props.selectedClient.id) {
      fetchClientLastOrders(props.selectedClient.id).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setClientOrders(data);
          });
        } else {
          console.log(res.statusText);
        }
      });
    }
  }, [props.selectedClient.id]);

  useEffect(() => {
    dispatch(getAddresses());
    dispatch(getOrganizations());
    if (props?.selectedClient) {
      fetchClientRequisites(props.selectedClient.id).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setRequisiteTypes(data);
          });
        }
      });
      console.log(props.selectedClient, "selectedClient");
      setId(props?.selectedClient.id || "");
      setType(props?.selectedClient.organization?.id ? "company" : "indiv");
      setFullname(props?.selectedClient.fullname || "");
      setPhone(props?.selectedClient.username || "");
      setSendSms(props?.selectedClient.send_sms || false);
      setOrgId(
        props?.selectedClient?.organization
          ? {
              label: props?.selectedClient?.organization.name,
              value: props?.selectedClient?.organization.id,
            }
          : {
              label: lang === "ru" ? "Выбрать организацию" : "Мекемени тандоо",
              value: 0,
            }
      );
      setPosition(props?.selectedClient?.position || "");
      setEmail(props?.selectedClient?.email || "");
      setTelegram(props?.selectedClient?.telegram || "");
      setWhatsapp(props?.selectedClient?.whatsapp || "");
      setDesc(props?.selectedClient?.description || "");
    }
  }, [dispatch, props.selectedClient, lang]);

  const submitClient = () => {
    const data =
      type === "indiv"
        ? {
            username: `+${phone}`,
            fullname,
            whatsapp: `+${whatsapp}`,
            telegram: telegram,
            description: desc,
            send_sms: sendSms,
          }
        : {
            username: `+${phone}`,
            fullname,
            organization_id: orgId.value === 0 ? null : orgId.value,
            position,
            email,
            telegram,
            whatsapp: `+${whatsapp}`,
            description: desc,
            send_sms: sendSms,
          };
    submitCreate(data);
  };

  const submitCreate = (data) => {
    fetchCreateClient(data)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Новый клиент успешно создан"));
          setType("indiv");
          setFullname("");
          setPhone("");
          setWhatsapp("");
          setDesc("");
          setOrgId({
            label: lang === "ru" ? "Выбрать организацию" : "Мекемени тандоо",
            value: 0,
          });
          setEmail("");
          setPosition("");
          setTelegram("");
        } else {
          toastify("error", t("Не удалось создать клиента"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка создания, проверьте интернет"))
      )
      .finally(props.toggle);
  };

  const updateClient = () => {
    const data =
      type === "indiv"
        ? {
            username: phone.substring(0, 1) === "+" ? phone : "+" + phone,
            fullname,
            whatsapp,
            description: desc,
            send_sms: sendSms,
          }
        : {
            username: phone.substring(0, 1) === "+" ? phone : "+" + phone,
            fullname,
            whatsapp,
            telegram,
            description: desc,
            send_sms: sendSms,
            email,
            position,
            organization_id: orgId.value,
          };

    fetchUpdateClient(data, id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Данные клиента успешно обновлены"));
          setType("indiv");
          setFullname("");
          setPhone("");
          setWhatsapp("");
          setDesc("");
          setOrgId("");
          setEmail("");
          setPosition("");
          setTelegram("");
        } else {
          toastify("error", t("Не удалось обновить данные клиента"));
        }
      })
      .catch((err) => toastify("error", "Произошла ошибка, проверьте интернет"))
      .finally(props.toggle);
  };
  return (
    <div>
      <CModal show={props.modal} onClose={onCancel} size="xl">
        <CModalHeader>
          {!id ? t("Create client") : t("Edit Client")}
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CRow className="mb-2">
                <CCol>
                  <input
                    type="radio"
                    id="indiv"
                    name="clientType"
                    value="indiv"
                    checked={type === "indiv"}
                    onChange={() => {
                      setType("indiv");
                    }}
                  />
                  <label htmlFor="indiv" className="mx-2">
                    {t("Individual")}
                  </label>
                </CCol>
                <CCol>
                  <input
                    type="radio"
                    id="company"
                    name="clientType"
                    checked={type === "company"}
                    value="company"
                    onChange={() => {
                      setType("company");
                    }}
                  />
                  <label htmlFor="company" className="mx-2">
                    {t("Legal")}
                  </label>
                </CCol>
              </CRow>

              <CInputGroup className="mb-2">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cil-user" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="text"
                  placeholder={t("Fullname")}
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </CInputGroup>

              {type === "company" ? (
                <>
                  <CInputGroup className="mb-2 align-items-center">
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      containerStyle={{ width: "100%" }}
                      onlyCountries={["kg"]}
                      country={"kg"}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      value={phone}
                      onChange={setPhone}
                      masks={{ kg: "(...) ..-..-.." }}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-2">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cilCompass" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <div style={{ width: "92%" }}>
                      <Select
                        value={orgId}
                        options={organizations.map((o) => {
                          return {
                            value: o.id,
                            label: o.name,
                          };
                        })}
                        onChange={(e) => {
                          setOrgId(e);
                        }}
                      />
                    </div>
                  </CInputGroup>

                  <CInputGroup className="mb-2">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-user" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      placeholder={t("Position")}
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                    />
                  </CInputGroup>

                  <CInputGroup className="mb-2">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cilInbox" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </CInputGroup>

                  <CInputGroup className="mb-2">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cibTelegram" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      placeholder="Telegram"
                      value={telegram}
                      onChange={(e) => setTelegram(e.target.value)}
                    />
                  </CInputGroup>
                </>
              ) : (
                <CInputGroup className="mb-2 align-items-center">
                  <PhoneInput
                    inputStyle={{ width: "100%" }}
                    containerStyle={{ width: "84%" }}
                    onlyCountries={["kg"]}
                    country={"kg"}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    value={phone}
                    onChange={setPhone}
                    masks={{ kg: "(...) ..-..-.." }}
                  />
                  <CIcon
                    name="cibWhatsapp"
                    className="text-success ml-2"
                    size="xl"
                    onClick={() => setWhatsapp(phone)}
                  />
                  <CIcon
                    name="cibTelegram"
                    className="text-success mx-2"
                    size="xl"
                    onClick={() => setTelegram(phone)}
                  />
                </CInputGroup>
              )}
              <CInputGroup className="mb-2">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cibWhatsapp" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="text"
                  placeholder="WhatsApp"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </CInputGroup>
              <CInputGroup className="mb-2">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <CIcon name="cibTelegram" />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="text"
                  placeholder="Telegram"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                />
              </CInputGroup>
              <CLabel>
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={sendSms}
                  onChange={(e) => setSendSms(e.target.checked)}
                />
                {t("Send sms")}
              </CLabel>

              <CRow className="mt-2">
                <CCol className="d-flex justify-content-between">
                  <h5>{t("Requisites")}</h5>
                  <CTooltip content={t("add requisite")}>
                    <CIcon
                      cursor="pointer"
                      className="text-success"
                      size="xl"
                      name="cilLibraryAdd"
                      onClick={onAddRequisite}
                    />
                  </CTooltip>
                </CCol>
              </CRow>

              {propertyForm && !selectedRequisite && (
                <CRow>
                  <CCol className="mb-3">
                    <>
                      <RequisitePropertyForm
                        selectedClientId={id}
                        selectedRequisite={selectedRequisite}
                        setPropertyForm={setPropertyForm}
                        propertyForm={propertyForm}
                        setSelectedRequisiteType={setSelectedRequisiteType}
                        selectedRequisiteType={selectedRequisiteType}
                        requisiteTypes={requisiteTypes}
                        setAccountNumber={setAccountNumber}
                        accountNumber={accountNumber}
                        setSelectedCurrency={setSelectedCurrency}
                        selectedCurrency={selectedCurrency}
                        currencies={currencies}
                        setUserInitials={setUserInitials}
                        userInitials={userInitials}
                        setRequisiteTypes={setRequisiteTypes}
                        resetPropertyFormFields={resetPropertyFormFields}
                      />
                    </>
                  </CCol>
                </CRow>
              )}

              {requisiteTypes && requisiteTypes.length > 0 && (
                <CRow>
                  <CCol>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("Requisite name")}</th>
                          <th>{t("Initials")}</th>
                          <th>{t("Account number")}</th>
                          <th>{t("Actions")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {requisiteTypes.map((requisite) => {
                          return propertyForm &&
                            requisite.id === selectedRequisite?.id ? (
                            <tr key={requisite.id}>
                              <td style={{ width: "35%" }}>
                                <CreatableSelect
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                    }),
                                  }}
                                  value={selectedRequisiteType}
                                  options={requisiteTypes.map((r) => {
                                    return {
                                      label: r.requisite_type?.title,
                                      value: r.requisite_type?.id,
                                    };
                                  })}
                                  onChange={(e) => {
                                    setSelectedRequisiteType(e);
                                  }}
                                  isClearable
                                  onCreateOption={(e) => {
                                    setSelectedRequisiteType({
                                      label: e,
                                      value: e,
                                    });
                                  }}
                                  cacheOptions
                                  formatCreateLabel={(e) => `Создать ${e}`}
                                />
                              </td>

                              <td style={{ width: "20%" }}>
                                <CInput
                                  value={userInitials}
                                  onChange={(e) =>
                                    setUserInitials(e.target.value)
                                  }
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <CInput
                                  value={accountNumber}
                                  onChange={(e) =>
                                    setAccountNumber(e.target.value)
                                  }
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <Select
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                    }),
                                  }}
                                  value={selectedCurrency}
                                  options={
                                    currencies &&
                                    currencies.map((c) => {
                                      return { label: c.name, value: c.id };
                                    })
                                  }
                                  onChange={(e) => {
                                    setSelectedCurrency(e);
                                  }}
                                />
                              </td>

                              <td style={{ width: "15%" }}>
                                <CIcon
                                  name="cilCheck"
                                  className="text-success mr-3"
                                  size="lg"
                                  cursor="pointer"
                                  onClick={onUpdateRequisite}
                                />

                                <CIcon
                                  name="cilX"
                                  className="text-danger mr-3"
                                  size="lg"
                                  cursor="pointer"
                                  onClick={(e) => setPropertyForm(false)}
                                />
                              </td>
                            </tr>
                          ) : (
                            <>
                              <tr key={requisite.id}>
                                <td>{requisite.requisite_type?.title}</td>
                                <td>{requisite.fullname}</td>
                                <td>{requisite.account_number}</td>
                                <td>
                                  <CIcon
                                    className="text-danger mr-2"
                                    name="cilTrash"
                                    cursor="pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onShowDeleteRequisite(requisite);
                                    }}
                                  />
                                  <CIcon
                                    className="text-warning"
                                    name="cilPencil"
                                    cursor="pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showEditRequisite(requisite);
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </CCol>
                </CRow>
              )}
            </CCol>

            <CCol>
              <div>
                <YMaps query={{ load: "package.full", apiKey }}>
                  <Map
                    width="100%"
                    height="60vh"
                    state={{ center: center, zoom: zoom }}
                    defaultState={{ center: [42.88, 74.58], zoom: 6 }}
                  >
                    {clientOrders.length > 0 &&
                      clientOrders.map(
                        (order) =>
                          order.location_from && (
                            <Placemark
                              geometry={[
                                order.location_from?.lat,
                                order.location_from?.lng,
                              ]}
                              options={{ iconColor: "green" }}
                            />
                          )
                      )}
                  </Map>
                </YMaps>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h5 className="mt-4">{t("History of orders")}</h5>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <table className="table table-responsive-xl">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Sender")}</th>
                    <th>{t("Receiver")}</th>
                    <th>{t("Parcel")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Price")}</th>
                  </tr>
                </thead>

                {clientOrders &&
                  id &&
                  clientOrders.length > 0 &&
                  clientOrders.slice(0, 5).map((o) => (
                    <tr key={o.id}>
                      <td>{o.id}</td>
                      <td>
                        {tr(
                          o.location_from?.city?.name_ru,
                          o.location_from?.city?.name_kg
                        )}{" "}
                        {tr(
                          o.location_from?.district?.name_ru,
                          o.location_from?.district?.name_kg
                        )}
                        {tr(o.location_from?.name_ru, o.location_from?.name_kg)}
                      </td>
                      <td>
                        {tr(
                          o.location_to?.city?.name_ru,
                          o.location_to?.city?.name_kg
                        )}{" "}
                        {tr(
                          o.location_to?.district?.name_ru,
                          o.location_to?.district?.name_kg
                        )}
                        {tr(o.location_to?.name_ru, o.location_to?.name_kg)}
                      </td>
                      <td>
                        {tr(o.package_type?.name_ru, o.package_type?.name_kg)}
                      </td>
                      <td>{t(o.status)}</td>
                      <td>{dateParse(o.created_at)}</td>
                      <td>{o.final_price}c</td>
                    </tr>
                  ))}
              </table>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          {/* <CButton className="btn outline">{t("Pick on Map")}</CButton> */}
          {id ? (
            <CButton
              type="button"
              variant="outline"
              color="success"
              onClick={() => {
                updateClient();
              }}
            >
              {t("Update")}
            </CButton>
          ) : (
            <CButton
              type="button"
              variant="outline"
              color="success"
              onClick={() => {
                submitClient();
              }}
            >
              {t("Create")}
            </CButton>
          )}
          <CButton
            type="button"
            variant="outline"
            color="dark"
            onClick={onCancel}
          >
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>

      {/*Modal for Delete Action*/}
      <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          {selectedRequisite && (
            <CForm>
              <CFormGroup>
                <CLabel htmlFor="nf-username">
                  {t("Delete this requisite?")}{" "}
                  <b>{selectedRequisite.requisite_type?.title}</b>
                </CLabel>
              </CFormGroup>
            </CForm>
          )}
        </CModalBody>
        <CModalFooter>
          {selectedRequisite && (
            <CRow>
              <CButton
                onClick={() => onDeleteRequisite(selectedRequisite)}
                color="danger"
                className="mr-2"
              >
                {t("Delete")}
              </CButton>
            </CRow>
          )}
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default ClientModals;
