import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CModal,
  CModalBody,
} from "@coreui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OrderDetails from "src/pages/orders/Order-Details/OrderDetails";
import { getOrders } from "src/redux/actions/orderActions";
import Table from "./Table";
import { useTable } from "./useTable";

export default function OrderList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orders = useSelector((state) => state.order.orders);

  const {
    openConfirmChange,
    openDetails,
    selectedOrder,
    setSelectedOrder,
    isOpen,
    onToggle,
    isConfirmOpen,
    onConfirmToggle,
    confirmChange,
    field,
  } = useTable();

  useEffect(() => {
    dispatch(getOrders({ isPartner: "partner" }));
  }, [dispatch]);

  console.log(orders);

  return (
    <div>
      <CCard className="m-2">
        <CCardHeader>
          <CCardTitle>{t("Orders")}</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <Table
            orders={orders}
            openDetails={openDetails}
            openConfirmChange={openConfirmChange}
          />
        </CCardBody>
      </CCard>
      {selectedOrder && (
        <OrderDetails
          isOpen={isOpen}
          onToggle={onToggle}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}

      {selectedOrder && (
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalBody>
            <h5 className="text-center">
              {t("Confirm")} {field} change?
            </h5>
            <div className="text-right">
              <CButton
                variant="outline"
                color="dark"
                onClick={onConfirmToggle}
                className="mx-2"
              >
                {t("Close")}
              </CButton>
              <CButton
                variant="outline"
                color="primary"
                onClick={confirmChange}
              >
                {t("Confirm")}
              </CButton>
            </div>
          </CModalBody>
        </CModal>
      )}
    </div>
  );
}
