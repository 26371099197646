import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalFooter,
  CModalHeader,
  CPagination,
  CRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import FullSpinner from "../../../components/spinners/FullSpinner";
import useUsers from "./useUsers";
import UsersTable from "./UsersTable";
import Select from "react-select";
import { roles } from "../helpers";

const Users = () => {
  const { t } = useTranslation();

  const {
    users,
    isLoading,
    totalPages,
    selectedRoles,
    setSelectedRoles,
    currentPage,
    setCurrentPage,
    columns,
    onCreateNew,
    toEditPage,
    selectedUser,
    confirmModal,
    setConfirmModal,
    onConfirm,
    onRemoveClick,
  } = useUsers();

  if (isLoading) return <FullSpinner />;
  return (
    <CCard className="m-2">
      <CCardHeader>
        <CRow>
          <CCol sm="4">
            <Select
              isMulti
              isClearable
              options={roles.map((role) => ({ value: role, label: t(role) }))}
              onChange={(res) => setSelectedRoles(res)}
              value={selectedRoles}
              placeholder={t("Role")}
            />
          </CCol>
        </CRow>
        <CButton
          variant="outline"
          color="success"
          className="float-right"
          onClick={onCreateNew}
        >
          {t("Create new user")}
        </CButton>
      </CCardHeader>
      <CCardBody>
        <UsersTable
          users={users}
          columns={columns}
          toEditPage={toEditPage}
          onRemoveClick={onRemoveClick}
        />
        <CModal show={confirmModal} onClose={() => setConfirmModal(false)}>
          <CModalHeader>
            {t("Are you sure to delete") + selectedUser?.fullname}
          </CModalHeader>
          <CModalFooter>
            <CButton color="primary" onClick={onConfirm}>
              Confirm
            </CButton>
            <CButton color="secondary" onClick={() => setConfirmModal(false)}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
        <CPagination
          activePage={currentPage}
          pages={totalPages}
          onActivePageChange={(i) => setCurrentPage(i)}
        />
      </CCardBody>
    </CCard>
  );
};

export default Users;
