import React, { useCallback, useEffect, useState } from "react";
import { CInput } from "@coreui/react";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useTranslation } from "react-i18next";
import { useTranslate } from "src/helpers/useTranslate";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { toastify } from "src/helpers/toast";
import { fetchCompanyAddressesByParams } from "src/services/LocationService";
import ClientInputWithSuggestions from "src/components/ClientInputWithSuggestions";
import { useLocation } from "react-router-dom";

export function ClientFields({
  allClients,
  selectClient,
  orderData,
  updateOrderData,
  emptyClient,
  emptyAddress,
  cities,
  getDistrictsFor,
  geoSelect,
  districts,
  promiseCityOptions,
  promiseDistrictOptions,
  promiseAddressOptions,
  allAddresses,
  handleAddress,
  role = "anonymouse",
  promiseClientOptions,
  setPoint,
  setAllAddressesReceiver,
}) {
  const location = useLocation();

  const { t } = useTranslation();
  const { tr } = useTranslate();

  const [iv, setIv] = useState("");
  const [isSender, setIsSender] = useState(null);

  const predictClientExist = () => {
    let phoneValue =
      iv.replace(/[^\d]/g, "").length > 8 ? iv.replace(/[^\d]/g, "") : iv;

    if (phoneValue.substring(0, 1) === "0") {
      phoneValue = "996" + phoneValue.substring(1);
    } else if (phoneValue.substring(0, 4) === "+996") {
      phoneValue = phoneValue.substring(1);
    } else if (phoneValue.substring(0, 5) === "00996") {
      phoneValue = phoneValue.substring(2);
    } else if (phoneValue.substring(0, 3) !== "996") {
      phoneValue = "996" + phoneValue;
    }
    return !(
      allClients.filter((ac) => ac.phone === "+" + phoneValue).length > 0
    );
  };

  useEffect(() => {
    setIsSender(role === "Sender");
  }, [role]);

  const getAddrs = useCallback(
    (value, isCity) => {
      let addrParams = {
        cityId: isCity ? value.id : value.city?.id,
        districtId: !isCity && value.id,
        limit: 10,
      };
      fetchCompanyAddressesByParams(addrParams).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            const allAddresses = d.map((dd) => {
              return {
                label:
                  tr(dd.name_ru, dd.name_kg) +
                  (dd.district
                    ? ", " + tr(dd.district.name_ru, dd.district.name_kg)
                    : "") +
                  (dd.city ? ", " + tr(dd.city.name_ru, dd.city.name_kg) : ""),
                value: dd.id,
                ...dd,
              };
            });
            setAllAddressesReceiver(allAddresses);
          })
      );
    },
    [setAllAddressesReceiver, tr]
  );

  return role ? (
    <div>
      <h6 className="text-dark text-uppercase my-n1 font-xs">{t(role)}</h6>
      <hr className="mt-0 my-2" />
      {location.pathname.includes("alt") ? (
        <ClientInputWithSuggestions
          invalid={isSender ? !orderData.senderPhone : undefined}
          setValue={(val) =>
            updateOrderData(isSender ? "senderPhone" : "receiverPhone", val)
          }
          value={
            (isSender ? orderData.senderPhone : orderData.receiverPhone) || ""
          }
          onOptionSelect={(option) => selectClient(option, isSender)}
          placeholder={t("Phone")}
        />
      ) : (
        <>
          <AsyncCreatableSelect
            styles={{
              control: (base) => ({
                ...base,
                border: emptyClient ? "Solid Red 1px" : "",
              }),
            }}
            loadOptions={promiseClientOptions}
            isValidNewOption={() => predictClientExist()}
            formatCreateLabel={(res) => t("Create") + " " + res}
            onInputChange={setIv}
            isClearable
            defaultOptions={allClients.map((cl) => {
              return {
                value: cl.id,
                label: `${cl.username} ${cl.fullname}`,
                ...cl,
              };
            })}
            placeholder={t("Phone")}
            onChange={(res) => selectClient(res, isSender)}
            value={
              (isSender
                ? orderData.selectedClientSender
                : orderData.selectedClientReceiver) || null
            }
            className="mb-2"
          />
          {(isSender
            ? orderData.selectedClientSender
            : orderData.selectedClientReceiver) && (
            <CInput
              type="text"
              className="my-2"
              value={
                (isSender
                  ? orderData.senderFullname || ""
                  : orderData.receiverFullname) || ""
              }
              onChange={(e) =>
                updateOrderData(
                  isSender ? "senderFullname" : "receiverFullname",
                  e.target.value
                )
              }
              placeholder={t(role + " name")}
              disabled={
                (isSender
                  ? orderData.selectedClientSender
                  : orderData.selectedClientReceiver
                )?.id
              }
            />
          )}
        </>
      )}
      <AsyncSelect
        defaultOptions={cities.map((city) => {
          return {
            value: city.id,
            label: tr(city.name_ru, city.name_kg),
            ...city,
          };
        })}
        loadOptions={promiseCityOptions}
        isClearable
        placeholder={tr("Город/Район", "Шаар/Район")}
        className="mb-2"
        onChange={(res) => {
          if (res?.id) {
            if (
              res.id !==
              (isSender
                ? orderData.selectedAddressSender
                : orderData.selectedAddressReceiver
              )?.city?.id
            ) {
              handleAddress(null);
            }
            getAddrs(res, true);
          }
          if (
            res?.id !==
            (isSender
              ? orderData.selectedDistrictSender
              : orderData.selectedDistrictReceiver
            )?.city?.id
          ) {
            updateOrderData(
              isSender ? "selectedDistrictSender" : "selectedDistrictReceiver",
              null
            );
          }
          getDistrictsFor(res, isSender);
          updateOrderData(
            isSender ? "selectedCitySender" : "selectedCityReceiver",
            res
          );
          geoSelect(res, isSender);
        }}
        value={
          isSender
            ? orderData.selectedCitySender
            : orderData.selectedCityReceiver
        }
      />
      <AsyncSelect
        isClearable
        className="mb-2"
        placeholder={tr("Мкрн/Село", "Кичирайон/Айыл")}
        defaultOptions={districts.map((d) => {
          return { value: d.id, label: tr(d.name_ru, d.name_kg), ...d };
        })}
        loadOptions={(res) => promiseDistrictOptions(res, role)}
        onChange={(res) => {
          if (res?.id) {
            if (
              res.id !==
              (isSender
                ? orderData.selectedAddressSender
                : orderData.selectedAddressReceiver
              )?.district?.id
            ) {
              handleAddress(null);
            }
            getAddrs(res);
            updateOrderData(
              isSender ? "selectedCitySender" : "selectedCityReceiver",
              {
                value: res.city?.id,
                label: tr(res.city?.name_ru, res.city?.name_kg),
                ...res.city,
              }
            );
          }
          updateOrderData(
            isSender ? "selectedDistrictSender" : "selectedDistrictReceiver",
            res
          );
          geoSelect(res, isSender);
        }}
        value={
          isSender
            ? orderData.selectedDistrictSender
            : orderData.selectedDistrictReceiver
        }
      />
      <AsyncCreatableSelect
        className="mt-2"
        styles={{
          control: (base) => ({
            ...base,
            border: emptyAddress ? "Solid Red 1px" : "",
          }),
        }}
        loadOptions={(res) => promiseAddressOptions(res, role)}
        formatCreateLabel={(res) => t("Create") + " " + res}
        isClearable
        placeholder={t("Address")}
        defaultOptions={allAddresses.map((address) => ({
          value: address.id,
          label: tr(address.name_ru, address.name_kg),
          ...address,
        }))}
        onChange={(res) => handleAddress(res)}
        value={
          isSender
            ? orderData.selectedAddressSender
            : orderData.selectedAddressReceiver
        }
        onCreateOption={(res) => {
          updateOrderData(
            isSender ? "selectedAddressSender" : "selectedAddressReceiver",
            { label: res, name_ru: res, name_kg: res }
          );
          setPoint(true);
          toastify("info", t("Select location on map"));
        }}
      />
      <i style={{ fontSize: "10px" }}>
        {isSender
          ? orderData.selectedAddressSender?.label
          : orderData.selectedAddressReceiver?.label}
      </i>
      <CInput
        className="mt-2 mr-1"
        placeholder={t("2gis link")}
        size="sm"
        disabled={
          isSender
            ? orderData.selectedAddressSender?.lat
            : orderData.selectedAddressReceiver?.lat
        }
        value={
          (isSender ? orderData.senderGisLink : orderData.receiverGisLink) || ""
        }
        onChange={(e) => {
          updateOrderData(
            isSender ? "senderGisLink" : "receiverGisLink",
            e.target.value
          );
          let lat = e.target.value
            .split("/")
            .slice(-1)[0]
            .split("?")[0]
            .split("%2C")[1];
          let lng = e.target.value
            .split("/")
            .slice(-1)[0]
            .split("?")[0]
            .split("%2C")[0];
          lat &&
            handleAddress({
              label:
                (isSender
                  ? orderData.selectedAddressSender?.label
                  : orderData.selectedAddressReceiver?.label) || "2gis loc",
              lat: lat,
              lng: lng,
            });
        }}
      />
      <div className="d-flex">
        <CInput
          className="mt-2 mr-1"
          placeholder={t("Flat")}
          size="sm"
          value={
            (isSender
              ? orderData.location_from_flat
              : orderData.location_to_flat) || ""
          }
          onChange={(e) =>
            updateOrderData(
              isSender ? "location_from_flat" : "location_to_flat",
              e.target.value
            )
          }
        />
        <CInput
          className="mt-2"
          placeholder={t("Floor")}
          size="sm"
          value={
            (isSender
              ? orderData.location_from_floor
              : orderData.location_to_floor) || ""
          }
          onChange={(e) =>
            updateOrderData(
              isSender ? "location_from_floor" : "location_to_floor",
              e.target.value
            )
          }
        />
      </div>
    </div>
  ) : (
    <MiniSpinner />
  );
}
