import {
    CCard,
    CCardHeader,
    CCardBody,
    CButton,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter
  } from "@coreui/react";
  import CIcon from "@coreui/icons-react";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { setBreadcrumb } from "src/redux/actions/settingsActions";
  import { useDispatch, useSelector } from "react-redux";
  import { fetchDeleteOffice, fetchOffices, fetchOfficesAll } from "src/services/CompanyService";
  import { useConfirmModal, useModalState } from "src/helpers/useModal";
  import { toastify } from "src/helpers/toast";
  import OfficeForm from "./OfficeForm";
  import MiniSpinner from "src/components/spinners/MiniSpinner";
import { useTranslate } from "src/helpers/useTranslate";
  
  export default function Offices() {
  
    const dispatch = useDispatch();
  
    const { isOpen, onToggle } = useModalState();
    const { isConfirmOpen, onConfirmToggle} = useConfirmModal()
  
    const { t } = useTranslation();
    const { tr } = useTranslate();
  
    const [offices, setOffices] = useState([]);
    const [modalTitle, setModalTitle] = useState("");
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [loading, setLoading] = useState(null);

    const profile = useSelector(state => state.auth.profile)
  
    useEffect(() => {
      dispatch(setBreadcrumb(["Offices of courier companies"]));
      setLoading(true)
      fetchOffices().then(res => res.ok && res.json().then(data => {
        console.log(data)
        setLoading(false)
        setOffices(data)
      })).catch(err => setLoading(err))
    }, [dispatch]);
    
    const showAll = () => {
        fetchOfficesAll().then(res => res.ok && res.json().then(data => {
          console.log(data)
          setOffices(data)
      }))
    }
    
    const onSuccess = () => {
        fetchOffices().then(res => res.json().then(data => {
        setOffices(data)
      }))
      onToggle();
    }
  
    const editOffice = (off) => {
        setSelectedOffice(off);
        onToggle();
        setModalTitle("Edit office");
    }

    const deleteOffice = (off) => {
      setSelectedOffice(off)
      onConfirmToggle()
    }
  
    const confirmDelete = () => {
      fetchDeleteOffice(selectedOffice.id).then(res => {
        if(res.ok) {
          toastify("success", t("Operation succeded"))
          setSelectedOffice(null)
          onConfirmToggle()
          setOffices(offices.filter(off => off.id !== selectedOffice.id))
        } else {
          toastify("error", t("Operation failed"))
        }
      }).catch(err => {
        console.log(err)
        toastify("error", t("Operation failed"))
      })
    }
    return (
      <CCard className="m-2">
        <CCardHeader>
          <CButton
            variant="outline"
            color="success"
            className="float-right"
            onClick={() => {
              setModalTitle("Create office");
              setSelectedOffice(null)
              onToggle();
            }}
          >
            {t("Create office")}
          </CButton>
          {profile?.roles.includes("super_admin") &&<CButton
            variant="outline"
            color="info"
            className="float-right mx-1"
            onClick={showAll}
          >
            {t("Show all")}
          </CButton>}
        </CCardHeader>
        <CCardBody>
          {loading ? <div className="text-center">
            <MiniSpinner/>
            </div> : 
          <div className="table-responsive-sm">
            <table className="table table-hover">
            <thead>
              <tr>
                <th>{t("ID")}</th>
                <th>{t("Identifier")}</th>
                <th>{t("Name")}</th>
                <th>{t("Contact phone")}</th>
                <th>{t("Location")}</th>
                {/* <th>{t("Balance")}</th> */}
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {offices &&
                offices.map((off) => (
                  <tr key={off.id}>
                    <td>{off.id}</td>
                    <td>{off.identifier}</td>
                    <td>{off.name}</td>
                    <td>{off.contact_phone}</td>
                    <td>{off.location && tr(off.location.name_ru, off.location.name_kg)}</td>
                    {/* <td>{off.balance}</td> */}
                    <td>
                      <CDropdown>
                        <CDropdownToggle
                            color="secondary" size="sm" >
                                <CIcon name="cil-menu"/>
                        </CDropdownToggle>
                        <CDropdownMenu>
                            {/* <CDropdownItem onClick={() => detailsOf(off)}>{t("Details")}</CDropdownItem> */}
                            <CDropdownItem onClick={() => editOffice(off)}>{t("Edit")}</CDropdownItem>
                            <CDropdownItem onClick={() => deleteOffice(off)}>{t("Delete")}</CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
          }
        </CCardBody>
        
        <OfficeForm isOpen={isOpen} onToggle={onToggle} modalTitle={modalTitle} 
            onSuccess={onSuccess} selectedOffice={selectedOffice}/>
       
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalHeader>
            {t("Confirm")}
          </CModalHeader>
          <CModalBody>
            {"Are you sure?"}
          </CModalBody>
          <CModalFooter className="text-right">
            <CButton onClick={confirmDelete} color="success">{t("Confirm")}</CButton>
            <CButton onClick={onConfirmToggle} color="secondary">{t("Close")}</CButton>
          </CModalFooter>
        </CModal>
  
      </CCard>
    );
  }
  