import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toastify } from "src/helpers/toast";
import { createOrderFetchPartner } from "src/services/OrderService";
import OrderForm from "./OrderForm";

export default function CreateOrder() {
  const history = useHistory();
  const onSubmit = useCallback(
    async (data) => {
      const response = await createOrderFetchPartner(data);
      if (response.ok) {
        toastify("success", "Order created successfully");
        history.push("/partner/orders");
      } else {
        toastify("error", "Order created failed");
      }
    },
    [history]
  );
  return (
    <div>
      <OrderForm title="Создать заказ" onSubmit={onSubmit} />
    </div>
  );
}
