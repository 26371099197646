import {
    ORDER_SUCCESS,
    ORDER_LOADING,
    ORDER_FAIL,
    ORDER_SELECT,
    NEW_ORDER
  } from '../types/orderTypes'
  
  const initialState = {
    orders: [],
    selectedOrders: [],
    error: null,
    isLoading: true,
    newOrder: {
      packages: [{}]
    }
  }
  
  export default function orderReducer(state = initialState, action) {
    switch (action.type) {
      case ORDER_LOADING:
        return {
          ...state,
          isLoading: true,
          error: null
        }
      case ORDER_SUCCESS:
        return {
          ...state,
          orders: action.payload,
          isLoading: false,
          error: null
        }
      case ORDER_FAIL:
        return {
          ...state,
          orders: [],
          isLoading: false,
          error: action.payload || null
        }
      case ORDER_SELECT:
        return {
          ...state,
          selectedOrders: action.payload,
          isLoading: false,
        }
      case NEW_ORDER:
        return {
          ...state,
          newOrder: action.payload,
          isLoading: false,
        }
      default:
        return {
          ...state
        }
    }
  }
  