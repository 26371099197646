import { toastify } from "src/helpers/toast";
import { fetchCreateClient } from "src/services/ClientService";
import { fetchCompanyAddressCreate } from "src/services/CompanyService";

export const fetchBodyComposer = (data) => {
  return {
    location_from_id: data.addressSender?.id,
    location_to_id: data.addressReceiver?.id,
    sender_id: data.sender?.id,
    receiver_id: data.receiver?.id,
    description: data.description,
    packages: data.packages,
    ...data
  };
};

export const fetchCreatesAndSubmit = (data, submitFunc) => {
  const bodyData = fetchBodyComposer(data);
  const promiseList = [[], [], [], []];
  if (!data.sender?.id && data.sender.label) {
    let phone = phoneComposer(data.sender.label);
    promiseList[0] = fetchCreateClient({
      fullname: data.sender.fullname || data.senderFullname || phone,
      phone: phone,
    });
  }
  if (data.receiver?.label && !data.receiver?.id) {
    let phone = phoneComposer(data.receiver.label);
    promiseList[1] = fetchCreateClient({
      fullname: data.receiver.fullname || data.receiverFullname || phone,
      phone: phone,
    });
  }
  if (!data.addressSender?.id && data.addressSender?.label) {
    promiseList[2] = fetchCompanyAddressCreate({
      name_kg: data.addressSender.label,
      name_ru: data.addressSender.label,
      city_id: data.selectedCitySender ? data.selectedCitySender.id : 3,
      district_id: data.selectedDistrictSender?.id,
      lat: data.addressSender.lat,
      lng: data.addressSender.lng,
    });
  }
  if (!data.addressReceiver?.id && data.addressReceiver?.label) {
    promiseList[3] = fetchCompanyAddressCreate({
      name_kg: data.addressReceiver.label,
      name_ru: data.addressReceiver.label,
      city_id: data.selectedCityReceiver ? data.selectedCityReceiver.id : 3,
      district_id: data.selectedDistrictReceiver?.id,
      lat: data.addressReceiver.lat,
      lng: data.addressReceiver.lng,
    });
  }
  Promise.all(promiseList)
    .then((values) => {
      Promise.all(
        values.map((v) => (v.toString().length > 0 ? v.json() : v))
      ).then((d) => {
        if (d[0].toString().length > 0) {
          bodyData.sender_id = d[0]?.id;
        }
        if (d[1].toString().length > 0) {
          bodyData.receiver_id = d[1]?.id;
        }
        if (d[2].toString().length > 0) {
          bodyData.location_from_id = d[2]?.id;
        }
        if (d[3].toString().length > 0) {
          bodyData.location_to_id = d[3]?.id;
        }
        console.log(bodyData);
        submitFunc(bodyData);
      });
    })
    .catch((err) => {
      toastify("error", err.toString());
      console.log(err);
    });
};

export const phoneComposer = (label) => {
  let phone = label;
  if (phone.substring(0, 1) === "0") {
    phone = "+996" + phone.substring(1, phone.length);
  } else if (phone.substring(0, 3) === "996") {
    phone = "+" + phone;
  } else if (phone.substring(0, 1) !== "+") {
    phone = "+996" + phone;
  }
  return phone;
};
