import { orderFetchShareText } from "src/services/OrderService"

export const addOrderDetailToClipboard = (order, tr, t, toastify) => {
    orderFetchShareText(order).then(res => {
        res.ok && res.json()
        .then(d => {
          const orderOid = d.oid
          const orderId = d.id
          const orderPid = d.pid
          const sender = d.sender
          const cityFrom = tr(d.city_from?.name_ru, d.city_from?.name_kg)
          const districtFrom =
                d.district_from === null ? "" : tr(d.district_from?.name_ru, d.district_from?.name_kg)
          const locationFrom = tr(d.location_from?.name_ru, d.location_from?.name_kg)
          const locationFromFlat = d.location_from_flat
          const locationFromFloor = d.location_from_floor
          const redemptionAmount = d.redemption_amount
          const receiver = d.receiver
          const cityTo = tr(d.city_to?.name_ru, d.city_to?.name_kg)
          const districtTo =
                d.district_to === null ? "" : tr(d.district_to?.name_ru, d.district_to?.name_kg)
          const locationTo = d.location_to === null ? "" : tr(d.location_to?.name_ru, d.location_to?.name_kg)
          const locationToFlat = d.location_to_flat
          const locationToFloor = d.location_to_floor
          const paying_side =
            d.paying_side === "receiver" ? "Получатель" :
            d.paying_side === "sender" ? "Отправитель" : "Вместе"
          const finalPrice = d.final_price
          const packageType = tr(d.package_type?.name_ru,d.package_type?.name_ru)
          const description = d.description
          const senderPaymentAmount = d.sender_payment_amount
          const receiverPaymentAmount = d.receiver_payment_amount
          const senderPaymentAmountIsPaid = d.sender_payment_is_paid
          const receiverPaymentAmountIsPaid = d.receiver_payment_is_paid
    
          navigator.clipboard.writeText(`*Заказ №: ${orderOid ? orderOid : orderId}* http://dms.kg/orders/${orderPid}  \n\nОтправитель: ${sender?.fullname}, ${sender?.username},\n🅰️Откуда: ${cityFrom && (cityFrom + ",")} ${districtFrom && (districtFrom + ", ")} ${locationFrom && (locationFrom)} ${locationFromFlat ? "\nКвартира: " + locationFromFlat : ""} ${locationFromFloor ? " Этаж: " + locationFromFloor : ""} \n${d.package_type ? "\n📦Тип посылки: " + packageType : ""} \nВыкуп: *${redemptionAmount || 0} ⊆* \n\nПолучатель: ${(receiver ? receiver?.fullname + ',' + receiver?.username : "нужно уточнить, ")} \n🅱️Куда: ${cityTo && (cityTo + ",")} ${districtTo && (districtTo + ", ")} ${locationTo && (locationTo)}  \n${locationToFlat ? "Квартира: " + locationToFlat : ""} ${locationToFloor ? " Этаж: " + locationToFloor : ""} \nОплачивает: ${paying_side === "Вместе" ? `*Совместно* \nОтправитель: *${senderPaymentAmount} ⊆* (${senderPaymentAmountIsPaid ? "Оплачено" : "Неоплачено"}) \nПолучатель: *${receiverPaymentAmount} ⊆* (${receiverPaymentAmountIsPaid ? "Оплачено" : "Неоплачено"})` : `${paying_side} \n💰 За доставку: *${finalPrice || 0} ⊆* (${paying_side === "Получатель" ? receiverPaymentAmountIsPaid ? "Оплачено" : "Неоплачено" : paying_side === "Отправитель" ? senderPaymentAmountIsPaid ? "Оплачено" : "Неоплачено" : ""})`} 
          \nКомментарий : ${description ? description : ""}
         `)
             toastify("success", "Order text copied to clipboard")
        })
    }).catch(err => toastify(t("danger", "Operation failed")))
}