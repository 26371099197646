import React, {useEffect, useState} from 'react';
import {CCol, CInput, CLabel, CRow} from "@coreui/react";
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import {useTranslation} from "react-i18next";
import {useTranslate} from "../../../../helpers/useTranslate";

const CourierContract = ({ selectedCourier, offices, onUploadPhoto, setInitForm, initForm, setRadioTypeIsActive, activePage, setOffice, office, setRoles, roles, takeRole }) => {

    const { tr } = useTranslate();

    const { t, i18n } = useTranslation();
    const [fieldChecked, setFieldChecked] = useState(false)

    useEffect(() => {
        if(selectedCourier){
            if(selectedCourier?.delivery_office?.id){
                setFieldChecked(state => ({...state, fieldChecked: true}))
            }
        }
    }, [selectedCourier])

    useEffect(() => {
        setRadioTypeIsActive(prevState => ({...prevState, contractData: fieldChecked}))
    }, [fieldChecked])

    const onOfficeSelect = (off) => {
        let res = off
        if (res){
            setFieldChecked(true)
        }else{
            setFieldChecked(false)
        }
        setOffice(res)
        setInitForm(state => ({...state, delivery_office_id: res?.id}))
    }

    const onRoleSelect = (role) => {
        takeRole(role)
        let res = role
        setRoles(res)
    }

    return (
        <CRow className="ml-2" style={{display: activePage === 4 ? "block" : "none"}}>
            <CCol>
                <CLabel>{t("Офис курьерной службы")}*</CLabel>
            </CCol>

            <div style={{width: "100%"}} className='d-flex align-items-center'>
                <CCol xs={10}>
                    <Select placeholder="Выберите офис курьерной службы"
                            isClearable
                            value={office}
                            options={offices.map((office) => ({value: office.id, label: office.name, ...office}))}
                            onChange={(option) => onOfficeSelect(option)}
                    />
                </CCol>
                <CIcon
                    name={fieldChecked ? "cilCheckCircle" : "cilWarning" }
                    className={"ml-2 " + (fieldChecked ? "text-success" : "text-danger")}
                    size="xl"
                />
            </div>


            <CCol className="mt-3" xs={10}>
                <CLabel>{t("Role")}</CLabel>
                <Select
                    isMulti
                    onChange={(e) => onRoleSelect(e)}
                    value={roles}
                    options={[
                        {label: t("Courier"), value: "courier"},
                        {label: t("Packer"), value: "packer"},
                        {label: t("Carrier"), value: "carrier"},
                    ]}
                />
            </CCol>
            <CCol className="mt-3" xs={10}>
                <CLabel>{t("Договор")}</CLabel>
                <CInput
                    type="file"
                    onChange={(e) => onUploadPhoto(e, "contract")}
                />
            </CCol>





        </CRow>
    );
};

export default CourierContract;