import React, {useEffect, useState} from 'react';
import {CRow, CCol, CLabel, CInput} from "@coreui/react";
import {useTranslation} from "react-i18next";
import CIcon from "@coreui/icons-react";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {useTranslate} from "../../../../helpers/useTranslate";

const PassportData = ({onUploadPhoto, setRadioTypeIsActive, setInitForm, initForm, cities, promiseCityOptions, promiseDistrictOptions, handleCityChange, city, districts, district, setDistrict, activePage }) => {
    const { t, i18n } = useTranslation();
    const { tr } = useTranslate();
    const [fieldChecked, setFieldChecked] = useState({
        passportFront: false,
        passportBack: false
    })

    useEffect(() => {
        setRadioTypeIsActive(prevState => ({...prevState, passportData: !Object.values(fieldChecked).includes(false)}))
    }, [fieldChecked])

    const onUploadPassport = (e, imgName) => {
        if (imgName === "passport_front"){
            if (e.target?.files.length >= 1){
                setFieldChecked(state => ({...state, passportFront: true}))
            }else{
                setFieldChecked(state => ({...state, passportFront: false}))
            }
        }else{
            if (e.target?.files.length >= 1){
                setFieldChecked(state => ({...state, passportBack: true}))
            }else{
                setFieldChecked(state => ({...state, passportBack: false}))
            }
        }
        onUploadPhoto(e,imgName)
    }

    const handleDistrictChange = (district) => {
        let dist = district
        setDistrict(dist)
        setInitForm(state => ({...state, district_id: dist?.value}))
    }

    return (
        <CRow className='ml-2' style={{display: activePage === 2 ? "block" : "none"}}>
            <CRow style={{width: "100%"}} >
                <CCol xs={10}>
                    <CLabel>{t("City")}*</CLabel>
                    <div style={{width: "92%"}}>
                        <AsyncSelect
                            defaultOptions={cities.map(city => { return { value: city.id,label: tr(city.name_ru, city.name_kg), ...city}})}
                            loadOptions={promiseCityOptions}
                            isClearable
                            placeholder={tr("Город/Район","Шаар/Район")}
                            onChange={res => handleCityChange(res)}
                            value={city}
                        />
                    </div>
                </CCol>

                <CCol className="mt-3" xs={10}>
                    <CLabel>{t("District")}*</CLabel>
                    <div style={{width: "92%"}}>
                        <AsyncSelect
                            isClearable
                            className="mb-2"
                            placeholder={tr("Мкрн/Село","Кичирайон/Айыл")}
                            defaultOptions={districts.map(d => { return { value: d.id,label: tr(d.name_ru, d.name_kg), ...d}})}
                            loadOptions={promiseDistrictOptions}
                            onChange={res => handleDistrictChange(res)}
                            value={district}
                        />
                    </div>
                </CCol>

                <CCol className="mt-3" xs={10}>
                    <CLabel>{t("Personal Number")}</CLabel>
                    <CInput style={{width: "92%"}}
                        type="text"
                        placeholder={t("Personal Number")}
                        name="personal_number"
                        value={initForm.personal_number}
                        onChange={(e) => setInitForm(state => ({...state, [e.target.name]: e.target.value}))}
                    />
                </CCol>

                <CCol className="mt-3" xs={10}>
                    <CLabel>{t("Passport front image")}*</CLabel>
                    <div className = "d-flex align-items-center">
                        <CInput
                            type="file"
                            onChange={(e) => onUploadPassport(e,  "passport_front")}
                        />
                        <CIcon
                            name={fieldChecked.passportFront ? "cilCheckCircle" : "cilWarning" }
                            className={"ml-2 " + (fieldChecked.passportFront ? "text-success" : "text-danger")}
                            size="xl"
                        />
                    </div>

                </CCol>
            </CRow>
            <CRow style={{width: "100%"}}  className="mt-3">
                <CCol xs={10}>
                    <CLabel>{t("Passport back image")}*</CLabel>
                    <div className = "d-flex align-items-center">
                        <CInput
                            type="file"
                            onChange={e => onUploadPassport(e, "passport_back")}
                        />
                        <CIcon
                            name={fieldChecked.passportBack ? "cilCheckCircle" : "cilWarning" }
                            className={"ml-2 " + (fieldChecked.passportBack ? "text-success" : "text-danger")}
                            size="xl"
                        />
                    </div>
                </CCol>
            </CRow>

        </CRow>
    );
};

export default PassportData;