import CIcon from "@coreui/icons-react";
import { CBadge } from "@coreui/react";
import { useTranslation } from "react-i18next";

const UsersTable = ({ users, columns, toEditPage, onRemoveClick }) => {
  const { t } = useTranslation();
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={"head" + col.key}>{t(col.header)}</th>
          ))}
          <th>{t("roles")}</th>
          <th>{t("Actions")}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            {columns.map((col) => (
              <td key={"row" + col.key}>{user[col.key]}</td>
            ))}
            <td>
              {user.roles?.map((role) => (
                <CBadge color="primary" className="mx-1" key={role}>
                  {role}
                </CBadge>
              ))}
            </td>
            <td>
              <CIcon
                onClick={() => onRemoveClick(user)}
                name="cilTrash"
                className="text-danger mr-2"
                size="xl"
                style={{ cursor: "pointer" }}
              />
              <CIcon
                onClick={() => toEditPage(user.id)}
                name="cilPencil"
                className="text-warning"
                size="xl"
                style={{ cursor: "pointer" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsersTable;
