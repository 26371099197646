import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CTextarea,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select from "react-select";
import { useTranslate } from "src/helpers/useTranslate";
import { Package } from "src/pages/orders/New-Order/ParcelFields";
import { useOrderForms } from "./useOrderForm";

export default function OrderForm({ onSubmit, title }) {
  const { t } = useTranslation();
  const { tr } = useTranslate();
  const {
    emptySender,
    emptyReceiver,
    promiseClientOptions,
    allClientsSender,
    allClientsReceiver,
    orderData,
    offices,
    defaultOffice,
    onFieldChange,

    emptyAddress,
    promiseAddressOptions,
    allAddressSender,
    allAddressReceiver,

    updatePack,
    deletePack,
    packagesTypes,
    submitForm,
  } = useOrderForms({ onSubmit });

  return (
    <div>
      <CCard className="m-1">
        <CCardHeader>
          <CCardTitle>{title}</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol md="4">
              <h6 className="text-dark text-uppercase font-xs">
                {t("Sender")}
              </h6>
              <hr className="mt-0 my-2" />
              <AsyncCreatableSelect
                styles={{
                  control: (base) => ({
                    ...base,
                    border: emptySender ? "Solid Red 1px" : "",
                  }),
                }}
                loadOptions={(val) => promiseClientOptions("sender", val)}
                formatCreateLabel={(res) => t("Create") + " " + res}
                isClearable
                defaultOptions={allClientsSender.map((cl) => {
                  return { value: cl.id, label: cl.fullname, ...cl };
                })}
                placeholder={t("Phone")}
                onChange={(res) => onFieldChange("sender", res)}
                value={orderData.sender}
                className="mb-2"
              />
              <AsyncCreatableSelect
                className="mt-2"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: emptyAddress ? "Solid Red 1px" : "",
                  }),
                }}
                loadOptions={(val) => promiseAddressOptions("sender", val)}
                formatCreateLabel={(res) => t("Create") + " " + res}
                isClearable
                placeholder={t("Address")}
                defaultOptions={allAddressSender.map((address) => ({
                  value: address.id,
                  label: tr(address.name_ru, address.name_kg),
                  ...address,
                }))}
                onChange={(res) => onFieldChange("addressSender", res)}
                value={orderData.addressSender}
              />
              <Select
                className="mt-2"
                defaultValue={{
                  label: defaultOffice?.name,
                  value: defaultOffice?.id,
                  ...defaultOffice,
                }}
                value={orderData.delivery_office || null}
                isSearchable
                name="deliveryOffice"
                options={offices?.map((o) => ({
                  label: o.name,
                  value: o.id,
                  ...o,
                }))}
                onChange={(res) => {
                  onFieldChange("delivery_office_id", res.id);
                  onFieldChange("delivery_office", res);
                }}
                placeholder="Офис"
              />
            </CCol>
            <CCol md="4">
              <h6 className="text-dark text-uppercase font-xs">
                {t("Receiver")}
              </h6>
              <hr className="mt-0 my-2" />
              <AsyncCreatableSelect
                styles={{
                  control: (base) => ({
                    ...base,
                    border: emptyReceiver ? "Solid Red 1px" : "",
                  }),
                }}
                loadOptions={(val) => promiseClientOptions("receiver", val)}
                formatCreateLabel={(res) => t("Create") + " " + res}
                isClearable
                defaultOptions={allClientsReceiver.map((cl) => {
                  return { value: cl.id, label: cl.fullname, ...cl };
                })}
                placeholder={t("Phone")}
                onChange={(res) => onFieldChange("receiver", res)}
                value={orderData.receiver}
                className="mb-2"
              />
              <AsyncCreatableSelect
                className="mt-2"
                loadOptions={(val) => promiseAddressOptions("receiver", val)}
                formatCreateLabel={(res) => t("Create") + " " + res}
                isClearable
                placeholder={t("Address")}
                defaultOptions={allAddressReceiver.map((address) => ({
                  value: address.id,
                  label: tr(address.name_ru, address.name_kg),
                  ...address,
                }))}
                onChange={(res) => onFieldChange("addressReceiver", res)}
                value={orderData.addressReceiver}
              />
            </CCol>
            <CCol md="4">
              <h6 className="text-dark text-uppercase font-xs">
                {t("Parcel")}
              </h6>
              <hr className="mt-0 my-2" />
              {orderData.packages?.map((pack, i) => (
                <Package
                  key={i}
                  i={i}
                  packagesTypes={packagesTypes}
                  updatePack={(field, value) => updatePack(i, field, value)}
                  pack={pack}
                  tr={tr}
                  t={t}
                  isShowDeleteBtn={orderData.packages?.length > 1}
                  deletePack={() => deletePack(i)}
                />
              ))}
              <CTextarea
                rows="5"
                placeholder={t("Comments")}
                className="mb-2"
                value={orderData.description || ""}
                onChange={(e) => onFieldChange("description", e.target.value)}
              />
            </CCol>
          </CRow>
          <div className="d-flex justify-content-end">
            <CButton color="success" onClick={submitForm}>
              {t("Submit")}
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
}
