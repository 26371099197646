import { CCol, CInput, CRow, CSelect } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useTranslate } from "src/helpers/useTranslate";
import { fetchCompanyStatuses } from "src/services/CompanyService";

export function CourierFields({
  orderData,
  updateOrderData,
  sortedCourierList,
  courierList,
}) {
  const { t } = useTranslation();
  const { tr } = useTranslate();

  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    fetchCompanyStatuses().then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setStatusList(data);
        });
      }
    });
  }, []);

  const setTime = (e, type) => {
    let d = new Date();
    d.setHours(e.target.value.substring(0, 2));
    d.setMinutes(e.target.value.substring(3, 5));
    updateOrderData(type, d);
  };

  const currentStatus = statusList?.find((s) => s.value === orderData.status);
  return (
    <div>
      <h6 className="text-dark text-uppercase my-n1 font-xs">{t("Courier")}</h6>
      <hr className="mt-0 my-2" />
      <Select
        className="my-2"
        isClearable
        placeholder={t("Courier")}
        options={(sortedCourierList || courierList).map((c) => {
          return {
            value: c.id,
            label:
              c.fullname +
              " (" +
              t(c.roles[0] === "courier" ? "Courier" : "Carrier") +
              ")",
            ...c,
          };
        })}
        onChange={(res) => updateOrderData("selectedCourier", res)}
        value={orderData?.selectedCourier}
      />
      <Select
        className="my-2"
        isClearable
        placeholder={t("Status")}
        options={statusList.map((s) => ({
          value: s.value,
          label: tr(s.name_ru, s.name_kg),
        }))}
        onChange={(res) => updateOrderData("status", res)}
        value={
          currentStatus && {
            label: tr(currentStatus?.name_ru, currentStatus?.name_kg),
            ...currentStatus,
          }
        }
      />
      <div className="my-2">
        <span className="font-xs">{t("Pickup time")}</span>
        <CRow>
          <CCol className="pr-1">
            <CSelect
              size="sm"
              onChange={(e) => updateOrderData("pickUpDay", e.target.value)}
            >
              <option value="today">{t("Today")}</option>
              <option value="tomorrow">{t("Tomorrow")}</option>
            </CSelect>
          </CCol>
          <CCol className="pl-1">
            <CInput
              size="sm"
              type="time"
              id="appt"
              name="appt"
              min="00:00"
              max="23:59"
              onChange={(e) => setTime(e, "pickupTimeStamp")}
            />
          </CCol>
        </CRow>
        <span className="font-xs">{t("Delivery time")}</span>
        <CRow>
          <CCol className="pr-1">
            <CSelect
              size="sm"
              onChange={(e) => updateOrderData("deliveryDay", e.target.value)}
            >
              <option value="today">{t("Today")}</option>
              <option value="tomorrow">{t("Tomorrow")}</option>
            </CSelect>
          </CCol>
          <CCol className="pl-1">
            <CInput
              size="sm"
              type="time"
              id="appt"
              name="appt"
              min="00:00"
              max="23:59"
              onChange={(e) => setTime(e, "deliveryTimeStamp")}
            />
          </CCol>
        </CRow>
      </div>
    </div>
  );
}
