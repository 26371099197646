import CIcon from "@coreui/icons-react";
import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toastify } from "src/helpers/toast";
import { useModalState } from "src/helpers/useModal";
import { fetchPartnerOperators } from "src/services/PartnerService";
import PartnerOperatorForm from "./PartnerOperatorForm";

export default function PartnerOperators({
  partners,
  operators,
  setOperators,
}) {
  const { isOpen, onToggle } = useModalState();

  const { t } = useTranslation();
  const history = useHistory();

  const [modalTitle, setModalTitle] = useState("");
  const [selectedPartnerOperator, setSelectedPartnerOperator] = useState(null);

  const onSuccess = () => {
    toastify("success", "Operation succeeded");
    fetchPartnerOperators().then(
      (res) =>
        res.ok &&
        res.json().then((d) => {
          setOperators(d);
        })
    );
    onToggle();
  };

  const editOperator = useCallback(
    (p) => {
      history.push("/user/edit/" + p.id);
    },
    [history]
  );

  const deleteOperator = (o) => {
    setSelectedPartnerOperator(o);
    onToggle();
    setModalTitle(t("Delete"));
  };

  return (
    <div>
      <CButton
        variant="outline"
        color="success"
        className="float-right my-1 d-none"
        onClick={() => {
          setModalTitle("Create");
          setSelectedPartnerOperator(null);
          onToggle();
        }}
      >
        {t("Create")}
      </CButton>
      <div className="table-responsive-sm">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("ID")}</th>
              <th>{t("Username")}</th>
              <th>{t("Fullname")}</th>
              <th>{t("Partner")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {operators &&
              operators.map((o) => (
                <tr key={o.id}>
                  <td>{o.id}</td>
                  <td>{o.username}</td>
                  <td>{o.fullname}</td>
                  <td>{o.partner?.name}</td>
                  <td>
                    <CDropdown>
                      <CDropdownToggle color="secondary" size="sm">
                        <CIcon name="cil-menu" />
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem onClick={() => editOperator(o)}>
                          {t("Edit")}
                        </CDropdownItem>
                        <CDropdownItem onClick={() => deleteOperator(o)}>
                          {t("Delete")}
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PartnerOperatorForm
        isOpen={isOpen}
        partners={partners}
        onToggle={onToggle}
        modalTitle={modalTitle}
        onSuccess={onSuccess}
        selectedPartnerOperator={selectedPartnerOperator}
      />
    </div>
  );
}
