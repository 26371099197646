import { useParams } from "react-router-dom"
import OrderForm from "./OrderForm";

export default function EditOrder () {
    const params = useParams();
    console.log(params.id)
    return (
        <div>
            Edit Order page
            <OrderForm />    
        </div>
    )
}