import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CLabel,
  CRow,
} from "@coreui/react";
import React from "react";
import { FormControl } from "./FormControl";
import { roles } from "../helpers";
import { useTranslation } from "react-i18next";
import useForm from "./useForm";

const UsersForm = ({ title, isNew, currentUser }) => {
  const { t } = useTranslation();
  const {
    userForm,
    onChangeField,
    onSubmit,
    handleFileChange,
    photoUpStatus,
    offices,
    partners,
    composeSelectObject,
  } = useForm({ isNew, currentUser });
  return (
    <CCard className="m-2">
      <CCardHeader>{title}</CCardHeader>
      <CCardBody>
        <CForm>
          <CRow>
            <CCol sm="4">
              <FormControl
                label={t("Username") + "*"}
                onChange={(value) => onChangeField("username", value)}
                value={userForm.username}
              />
              <FormControl
                label={t("Fullname")}
                onChange={(value) => onChangeField("fullname", value)}
                value={userForm.fullname}
              />
              <FormControl
                label={t("Password")}
                onChange={(value) => onChangeField("password", value)}
                value={userForm.password}
              />
            </CCol>
            <CCol sm="4">
              <FormControl
                label={t("Roles")}
                isMulti={true}
                type="select"
                options={composeSelectObject(roles)}
                onChange={(res) =>
                  onChangeField(
                    "roles",
                    res.map((r) => r.value)
                  )
                }
                value={composeSelectObject(userForm.roles)}
              />
              {userForm.roles?.includes("partner") && (
                <FormControl
                  label={t("Partner")}
                  isMulti={false}
                  type="select"
                  options={partners.map((partner) => ({
                    value: partner.id,
                    label: partner.name,
                    ...partner,
                  }))}
                  onChange={(res) => {
                    onChangeField("partner_id", res?.value);
                    onChangeField("partner", res);
                  }}
                  value={
                    userForm.partner && {
                      value: userForm.partner?.id,
                      label: userForm.partner?.name,
                    }
                  }
                />
              )}
              <FormControl
                label={t("Office")}
                isMulti={false}
                type="select"
                options={offices.map((office) => ({
                  value: office.id,
                  label: office.name,
                  ...office,
                }))}
                onChange={(res) => {
                  onChangeField("delivery_office_id", res?.value);
                  onChangeField("delivery_office", res);
                }}
                value={
                  userForm.delivery_office && {
                    value: userForm.delivery_office?.id,
                    label: userForm.delivery_office?.name,
                  }
                }
              />
              <FormControl
                label={t("Birth date")}
                onChange={(value) => onChangeField("birthdate", value)}
                value={userForm.birthdate}
                type={"date"}
              />
            </CCol>
            <CCol sm="4">
              <FormControl
                label={t("Photo")}
                onChange={(file) => handleFileChange("photo", file)}
                type="file"
              />
              <FormControl
                label={t("Passport Front")}
                onChange={(file) => handleFileChange("passport_front", file)}
                type="file"
              />
              <FormControl
                label={t("Passport Back")}
                onChange={(file) => handleFileChange("passport_back", file)}
                type="file"
              />
              <div className="mt-2">
                <CLabel>
                  <input
                    type="checkbox"
                    className="mr-1"
                    onChange={(e) =>
                      onChangeField("is_registered", e.target.checked)
                    }
                    checked={userForm.is_registered || false}
                  />
                  {t("Registered?")}
                </CLabel>
              </div>
              <div className="mt-2">
                <CLabel>
                  <input
                    type="checkbox"
                    className="mr-1"
                    onChange={(e) =>
                      onChangeField("is_verified", e.target.checked)
                    }
                    checked={userForm.is_verified || false}
                  />
                  {t("Verified?")}
                </CLabel>
              </div>
            </CCol>
          </CRow>
          <i>* Example of username: 996777000111</i>
        </CForm>
        <i>{photoUpStatus}</i>
      </CCardBody>
      <CCardFooter className="d-flex justify-content-end">
        <CButton color="success" variant="outline" onClick={onSubmit}>
          {t(isNew ? "Submit" : "Save")}
        </CButton>
      </CCardFooter>
    </CCard>
  );
};

export default UsersForm;
