import {
  COURIER_SUCCESS,
  COURIER_LOADING,
  COURIER_FAIL
} from '../types/courierTypes'

import { fetchCouriers } from "../../services/CourierService";

export const getCouriers = () => async (dispatch, getState) => {
  dispatch(courierLoading());

  fetchCouriers()
    .then((res) =>
      {
        if(res.ok) {
          res.json().then((data) => {
            dispatch(courierSuccess(data.items));
          })
        } else {
          dispatch(courierFail(res.statusText))
        }

      }
    )
    .catch((err) => {
      dispatch(courierFail(err));
    });
};

export const courierLoading = () => ({ type: COURIER_LOADING });

export const courierSuccess = (couriers) => ({
  type: COURIER_SUCCESS,
  payload: couriers,
});
export const courierFail = (error) => ({ type: COURIER_FAIL, payload: error });

export const courierListUpdate = (courier) => async(dispatch, getState) => {

  let currentCourier = getState().courier.couriers.filter(c => c.id === courier.courier_id)[0]
  
  if(courier && currentCourier) {
    if(courier.status) {
      currentCourier.status = courier.status;
    } 
    if (courier.lat && courier.lng) {
      currentCourier.lat = courier.lat;
      currentCourier.lng = courier.lng;
    } 
    if (courier.last_updated) {
      currentCourier.last_updated = courier.last_updated;
    }
    
    let courierList = getState().courier.couriers;
    courierList[courierList.findIndex((c => c.id === courier.courier_id))] = currentCourier
    // courierList.unshift(currentCourier);
    console.log(courierList[courierList.findIndex((c => c.id === courier.courier_id))].status)
    dispatch(courierSuccess(courierList));
  }
}