import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toastify } from "src/helpers/toast";
import { fetchUsers, removeUser } from "../../../services/UsersService";
import { capitalizeString } from "../helpers";

const columns = [
  { header: "Fullname", key: "fullname" },
  { header: "Phone number", key: "username" },
];

const useUsers = () => {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const role = params.role;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    role &&
      setSelectedRoles(() => [
        { value: role, label: t(capitalizeString(role)) },
      ]);
  }, [role, t]);

  useEffect(() => {
    fetchUsers(
      [...selectedRoles?.map((role) => role?.value?.toLowerCase())],
      currentPage
    )
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setUsers(data.items);
            setTotalPages(data.pages);
            console.log(data, "all users");
            setIsLoading(false);
          });
        }
      })
      .catch((err) => {
        console.log(err, "error fetch");
        setIsLoading(false);
      });
  }, [currentPage, selectedRoles]);

  const onCreateNew = useCallback(() => {
    history.push("/user/create");
  }, [history]);

  const toEditPage = useCallback(
    (id) => {
      history.push("/user/edit/" + id);
    },
    [history]
  );

  const onRemoveClick = useCallback((user) => {
    setSelectedUser(user);
    setConfirmModal(true);
  }, []);

  const onRemoveSuccess = useCallback((id) => {
    toastify("success", "User removed successfully");
    setConfirmModal(false);
    setUsers((list) => list.filter((l) => l.id === id));
  }, []);

  const onRemoveFail = useCallback((id) => {
    toastify("error", "User remove failed");
  }, []);

  const onConfirm = useCallback(() => {
    removeUser(selectedUser?.id)
      .then(() => {
        onRemoveSuccess(selectedUser?.id);
      })
      .catch(() => onRemoveFail());
  }, [onRemoveFail, onRemoveSuccess, selectedUser?.id]);

  return {
    users,
    isLoading,
    totalPages,
    currentPage,
    setCurrentPage,
    setSelectedRoles,
    selectedRoles,
    columns,
    onCreateNew,
    toEditPage,
    setSelectedUser,
    selectedUser,
    confirmModal,
    setConfirmModal,
    onConfirm,
    onRemoveClick,
  };
};

export default useUsers;
