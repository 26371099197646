import React from 'react';
import { Chrono } from "react-chrono";
import { date } from 'src/helpers/date';
import {useTranslate} from "../../../helpers/useTranslate";
import {dateDifference} from "src/helpers/date";

export default function HistoryContainer({order_history}) {

    const {tr} = useTranslate();
    console.log(order_history,"history of order")

    const exportStatus = (h) => {
        switch(h) {
          case "created":
            return tr("Заказ создан"," Буйрутма түзүлдү");
          case "in processing":
            return tr("В обработке","Текшерүүдө");
          case "approved":
            return tr(" Подтвержден","Тастыкталды");
          case "appointed":
            return tr(" Назначен", " Дайындалды");
          case "on pick-up":
            return tr(" Принят ", " Кабыл алынды");
          case "arrived at sender":
            return tr(" Прибыл к отправителю"," Жөнөтүүчүгө келди ");
          case "on courier":
            return tr(" Курьер забрал", " Курьер аманатты алды");
          case "in sorting center":
            return tr(" В сортировочном центре"," Курьер аманатты өткөрүп берди");
          case "arrived at receiver":
            return tr(" Прибыл к получателю.","Алуучуга келди ");
          case "on wait":
            return tr("","");
          case "cancelled":
            return tr("","");
          case "completed":
            return tr(" Завершен."," Аяктады ");
          case "rejected":
            return tr(" Отказ."," Алуучу аманатты алуу баш тартты");
          case "on carrier":
            return tr(" упаковщик передал посылку. [CourierName2] магистральный перевозчик взял посылку.", " таңгакчы аманатты өткөрүп берди. [CourierName2] магистралдык ташуучу алды.");
          default:
            return ""
        }
      }

    const exportText = (h) => {
        switch(h.new_value.state) {
            case "created":
                return (h.author?.fullname + tr(" оформил заявку"," буйрутма берди"));
            case "in processing":
                return (h.author?.fullname + tr(" оператор взял заказ в обработку"," оператор буйрутманы текшерүүгө алды"));
            case "approved":
                return (h.author?.fullname + tr(" оператор подтвердил заявку"," оператор буйрутманы тастыктады"));
            case "appointed":
                return (h.author?.fullname + tr(" оператор назначил заказ на [CourierName]", " оператор буйрутмага [CourierName] дайындады"));
            case "on pick-up":
                return (h.author?.fullname + tr(" курьер принял заказ в исполнение ", " курьер буйрутманы аткарууга кабыл алды"));
            case "arrived at sender":
                return (h.author?.fullname + tr(" курьер прибыл к Отправителю"," курьер Жөнөтүүчүгө келди "));
            case "on courier":
                return (h.author?.fullname + tr(" курьер  взял посылку", " курьер аманатты алды"));
            case "in sorting center":
                return (h.author?.fullname + tr(" курьер передал посылку. [CourierName2] упаковщик взял посылку."," курьер аманатты өткөрүп берди. [CourierName2] таңгакчы алды."));
            case "arrived at receiver":
                return (h.author?.fullname + tr(" курьер приехал к Получателю."," курьер Алуучуга келди "));
            case "on wait":
                return (h.author?.fullname + tr("",""));
            case "cancelled":
                return (h.author?.fullname + tr("",""));
            case "completed":
                return (h.author?.fullname + tr(" Получатель взял посылку."," Алуучу аманатты алды "));
            case "rejected":
                return (h.author?.fullname + tr(" Получатель отказался взять посылку."," Алуучу аманатты алуу баш тартты"));
            case "on carrier":
                return (h.author?.fullname + tr(" упаковщик передал посылку. [CourierName2] магистральный перевозчик взял посылку.", " таңгакчы аманатты өткөрүп берди. [CourierName2] магистралдык ташуучу алды."));
            default:
                return ""
        }
    }

    return (
            <div style={{ width: "auto", height: "auto" }} className="bg-white text-dark">
                {order_history &&
                <Chrono items={[...order_history.map((h, i) => {
                    return {
                        title: date(h.date),
                        // cardTitle: dateDifference(h.date, order_history[i+1]?.date),
                        cardTitle: h.action_type === "status change" ? exportStatus(h.new_value.state) : "Изменения в деталях",
                        cardDetailedText: 
                            <>
                                <>
                                    {(h.action_type === "status change" ? exportText(h)
                                    :
                                    (h.author?.fullname + "(" + h.author_role +") обновил заказ"))}
                                </> <br /> <br />
                                <i className='text-success'>
                                    <b>{dateDifference(h.date, order_history[i+1]?.date)}</b>
                                </i>
                            </>,
                    }
                })]} 
                mode="VERTICAL" 
                hideControls 
                useReadMore={false}
                cardHeight={100}
                borderLessCards={true}
                theme={{ 
                    primary: "#2EB85C",
                    secondary: "#2EB85C",
                    cardBgColor: "white",
                    cardForeColor: "gray",
                    titleColor: "black",
                    textColor: "black"
                }}
                />}
            </div>
    )
}