import { CFormGroup, CInput, CLabel } from "@coreui/react";
import Select from "react-select";

export const FormControl = ({
  onChange,
  value,
  label,
  type,
  options,
  isMulti,
}) => {
  return (
    <CFormGroup>
      <CLabel>{label}</CLabel>
      {type === "select" ? (
        <SelectField
          options={options}
          onChange={onChange}
          value={value}
          isMulti={isMulti}
          label={label}
        />
      ) : (
        <InputField
          label={label}
          onChange={onChange}
          value={value}
          type={type}
        />
      )}
    </CFormGroup>
  );
};

const SelectField = ({ options, onChange, value, isMulti, label }) => {
  return (
    <Select
      isMulti={isMulti}
      isClearable
      options={options}
      onChange={onChange}
      value={value}
      placeholder={label}
    />
  );
};

const InputField = ({ label, onChange, value, type }) => {
  return (
    <CInput
      placeholder={label}
      onChange={(e) => type==='file' ? onChange(e.target.files[0]) : onChange(e.target.value)}
      value={value}
      type={type || "text"}
    />
  );
};
