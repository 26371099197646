import {
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow, CInput
} from "@coreui/react";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCouriers } from "../../redux/actions/courierActions";
import VerifyCourierForm from "./VerifyCourierForm";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { useModalState } from "../../helpers/useModal";
import { useTranslation } from "react-i18next";
import CourierBalanceReplenish from "./CourierBalanceReplenish";
import { fetchCourier, fetchCourierBlock, fetchCouriersAll } from "src/services/CourierService";
import { toastify } from "src/helpers/toast";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import CourierPassword from "./CourierPassword";
import CouriersTable from "./CouriersTable";
// import CourierForm from "./CourierForm";
import CourierFormNewDesign from "./corier-form-new-design/CourierFormNewDesign";
import ConfirmDeleteModal from "./ConfirmDelete";
import {fetchOffices} from "../../services/CompanyService";

export default function Couriers(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [courierList, setCourierList] = useState(null);
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("");
  const [offices, setOffices] = useState([]);
  const couriers = useSelector((state) => state.courier.couriers);
  const isLoading = useSelector((state) => state.courier.isLoading);
  const reFetchCouriers = async () => dispatch(getCouriers());
  const { isOpen, onToggle } = useModalState();
  const [modalTitle, setModalTitle] = useState("Modal Title");
  const [selectedCourier, setSelectedCourier] = useState({});
  const [modalForm, setModalForm] = useState(null);
  const statusList = [
      {name: "online", id: 1},
      {name: "on_delivery", id: 2},
      {name: "on_wait", id: 3},
      {name: "emergency", id: 4},
      {name: "at_lunch", id: 5},
      {name: "offline", id: 6},
  ]



  useEffect(() => {
    dispatch(setBreadcrumb([t("All Couriers")]));
    if(!props.all) {
      setCourierList(null);
      dispatch(getCouriers());
    } else {
      setLoading(true);
      fetchCouriersAll().then(res => {
        res.ok && res.json().then(d => {
          setCourierList(d);
          setLoading(false);
          console.log(d)
        })
      })
    }
    fetchOffices()
        .then((res) => res.ok && res.json().then((data) => setOffices(data)))
        .catch((err) => console.log(err));

  }, [dispatch, props.all, t]);

  const newCourierForm = () => {
    setSelectedCourier({});
    setModalTitle(t("Create Courier"));
    onToggle();
  };

  const verifyForm = () => {
    setModalForm("verify")
    setModalTitle(t("Verify Courier"));
    onToggle();
  };

  const blockCourier = (id, block) => {
    fetchCourierBlock(id, block).then(res => {
      if(res.ok) {
        toastify("success", t("Operation succeeded"));
        dispatch(getCouriers());
        onToggle();
      } else {
        toastify("error", "Error occured");
      }
    }).catch(err => console.log(err));
  };

  const selectCourier = (courier) => {
    fetchCourier(courier.id).then(res => {
      res.ok && res.json().then(d => setSelectedCourier(d));
    })
  }

  const onOfficeSelect = (office) => {
      if (!office){
          setCourierList(couriers)
      }else {
    setCourierList(couriers.filter(c => c?.delivery_office?.name === office?.label))
      }
  }

  const onStatusSelect = (status) => {
      if (!status){
          setCourierList(couriers)
      }else{
          setCourierList(couriers.filter(c => c?.status === status?.label))
      }
  }

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CRow>
          <CInput
              className="col-lg-3 col-md-8 col-sm-12"
              onChange={e => setSearch(e.target.value)} value={search}
              placeholder={t("Search")}/>

          <Select
              isClearable
              className="col-lg-3 col-md-8 col-sm-12"
              placeholder={t("Choose office")}
              // value={selectedOffice}
              options={offices && offices.map(o => ({label:o.name, value:o.id}))}
              onChange={(office) => onOfficeSelect(office)}
          />
          <Select
              isClearable
              className="col-lg-3 col-md-8 col-sm-12"
              placeholder={t("Status")}
              options={statusList.map(status => ({label: t(status.name), value: status.id}))}
              onChange={(status) => onStatusSelect(status)}
          />

        <CButton
          variant="outline"
          color="success"
          className="float-right"
          onClick={() => {
            newCourierForm();
            setModalForm("create");
            setSelectedCourier({});
            dispatch(setBreadcrumb(["Couriers", t("Create Courier")]));
          }}
        >
          {t("Create new Courier")}
        </CButton>
        </CRow>
      </CCardHeader>
      <CCardBody>
        {(props.all ? loading : isLoading) ? <div className="text-center"><MiniSpinner/></div> :
        <CouriersTable
        couriers={courierList || couriers} setModalForm={setModalForm} selectCourier={selectCourier}
        onToggle={onToggle} setModalTitle={setModalTitle} verifyForm={verifyForm} search={search} setSearch={setSearch}/>
      }
      </CCardBody>

{/** confirm modal */}
      {modalForm === "block" && <CModal show={isOpen} onClose={onToggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          {selectedCourier && (
            <CForm>
              <CFormGroup>
                <CLabel htmlFor="nf-username">
                 {selectedCourier.username}
                </CLabel>
              </CFormGroup>
            </CForm>
          )}
        </CModalBody>
        <CModalFooter>
          {selectedCourier && (
            <CRow>
              <CButton
                color="danger"
                onClick={() => blockCourier(selectedCourier.id, !selectedCourier.is_blocked)}
                className="mr-2"
              >
                {selectedCourier.is_blocked ? t("Unblock") : t("Block")}
              </CButton>
            </CRow>
          )}
        </CModalFooter>
      </CModal>}

      {modalForm === "verify" &&
        <VerifyCourierForm
          selectedCourier={selectedCourier}
          toggle={onToggle}
          modal={isOpen}
          modalTitle={modalTitle}
          reFetchCouriers={reFetchCouriers}/>}
      {modalForm === "create" && 
        // <CourierForm
        //   toggle={onToggle}
        //   modal={isOpen}
        //   modalTitle={modalTitle}
        //   reFetchCouriers={reFetchCouriers}
        //   setSelectedCourier={setSelectedCourier}
        // />

        <CourierFormNewDesign
          toggle={onToggle}
          modal={isOpen}
          modalTitle={modalTitle}
          reFetchCouriers={reFetchCouriers}
          setSelectedCourier={setSelectedCourier}
        />
      }
      {(modalForm === "edit" && selectedCourier) && 
        // <CourierForm
        //   toggle={onToggle}
        //   modal={isOpen}
        //   modalTitle={modalTitle}
        //   reFetchCouriers={reFetchCouriers}
        //   setSelectedCourier={setSelectedCourier}
        //   selectedCourier={selectedCourier}
        //   edit={true}
        // />

        <CourierFormNewDesign
        toggle={onToggle}
        modal={isOpen}
        modalTitle={modalTitle}
        reFetchCouriers={reFetchCouriers}
        setSelectedCourier={setSelectedCourier}
        selectedCourier={selectedCourier}
        edit={true}
        />
      }
        {modalForm === "password" &&
        <CourierPassword 
          onToggle={onToggle}
          isOpen={isOpen}
          modalTitle={modalTitle}
          reFetchCouriers={reFetchCouriers}
          selectedCourier={selectedCourier}/>} 
        {modalForm === "depositBalance" && 
        <CourierBalanceReplenish
            onToggle={onToggle}
            isOpen={isOpen}
            modalTitle={modalTitle}
            selectedCourier={selectedCourier}/>}
        {modalForm === "delete" && 
        <ConfirmDeleteModal
            onToggle={onToggle}
            isOpen={isOpen}
            modalTitle={modalTitle}
            selectedCourier={selectedCourier}/>}
    </CCard>
  );
}
