import CIcon from "@coreui/icons-react";
import { CButton, CTooltip } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { date } from "src/helpers/date";
import { toastify } from "src/helpers/toast";
import { useTranslate } from "src/helpers/useTranslate";

export default function Table({ orders, openConfirmChange, openDetails }) {
  const { t } = useTranslation();
  const { tr } = useTranslate();

  return (
    <table className="table table-responsive-lg">
      <thead>
        <tr>
          <th>#</th>
          <th>
            {t("Sender")} <CIcon name="cilArrowRight" /> {t("Receiver")}
          </th>
          <th>{t("Courier")}</th>
          <th>{t("Status")}</th>
          <th>{t("Date")}</th>
          <th>{t("Tariff")}</th>
          <th>{t("Price")}</th>
          <th>{t("Actions")}</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order) => (
          <tr className="order_table_row" key={order.id}>
            <td style={{ padding: "5px", width: "10%" }}>
              <b>{order.oid}</b>
            </td>
            <td
              onClick={() => openDetails(order)}
              style={{ cursor: "pointer", padding: "5px", width: "30%" }}
            >
              <span style={{ color: "black" }}>
                {order.location_from?.city &&
                  order.location_from?.city?.id !==
                    order.location_to?.city?.id &&
                  tr(
                    order.location_from?.city?.name_ru,
                    order.location_from?.city?.name_kg
                  ) + ", "}
                {tr(order.location_from?.name_ru, order.location_from?.name_kg)}{" "}
                <CIcon name="cilArrowRight" />{" "}
                {order.location_to?.city &&
                  order.location_from?.city?.id !==
                    order.location_to?.city?.id &&
                  tr(
                    order.location_to?.city?.name_ru,
                    order.location_to?.city?.name_kg
                  ) + ", "}
                {tr(order.location_to?.name_ru, order.location_to?.name_kg)}
              </span>
              <br />
              {order.description.substring(0, 40)}
              {order.description.length > 40 && "..."}
            </td>
            <td style={{ padding: "5px", width: "8%" }} className="text-center">
              {
                <>
                  <span>{order.delivery_service?.name}</span> <br />
                  <span>{order.courier?.user?.firstname}</span> <br />
                  <span>{order.courier?.user?.username}</span>
                </>
              }
            </td>
            <td style={{ width: "12%", padding: "5px" }}>
              {
                <div className="text-center">
                  {t(order.status)}
                  {order.status !== "cancelled" ? (
                    <CButton
                      className="btn btn-block btn-sm btn-danger"
                      onClick={() =>
                        openConfirmChange(order, "status", "cancelled")
                      }
                    >
                      {t("Cancel")}
                    </CButton>
                  ) : null}
                </div>
              }
            </td>
            <td style={{ padding: "5px" }} className="text-right">
              {date(order.created_at)}
            </td>
            <td style={{ padding: "5px" }}>
              <img
                alt=""
                src={order.package_type?.photo || ""}
                className="rounded-circle mr-1"
                width="15"
              />
              {order.tariff?.name}
            </td>
            <td style={{ padding: "5px" }} className="text-center">
              {order.final_price} <u>c</u>
            </td>
            <td style={{ padding: "5px" }} className="text-center">
              {/* <CTooltip content={t("Copy")}>
                <CIcon
                style={{ cursor: "pointer" }}
                name="cilCopy"
                onClick={() => addOrderDetailToClipboard(order)}
                />
            </CTooltip> */}
              <CTooltip content={t("Copy link")} placement="bottom">
                <CIcon
                  name="cilLink"
                  style={{ cursor: "pointer" }}
                  className="ml-1"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "http://dms.kg/orders/" + order.pid
                    );
                    toastify("info", "Link is copied");
                  }}
                />
              </CTooltip>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
