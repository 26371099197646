import axios from "axios";
import store from "../redux/store";
import { authHeader, authHeaderPost } from "../helpers/authHeader";

// export const SERVER_URL = (process.env.REACT_APP_MOD && process.env.REACT_APP_MOD === "master") ? "https://partner.smartpost.kg" : "https://staging.smartpost.kg";
export const SERVER_URL = 'https://dms.kg'
export default class ServerService {
  _baseApi = "";

  getItem = async () => {};

  deleteItem = async () => {};

  updateItem = async () => {};

  createItem = async () => {};
}

export const fetchCreateImg = (img) => {
  return axios({
    url: SERVER_URL + "/api/v1/upload/images/",
    method: "post",
    headers: authHeader(store.getState().auth.accessToken),
    data: img,
  });
};


// /api/v1/routes/
export const fetchRoutes = () => {
  return createApi("/api/v1/routes/")
};

export const createApi = (url, data) => {

  const options = {
    method: data?.method || "GET",
    headers: 
      (data?.method === "POST" || data?.method === "PATCH") 
      ? 
        new Headers(authHeaderPost(store.getState().auth.accessToken))
      :
        new Headers(authHeader(store.getState().auth.accessToken))
  }

  if(data?.body) {
    options.body = JSON.stringify(data.body);
  } 
  return fetch(SERVER_URL + url, options)
}

export const queryParamComposer = (paramsBody) => {
  let query = '';
  
  if(paramsBody) {
    Object.keys(paramsBody).forEach(key => {
      const currentParam = paramsBody[key];
      
      if(Array.isArray(currentParam)) {
        currentParam.forEach(value => {
          query = query + `${key}=${value}&`
        })
      } else {
        query = query + (currentParam ? `${key}=${currentParam}&` : '');
      }
    })
  }

  return query
}