import { CButton, CCard, CCardBody, CInput, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { date } from 'src/helpers/date'
import { fetchCourier } from 'src/services/CourierService'
import { ordersFetchCurrent } from 'src/services/OrderService'
import PrintStickerAmanat from '../orders/Order-Details/PrintStickerAmanat'

export default function CourierDetails() {

    const params = useParams()
    const {t} = useTranslation()

    const company = useSelector(state => state.auth.company)

    const [activeTab, setActiveTab] = useState("currentOrders")
    const [orders, setOrders] = useState([])
    const [courier, setCourier] = useState([])

    
    // const [selectedOrder, setSelectedOrder] = useState(null)

    useEffect(() => {
        console.log(params?.id)
        if(params?.id) {
            ordersFetchCurrent({courier_id: params.id, size: 100}).then(res => res.ok && res.json().then(d => {
                setOrders(d?.items)
            }))
            fetchCourier(params.id).then(res => res.ok && res.json().then(d => {
                setCourier(d)
            }))
        }
    }, [params])

    // const handlePrint = useReactToPrint({
    //     content: () => singleComponentRef.current,
    //   });
    const multiHandlePrint = useReactToPrint({
        content: () => multiComponentRef.current,
      });

    // useEffect(() => {
    //     if(selectedOrder) {
    //         handlePrint()
    //     }
    // }, [selectedOrder, handlePrint])


  const singleComponentRef = useRef();
  const multiComponentRef = useRef();

  const handleOrderSelect = (i, value) => {
      let orderList = [...orders]
      orderList[i].selected = value
      setOrders([...orderList])
    }
    
    const selectAll = (value) => {
      let orderList = [...orders].map(o => { return {...o, selected: value}})
      setOrders([...orderList])
      console.log(orderList)
  }
    return (
        <CCard>
            <CCardBody>
                <h4>{t("Courier")}: {courier?.firstname}</h4>
            <CTabs activeTab={activeTab}>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink data-tab="currentOrders" onClick={() => setActiveTab("currentOrders")}>
                            {t("Current Orders")}
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink data-tab="profile" onClick={() => setActiveTab("profile")}>
                            {t("Profile")}
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink data-tab="history" onClick={() => setActiveTab("history")}>
                            {t("History")}
                        </CNavLink>
                    </CNavItem>
                </CNav>

                <CTabContent>
                    <CTabPane data-tab="currentOrders">
                        <div className='my-4'>
                            <h5>{t("Количество")}: {orders.length}</h5>
                            {orders.length > 0 && <CButton color="info" className="float-right mb-2" onClick={() => {
                                multiHandlePrint()
                            }}>{t("Печатать выбранные")}</CButton>}
                            {orders.length > 0 ? <table className="table table-responsive-lg">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t("Sender")}</th>
                                        <th>{t("Receiver")}</th>
                                        <th>{t("Status")}</th>
                                        <th>{t("Date")}</th>
                                        <th>{t("Tariff")}</th>
                                        <th>{t("Redemption")}</th>
                                        <th>{t("Price")}</th>
                                        {/* <th>{t("Action")}</th> */}
                                        <th style={{width: "5%"}}>
                                            <CInput type='checkbox' onChange={e => selectAll(e.target.checked)}/>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((o, i) => 
                                        <tr key={o.id}>
                                            <td>{o.oid}</td>
                                            <td>{o.sender?.phone}<br/>{o.location_from?.name_kg}</td>
                                            <td>{o.receiver?.phone}<br/>{o.location_to?.name_kg}</td>
                                            <td>{t(o.status)}</td>
                                            <td>{date(o.created_at)}</td>
                                            <td>{o.tariff?.name}</td>
                                            <td>{o.redemption_amount}</td>
                                            <td>{o.final_price}</td>
                                            {/* <td className='text-center'>
                                                <CButton color='info' onClick={() => setSelectedOrder({...o})}>{t("Print")}</CButton>
                                            </td> */}
                                            <td className='text-center'>
                                                <CInput type='checkbox' onChange={e => handleOrderSelect(i, e.target.checked)} checked={o.selected}/>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table> : <div>Нет заказов</div>}
                            <div className="d-none">
                                <div ref={singleComponentRef} >
                                    {orders.map((o, i) => 
                                        <div key={o.id} className="pagebreak">
                                            <PrintStickerAmanat
                                                qrCode={"http://api.qrserver.com/v1/create-qr-code/?data=http://dms.kg/orders/" + o.pid}
                                                courierCompanyLogo={company?.navbar_logo} courierCompanyPhone={""} orderId={o.id} oid={o.oid}
                                                smartPost={""} sender={o.sender?.fullname} receiver={o.receiver?.fullname}
                                                senderPhone={o.sender?.phone} senderAddress={o.location_from}
                                                receiverPhone={o.receiver?.phone} receiverAddress={o.location_to}
                                                comment={o.description} redemption={o.redemption_amount}
                                                price={o.final_price} isPaid={o.is_paid}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div ref={multiComponentRef} >
                                    {orders.filter(o => o.selected).map((o, i) => 
                                        <div key={o.id} className="pagebreak">
                                            <PrintStickerAmanat
                                                qrCode={"http://api.qrserver.com/v1/create-qr-code/?data=http://dms.kg/orders/" + o.pid}
                                                courierCompanyLogo={company?.navbar_logo} courierCompanyPhone={""} orderId={o.id} oid={o.oid}
                                                smartPost={""} sender={o.sender?.fullname} receiver={o.receiver?.fullname}
                                                senderPhone={o.sender?.phone} senderAddress={o.location_from}
                                                receiverPhone={o.receiver?.phone} receiverAddress={o.location_to}
                                                comment={o.description} redemption={o.redemption_amount}
                                                price={o.final_price} isPaid={o.is_paid}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>    
                        </div>
                    </CTabPane>
                    <CTabPane data-tab="profile">
                        Courier Profile (Coming soon...)
                    </CTabPane>
                    <CTabPane data-tab="history">
                        History (Coming soon...)
                    </CTabPane>
                </CTabContent>
            </CTabs>
            </CCardBody>
        </CCard>
    )
}