import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  CBadge,
  CButton,
  CCol,
  CInput,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import { useTranslate } from "src/helpers/useTranslate";
import chevronUp from "src/assets/chevron-up.svg";
import chevronDown from "src/assets/chevron-down.svg";
import CIcon from "@coreui/icons-react";
import { fetchCreateImg } from "src/services/ServerService";
import { compressFile } from "src/helpers/fileCompresser";

export function ParcelFields({
  packagesTypes,
  updateOrderData,
  orderData,
  detectWeightFee,
}) {
  const updatePack = useCallback(
    (i, field, val) => {
      let packs = orderData.packages;
      packs[i] = { ...packs[i], [field]: val };
      updateOrderData("packages", packs);
    },
    [updateOrderData, orderData]
  );

  const deletePack = useCallback(
    (index) => {
      let packs = orderData.packages;
      updateOrderData(
        "packages",
        packs.filter((_, i) => index !== i)
      );
    },
    [updateOrderData, orderData]
  );

  useEffect(() => {
    if (orderData.packages && !orderData.packages[0]?.package_type_id) {
      updatePack(0, "package_type_id", packagesTypes[0]?.id);
    }
  }, [orderData, packagesTypes, updatePack]);
  const { t } = useTranslation();
  const { tr } = useTranslate();

  return (
    <div>
      <h6 className="text-dark text-uppercase my-n1 font-xs">{t("Parcel")}</h6>
      <hr className="mt-0 my-2" />

      {orderData.packages?.map((pack, i) => (
        <Package
          key={i}
          i={i}
          packagesTypes={packagesTypes}
          updatePack={(field, value) => updatePack(i, field, value)}
          pack={pack}
          tr={tr}
          t={t}
          detectWeightFee={detectWeightFee}
          isShowDeleteBtn={orderData.packages?.length > 1}
          deletePack={() => deletePack(i)}
        />
      ))}
      <CButton
        color="secondary"
        className="btn-block mt-2"
        size="sm"
        onClick={() => updateOrderData("packages", [...orderData.packages, {}])}
      >
        +
      </CButton>

      <div className="font-xs">
        <div>
          {t("Distance")}:{" "}
          {orderData.distanceBetween && orderData.distanceBetween}
        </div>
        <div>
          {t("Duration")}: {orderData.estimation && orderData.estimation}
        </div>
        <div>
          {orderData.destinationZone &&
            t("Fee zone") + ": " + orderData.destinationZone.name}
        </div>
      </div>
    </div>
  );
}

export const Package = ({
  packagesTypes,
  i,
  updatePack,
  pack,
  tr,
  t,
  detectWeightFee,
  isShowDeleteBtn,
  deletePack,
}) => {
  const [photoUp, setPhotoUp] = useState(null);
  const [showParams, setShowParams] = useState(false);

  const uploadImages = useCallback(
    (files) => {
      Promise.all(
        [...files].map((f) => {
          let formData = new FormData();
          formData.append("image", f);
          return fetchCreateImg(formData);
        })
      )
        .then((values) => {
          setPhotoUp("Фотографии загружены");
          const linkList = values.map((v) => v.data);
          updatePack(
            "photos",
            pack.photos ? [...pack.photos, ...linkList] : linkList
          );
        })
        .catch((err) => {
          setPhotoUp("Ошибка при загрузке фотографии");
        });
    },
    [updatePack, pack]
  );

  const handleFileChange = useCallback(
    (e) => {
      const files = e.target.files;
      setPhotoUp("Загружается");
      Promise.all(
        [...files].map((file) => {
          return compressFile(file);
        })
      ).then((values) => {
        uploadImages(values);
      });
    },
    [uploadImages]
  );
  const header = useMemo(
    () => pack.selectedPack || packagesTypes[0],
    [pack, packagesTypes]
  );
  return (
    <div>
      <CLabel className="mt-1 w-100 border-bottom rounded py-1">
        <span>{tr(header?.name_ru, header?.name_kg)}</span>
        <span
          className="float-right"
          onClick={() => setShowParams(!showParams)}
        >
          <img src={showParams ? chevronUp : chevronDown} alt="toggle" />
        </span>
      </CLabel>
      {showParams && (
        <div className="border-right border-left border-bottom rounded p-1">
          <CSelect
            size="sm"
            onChange={(e) => {
              const currentId = Number(e.target.value);
              const res = packagesTypes.find((pack) => pack.id === currentId);
              updatePack("selectedPack", res);
              updatePack("package_type_id", res?.id);
              updatePack("weight", res?.max_weight);
              updatePack("height", res?.height);
              updatePack("width", res?.width);
              updatePack("length", res?.length);
            }}
            value={
              pack.package_type_id ||
              pack.selectedPack?.id ||
              packagesTypes[0]?.id
            }
          >
            {packagesTypes.map((pack) => (
              <option key={pack.id} value={pack.id}>
                {tr(pack.name_ru, pack.name_kg)}
              </option>
            ))}
          </CSelect>
          <CRow className="px-0">
            <CCol className="pr-1" sm="6">
              <CLabel className="font-xs my-0">{t("Weight") + "(кг)"}</CLabel>
              <CInput
                size="sm"
                placeholder={t("Weight")}
                className="mb-2"
                type="number"
                value={pack.weight || ""}
                onChange={(e) => {
                  updatePack("weight", e.target.value);
                  detectWeightFee(
                    pack.amount *
                      Math.max(
                        e.target.value,
                        (pack.height * pack.width * pack.length) / 4000
                      )
                  );
                }}
              />
            </CCol>
            <CCol className="pl-1" sm="6">
              <CLabel className="font-xs my-0">{t("Amount")}:</CLabel>
              <CInput
                size="sm"
                placeholder={t("Amount")}
                className="mb-2"
                type="number"
                value={pack.amount || ""}
                onChange={(e) => {
                  updatePack("amount", e.target.value);
                  detectWeightFee(
                    e.target.value *
                      Math.max(
                        pack.weight,
                        (pack.height * pack.width * pack.length) / 4000
                      )
                  );
                }}
              />
            </CCol>
          </CRow>
          <CRow className="px-0">
            <CCol className="pr-1">
              <CLabel className="font-xs my-0">{t("Height") + " см"}</CLabel>
              <CInput
                size="sm"
                placeholder={t("Height")}
                type="number"
                value={pack.height || ""}
                onChange={(e) => {
                  updatePack("height", e.target.value);
                  detectWeightFee(
                    pack.amount *
                      Math.max(
                        pack.weight,
                        (e.target.value * pack.width * pack.length) / 4000
                      )
                  );
                }}
              />
            </CCol>
            <CCol className="px-1">
              <CLabel className="font-xs my-0">{t("Width") + " см"}</CLabel>
              <CInput
                size="sm"
                placeholder={t("Width")}
                type="number"
                value={pack.width || ""}
                onChange={(e) => {
                  updatePack("width", e.target.value);
                  detectWeightFee(
                    pack.amount *
                      Math.max(
                        pack.weight,
                        (pack.height * e.target.value * pack.length) / 4000
                      )
                  );
                }}
              />
            </CCol>
            <CCol className="pl-1">
              <CLabel className="font-xs my-0">{t("Length") + " см"}</CLabel>
              <CInput
                size="sm"
                placeholder={t("Length")}
                type="number"
                value={pack.length || ""}
                onChange={(e) => {
                  updatePack("length", e.target.value);
                  detectWeightFee(
                    pack.amount *
                      Math.max(
                        pack.weight,
                        (pack.height * pack.width * e.target.value) / 4000
                      )
                  );
                }}
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol className="pr-1" sm="6">
              <CLabel className="font-xs my-0">{t("All weight")}</CLabel>
              <CInput
                size="sm"
                placeholder={t("Weight")}
                className="mb-2"
                value={(pack.amount || 1) * pack.weight || 0 + " кг"}
                disabled
              />
            </CCol>
            <CCol className="pl-1" sm="6">
              <CLabel className="font-xs my-0">{t("Volume weight")}:</CLabel>
              <CInput
                className="mb-2"
                size="sm"
                value={
                  (
                    ((pack.amount || 1) *
                      pack.height *
                      pack.width *
                      pack.length) /
                    4000
                  ).toFixed(2) + " кг"
                }
                disabled
              />
            </CCol>
          </CRow>

          <CLabel>{t("Upload a photo")}</CLabel>
          <CInput type="file" multiple onChange={handleFileChange} />
          <CBadge color="primary">{photoUp}</CBadge>
          {pack.photos &&
            pack.photos.map((photo, i) => (
              <div
                key={i}
                className="my-1 pb-1 d-flex justify-content-between"
                style={{ borderBottom: "1px dotted gray" }}
              >
                <img alt="s" src={photo} style={{ maxHeight: "30px" }} />
                <CIcon
                  name="cilX"
                  className="sp-link"
                  onClick={() =>
                    updatePack(
                      "photos",
                      pack.photos.filter((ph) => ph !== photo)
                    )
                  }
                />
              </div>
            ))}
          {isShowDeleteBtn && (
            <CButton
              onClick={deletePack}
              size="sm"
              variant="outline"
              color="danger"
              className="btn-block mt-2"
            >
              {t("Delete")}
            </CButton>
          )}
        </div>
      )}
    </div>
  );
};
