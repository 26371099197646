import newOrder from "../assets/icons/newOrder.svg";
import current from "../assets/icons/current.svg";
import completedOrders from "../assets/icons/completedOrders.svg";
import operators from "../assets/icons/operators.svg";
import couriers from "../assets/icons/couriers.svg";
import couriersonmap from "../assets/icons/couriersonmap.svg";
import tariffs from "../assets/icons/tariffs.svg";
import packageTypes from "../assets/icons/packageTypes.svg";
import transport from "../assets/icons/transport.svg";
import extra from "../assets/icons/extra.svg";
import statuse from "../assets/icons/statuse.svg";
import clients from "../assets/icons/clients.svg";
import addresses from "../assets/icons/addresses.svg";
import geography from "../assets/icons/geography.svg";
import routes from "../assets/icons/routes.svg";
import organizations from "../assets/icons/organizations.svg";
import extrazones from "../assets/icons/extrazones.svg";
import couriersserv from "../assets/icons/couriersserv.svg";
import wallet from "../assets/icons/Wallet.svg";

export const navv = [
  {
    type: "title",
    name: "Orders",
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Create Order",
    to: "/new-order",
    badge: { color: "info", text: "NEW" },
    icon: newOrder,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Create Order",
    to: "/partner/create-order",
    badge: { color: "info", text: "NEW" },
    icon: newOrder,
    permissions: ["partner"],
  },
  {
    type: "item",
    name: "Current Orders",
    to: "/current-orders",
    icon: current,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Current Orders",
    to: "/partner/orders",
    icon: current,
    permissions: ["partner"],
  },
  {
    type: "item",
    name: "All Orders",
    to: "/orders-archive",
    icon: completedOrders,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "All Orders",
    to: "/partner/orders-archive",
    icon: completedOrders,
    permissions: ["partner"],
  },
  {
    type: "item",
    name: "Current Orders Map",
    to: "/orders-map",
    icon: current,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Act Orders",
    to: "/act-orders",
    icon: current,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "title",
    name: "Users",
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Offices",
    to: "/offices",
    icon: couriersserv,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "users",
    name: "Users",
    to: "/users",
    icon: operators,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Partners",
    to: "/partners",
    icon: couriersserv,
    permissions: ["super_admin", "admin"],
  },
  {
    type: "title",
    name: "Clients",
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Clients",
    to: "/clients",
    icon: clients,
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Organizations",
    to: "/organizations",
    icon: organizations,
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "title",
    name: "Users",
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Operators",
    to: "/operators",
    icon: operators,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Couriers",
    to: "/couriers",
    icon: couriers,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Couriers on map",
    to: "/couriers-map",
    icon: couriersonmap,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Carriers",
    to: "/carriers",
    icon: couriers,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "title",
    name: "Administration",
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Tariffs",
    to: "/tariffs",
    icon: tariffs,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Company Packages Types",
    to: "/company-package-types",
    icon: packageTypes,
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Transports",
    to: "/transports",
    icon: transport,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Extra",
    to: "/extra",
    icon: extra,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Polygons",
    to: "/polygons",
    icon: extrazones,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Statuses",
    to: "/company-statuses",
    icon: statuse,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Notification Settings",
    to: "/company-sms-settings",
    icon: statuse,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "title",
    name: "Reports",
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Reports",
    to: "/reports",
    icon: statuse,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Reports",
    to: "/reports-for-partners",
    icon: statuse,
    permissions: ["partner"],
  },
  {
    type: "item",
    name: "Graphics",
    to: "/graphics",
    icon: statuse,
    permissions: ["super_admin", "admin", "operator", "partner"],
  },
  {
    type: "item",
    name: "Courier payments",
    to: "/courier-payments",
    icon: wallet,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "title",
    name: "Database",
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Company Address",
    to: "/company-addresses",
    icon: addresses,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "item",
    name: "Routes",
    to: "/routes",
    icon: routes,
    permissions: ["super_admin", "admin", "operator"],
  },
  {
    type: "title",
    name: "Map",
    permissions: ["super_admin"],
  },
  {
    type: "item",
    name: "Settlements",
    to: "/cities",
    icon: geography,
    permissions: ["super_admin"],
  },
  {
    type: "item",
    name: "Settlements On Map",
    to: "/cities-districts-on-map",
    icon: geography,
    permissions: ["super_admin"],
  },
  {
    type: "item",
    name: "Addresses On Map",
    to: "/addresses-on-map",
    icon: geography,
    permissions: ["super_admin"],
  },
  {
    type: "item",
    name: "Courier Company Addresses",
    to: "/courier-company-addresses",
    icon: geography,
    permissions: ["super_admin"],
  },
  {
    type: "item",
    name: "New addresses",
    to: "/newaddress",
    icon: geography,
    permissions: ["super_admin"],
  },
  {
    type: "item",
    name: "База адресов",
    to: "/addresses-base",
    icon: geography,
    permissions: ["super_admin"],
  },
];
