import React, {useEffect, useState} from 'react';
import {CCol, CInput, CLabel, CRow} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useTranslate} from "../../../../helpers/useTranslate";

const TransportAndRoutes = (props) => {

    const {
        selectedCourier,
        routes,
        transports,
        onUploadPhoto,
        setRadioTypeIsActive,
        initForm,
        setInitForm,
        takeRoute,
        activePage,
        selectedTransport,
        setSelectedTransport,
        setRoute,
        route
    } = props

    const { tr } = useTranslate();
    const { t, i18n } = useTranslation();
    const [fieldChecked, setFieldChecked] = useState({
        routeField: false,
        transportField: false
    })

    useEffect(() => {
        if(selectedCourier){
            if(selectedCourier?.routes?.length){
                setFieldChecked(state => ({...state, routeField: true}))
            }if(selectedCourier?.transport?.id){
                setFieldChecked(state => ({...state, transportField: true}))
            }
        }
    }, [selectedCourier])

    useEffect(() => {
        setRadioTypeIsActive(prevState => ({...prevState, transportAndRoutes: !Object.values(fieldChecked).includes(false)}))
    }, [fieldChecked])

    const onRouteSelect = (route) => {
        takeRoute(route)
        let res = route
        setRoute(res)

        if (res && res.length){
            setFieldChecked(state => ({...state, routeField: true}))
        } else {
            setFieldChecked(state => ({...state, routeField: false}))
        }
    }

    const onAutoTypeSelect = (autoType) => {
        let res = autoType
        setSelectedTransport(res)
        setInitForm(state => ({...state, transport_id: res?.value}))
        if(res){
            setFieldChecked(state => ({...state, transportField: true}))
        }else{
            setFieldChecked(state => ({...state, transportField: false}))
        }
    }

    return (
        <CRow className="ml-2" style={{ display: activePage === 3 ? "block" : "none" }}>
            <CCol>
                 <CLabel>{t("Routes")}*</CLabel>
            </CCol>

            <div style={{width: "100%"}} className='d-flex align-items-center'>
                <CCol xs={10}>
                    <Select
                        isMulti
                        isClearable
                        placeholder="Выберите маршрут"
                        onChange={(route) => {
                            onRouteSelect(route)
                        }}
                        value={route}
                        options={routes.map(route => {
                            return {
                                value: route.id,
                                label: tr(route.name_ru, route.name_kg),
                                ...route
                            }
                        })}
                    />
                </CCol>
                <CIcon
                    name={fieldChecked.routeField ? "cilCheckCircle" : "cilWarning" }
                    className={"ml-2 " + (fieldChecked.routeField ? "text-success" : "text-danger")}
                    size="xl"
                />
            </div>

            <CCol className="mt-2">
                <CLabel>{t("Transport type")}*</CLabel>
            </CCol>
            <div style={{width: "100%"}} className='d-flex align-items-center'>
                <CCol xs={10}>
                    <Select
                        placeholder="Выберите транспорт"
                        isClearable
                        value={selectedTransport}
                        options={transports.map(transport => ({ value: transport.id, label: tr(transport.name_ru, transport.name_kg)
                        }))}
                        onChange={(autoType) => onAutoTypeSelect(autoType)}
                    />
                </CCol>
                <CIcon
                    name={fieldChecked.transportField ? "cilCheckCircle" : "cilWarning" }
                    className={"ml-2 " + (fieldChecked.transportField ? "text-success" : "text-danger")}
                    size="xl"
                />
            </div>

            <CCol className="mt-2" xs={10}>
                <CLabel>{t("Transport Model")}</CLabel>
                <CInput
                    type="text"
                    placeholder={t("Transport Model")}
                    name="transport_model"
                    value={initForm.transport_model}
                    onChange={(e) => setInitForm(state => ({...state, [e.target.name]: e.target.value}))}
                />
            </CCol>

            <CCol className="mt-2" xs={10}>
                <CLabel>{t("Auto Number")}</CLabel>
                <CInput
                    type="text"
                    placeholder={t("Auto Number")}
                    name="auto_number"
                    value={initForm.auto_number}
                    onChange={(e) => setInitForm(state => ({...state, [e.target.name]: e.target.value}))}
                />
            </CCol>

            <CCol className="mt-2" xs={10}>
                    <CLabel>{t("Transport image")}*</CLabel>
                        <CInput
                            type="file"
                            onChange={(e) => onUploadPhoto(e, "transport_images")}
                        />
            </CCol>

            <CCol  className="mt-3" xs={10}>
                <CRow>
                    <CCol>
                        <CLabel>{t("Max Weight")}</CLabel>
                        <CInput
                            type="text"
                            placeholder={t("0 кг")}
                            name="max_weight"
                            value={initForm.max_weight}
                            onChange={(e) => setInitForm(state => ({...state, [e.target.name]: e.target.value}))}
                        />
                    </CCol>
                    <CCol>
                        <CLabel>{t("Max Volume")}</CLabel>
                        <CInput
                            type="text"
                            placeholder={t("0 куб.м.")}
                            name="max_volume"
                            value={initForm.max_volume}
                            onChange={(e) => setInitForm(state => ({...state, [e.target.name]: e.target.value}))}
                        />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    );
};

export default TransportAndRoutes;