import React from "react";

import { useTranslation } from "react-i18next";
import { CTextarea } from "@coreui/react";

export function CommentFields({ orderData, updateOrderData }) {
  const { t } = useTranslation();

  return (
    <div>
      <h6 className="text-dark text-uppercase my-n1 font-xs">
        {t("Comments")}
      </h6>
      <hr className="mt-0 my-2" />
      <CTextarea
        rows="7"
        placeholder={t("Comments")}
        className="mb-2"
        value={orderData.description || ""}
        onChange={(e) => updateOrderData("description", e.target.value)}
      />
    </div>
  );
}
