import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getUser } from "src/services/UsersService";
import UsersForm from "./Form/UsersForm";

export const EditUser = () => {
  const { t } = useTranslation();

  const params = useParams();
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    getUser(params.id)
      .then((res) => {
        res.json().then((data) => setCurrentUser(data));
      })
      .catch((err) => console.log(err));
  }, [params]);
  return (
    <UsersForm title={t("Edit User")} currentUser={currentUser} />
  );
};
