import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import FullSpinner from "../components/spinners/FullSpinner";
import { routelist } from "./routelist";
import PublicOrderPage from "./PublicOrderPage";
import store from "src/redux/store";
import {
  setAtsCard,
  setCallingClient,
  setConectionStatus,
} from "src/redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import { orderListUpdate, removeOrder } from "src/redux/actions/orderActions";
import { courierListUpdate } from "src/redux/actions/courierActions";
import DefaultLayout from "src/containers/DefaultLayout";

const LoginPage = lazy(() => import("./LoginPage"));
const RegisterPage = lazy(() => import("./RegisterPage"));
const Page404 = lazy(() => import("./Page404"));

export default function Router() {
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(null);

  const token = store.getState().auth.accessToken;

  // const socket = useMemo(
  //   () =>
  //     (token && loggedIn)
  //       ? new WebSocket(
  //           "wss://" +
  //             (process.env.REACT_APP_MOD === "master"
  //               ? "dms.kg"
  //               : "dms.kg") +
  //             "/ws/?token=" +
  //             token
  //         )
  //       : null,
  //   [token, loggedIn]
  // );

  useEffect(() => {
    console.log("worked");
    const socket =
      token || loggedIn
        ? new WebSocket(
            "wss://" +
              (process.env.REACT_APP_MOD === "master" ? "dms.kg" : "dms.kg") +
              "/ws/?token=" +
              token
          )
        : null;
    if (socket) {
      socket.onopen = () => {
        dispatch(setConectionStatus("ONLINE"));
        console.log("opened");
      };
      socket.onerror = () => {
        dispatch(setConectionStatus("OFFLINE"));
      };

      socket.onmessage = (e) => {
        let message = JSON.parse(e.data);
        console.log("msg wbsckt", message);
        if (message.event_type === "order_data") {
          dispatch(orderListUpdate(message.data));
        } else if (message.event_type === "order_remove") {
          dispatch(removeOrder(message.data));
        } else if (message.event_type === "courier_data") {
          dispatch(courierListUpdate(message.data));
        } else if (message.event_type === "new_incoming_call") {
          dispatch(setCallingClient(message.data));
          dispatch(setAtsCard(true));
        }
      };
      return () => {
        socket.close();
      };
    }
  }, [dispatch, loggedIn, token]);

  return (
    <Suspense fallback={<FullSpinner />}>
      <Switch>
        <Route exact path="/orders/:id">
          <PublicOrderPage />
        </Route>
        <Route exact path="/login">
          <LoginPage setLoggedIn={setLoggedIn} />
        </Route>
        <Route exact path="/register">
          <RegisterPage />
        </Route>
        {routelist.map((r, i) => (
          <Route path={r.path} key={i} exact={r.exact}>
            <DefaultLayout>{r.component}</DefaultLayout>
          </Route>
        ))}
        <Route>
          <Page404 />
        </Route>
      </Switch>
    </Suspense>
  );
}
