import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUser, updateUser } from "src/services/UsersService";
import { fetchOffices } from "src/services/CompanyService";

import { compressFile } from "src/helpers/fileCompresser";
import { fetchCreateImg } from "src/services/ServerService";
import { toastify } from "src/helpers/toast";
import { useHistory } from "react-router-dom";
import { fetchPartners } from "src/services/PartnerService";

const useForm = ({ isNew, currentUser }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [offices, setOffices] = useState([]);
  const [partners, setPartners] = useState([]);
  const [userForm, setUserForm] = useState({
    username: "",
    fullname: "",
    roles: [],
    password: "",
    delivery_office_id: null,
    photo: "",
    birthdate: "",
    // transport_images: [],
    // passport_front: "",
    // passport_back: "",
    // personal_number: "",
    // auto_number: "",
    // max_weight: 0,
    // max_volume: 0,
    // account_number: "",
  });

  useEffect(() => {
    fetchOffices()
      .then((res) => {
        res.json().then((data) => setOffices(data));
      })
      .catch((err) => console.log(err));
    fetchPartners()
      .then((res) => {
        res.json().then((data) => setPartners(data));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    currentUser && setUserForm({ ...currentUser, ...currentUser.profile });
  }, [currentUser]);

  const onChangeField = useCallback((field, value) => {
    setUserForm((formData) => ({ ...formData, [field]: value }));
  }, []);

  const composeSelectObject = useCallback(
    (list) => {
      return (
        list?.map((role) => ({
          value: role.toLowerCase(),
          label: t(role),
        })) || []
      );
    },
    [t]
  );

  const onSubmitSuccess = useCallback(
    (typeText) => {
      toastify("success", `User ${typeText} successfully`);
      history.push("/users");
    },
    [history]
  );

  const onFetchError = useCallback((error) => {
    toastify("error", "Something has gone wrong");
    console.log(error.detail);
  }, []);

  const onSubmit = useCallback(() => {
    const formData = { ...userForm, birthdate: userForm.birthdate || null };
    (isNew ? createUser(formData) : updateUser(formData, formData?.id))
      .then((response) => {
        const result = response.json();
        result.then((data) =>
          response.ok ? console.log(data) : toastify("error", data.detail)
        );
        response.ok && onSubmitSuccess(isNew ? "created" : "updated");
      })
      .catch((err) => onFetchError(err));
  }, [isNew, userForm, onSubmitSuccess, onFetchError]);

  const [photoUpStatus, setPhotoUpStatus] = useState(null);
  const uploadImages = useCallback((file, onUploaded) => {
    let formData = new FormData();
    formData.append("image", file);
    const uploading = fetchCreateImg(formData);

    uploading
      .then((res) => {
        setPhotoUpStatus("Фотографии загружены");
        const link = res.data;
        onUploaded(link);
      })
      .catch((err) => {
        setPhotoUpStatus("Ошибка при загрузке фотографии");
      });
  }, []);
  const handleFileChange = useCallback(
    (field, file) => {
      setPhotoUpStatus("Загружается");
      compressFile(file).then((value) => {
        uploadImages(value, (link) => onChangeField(field, link));
      });
    },
    [uploadImages, onChangeField]
  );

  return {
    userForm,
    onChangeField,
    handleFileChange,
    photoUpStatus,
    onSubmit,
    composeSelectObject,
    offices,
    partners,
  };
};

export default useForm;
