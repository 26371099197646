const { fetchCreateClient } = require("src/services/ClientService");
const { fetchCompanyAddressCreate } = require("src/services/CompanyService");

export const generatePromises = (orderData, orderFormBody, company) => {
  let createFetchs = [[], [], [], []];

  if (!orderData.selectedClientSender?.id) {
    createFetchs[0] = generateClientCreater(
      orderData.selectedClientSender,
      orderData.senderFullname
    );
  }
  if (
    orderData.selectedClientReceiver &&
    !orderData.selectedClientReceiver?.id
  ) {
    createFetchs[1] = generateClientCreater(
      orderData.selectedClientReceiver,
      orderData.receiverFullname
    );
  }
  if (!orderFormBody.location_from_id) {
    createFetchs[2] = generateAddressCreater(
      orderData.selectedAddressSender,
      orderData.selectedCitySender,
      orderData.selectedDistrictSender,
      company
    );
  }
  if (orderData.selectedAddressReceiver && !orderFormBody.location_to_id) {
    createFetchs[3] = generateAddressCreater(
      orderData.selectedAddressReceiver,
      orderData.selectedCityReceiver,
      orderData.selectedDistrictReceiver,
      company
    );
  }
  return createFetchs;
};

const generateClientCreater = (client, fullname) => {
  let phone = client.label;
  if (phone.substring(0, 1) === "0") {
    phone = "+996" + phone.substring(1, phone.length);
  } else if (phone.substring(0, 3) === "996") {
    phone = "+" + phone;
  } else if (phone.substring(0, 1) !== "+") {
    phone = "+996" + phone;
  }
  console.log('ph', phone)
  return fetchCreateClient({
    fullname: client.fullname || fullname || phone,
    phone: phone,
    username: phone
  });
};

const generateAddressCreater = (address, city, district, company) => {
  return fetchCompanyAddressCreate({
    name_kg: address.label,
    name_ru: address.label,
    city_id: city ? city.id : company?.city ? company.city.id : 3,
    district_id: district?.id,
    lat: address.lat,
    lng: address.lng,
  });
};
