export const checkFieldsBeforeConfirm = (orderData) => {
    return new Promise((resolve, reject) => {
        // if(!orderData.selectedClientSender) {
        //     reject({title: "Sender client", reason: "No sender"})
        // } else if(!orderData.selectedAddressSender) {
        //     reject({title: "Sender address", reason: "No sender address"})
        // } else {
        //     resolve("No errors");
        // }
        resolve("No errors");
    })
}

export const uniqualize = (address) => {
    return address.reduce((acc, current) => {
        if(acc.filter(a => a.id === current.id).length < 1) {
            acc.push(current)
        }
        return acc;
    }, [])
}

export const packageConstructor = (orderData, packagesTypes) => {
    return {
        "amount": orderData.amount,
        "weight": orderData.weight,
        "height": orderData.height,
        "width": orderData.width,
        "length": orderData.length,
        "volume_weight": (orderData.height * orderData.width * orderData.length / 4000),
        "package_type_id": orderData.selectedPack ? orderData.selectedPack.id : packagesTypes[0]?.id,
        "photos": orderData.sender_photos || [],
    }
}

export const buildOrderBody = (orderData, packagesTypes, tariffs) => {
    return {
        "sender_id": orderData.selectedClientSender?.id,
        "sender_phone": orderData.senderPhone,
        "location_from_id": orderData.selectedAddressSender?.id,
        "location_from_flat": orderData.location_from_flat,
        "location_from_floor": orderData.location_from_floor,
        "location_to_flat": orderData.location_to_flat,
        "location_to_floor": orderData.location_to_floor,
        "receiver_id": orderData.selectedClientReceiver?.id,
        "receiver_phone": orderData.receiver_phone,
        "location_to_id": orderData.selectedAddressReceiver?.id,
        "city_from_id": orderData.selectedCitySender?.id,
        "city_to_id": orderData.selectedCityReceiver?.id,
        "district_from_id": orderData.selectedDistrictSender?.id,
        "district_to_id": orderData.selectedDistrictReceiver?.id,
        "package_type_id": orderData.selectedPack ? orderData.selectedPack.id : packagesTypes[0]?.id,
        "redemption_amount": orderData.redemption_amount || 0,
        "currency_id": 1,
        "courier_id": orderData.selectedCourier ? orderData.selectedCourier.id : null,
        "transports": orderData.selectedTransport?.map(tr => (tr.label!=="Все" && tr.label!=="Баары") && tr.id) || [],
        "tariff_id": orderData.selectedTariff ? orderData.selectedTariff.id : tariffs[0]?.id,
        "extra_fees": {
            "zone_fee": orderData.zoneFee,
            "weight_fee": orderData.weightFee,
            "redemption_fee": orderData.redemptionFee
        },
        "calculated_price": 0 + (orderData.selectedTariff ? orderData.selectedTariff.price : tariffs[0]?.price) || 0 + (orderData.zoneFee || 0) + (orderData.weightFee || 0) + (orderData.redemptionFee || 0),
        "final_price": orderData.final_price || 0,
        "description": orderData.description || "",
        "source": "operator web",
        "paying_side": orderData.paying_side,
        "packages": orderData.packages,
        "amount": orderData.amount,
        "weight": orderData.weight,
        "height": orderData.height,
        "width": orderData.width,
        "length": orderData.length,
        "volume_weight": (orderData.height * orderData.width * orderData.length / 4000),
        "pickup_time": orderData.pickUpTimeStamp?.toISOString() || undefined,
        "delivery_time": orderData.deliveryTimeStamp?.toISOString() || undefined,
        "distance": parseFloat(orderData.distanceBetween) * 1000, 
        "send_sms_sender": orderData.send_sms_sender,
        "send_sms_receiver": orderData.send_sms_receiver,
        "redemption_payment_method": orderData.redemption_amount > 0 ? orderData.redemption_payment_method || "cash" : orderData.redemption_payment_method,
        "redemption_payment_type": orderData.redemption_amount > 0 ? orderData.redemption_payment_type || "redemption" : orderData.redemption_payment_type,
        // "redemption_is_paid": orderData.redemption_is_paid,
        "sender_payment_amount": orderData.sender_payment_amount || 0,
        "sender_payment_method": orderData.sender_payment_method || "cash",
        "sender_payment_is_paid": orderData.sender_payment_is_paid || false,
        "sender_payment_type": orderData.sender_payment_type || "service",
        "receiver_payment_amount": orderData.receiver_payment_amount || 0,
        "receiver_payment_method": orderData.receiver_payment_method || "cash",
        "receiver_payment_is_paid": orderData.receiver_payment_is_paid || false,
        "receiver_payment_type": orderData.receiver_payment_type || "service",
        "delivery_service_id": orderData.deliveryService?.id,
        "invoice_organization_sender": orderData.invoice_organization_sender?.id,
        "invoice_organization_receiver": orderData.invoice_organization_receiver?.id,
        "sender_photos": orderData.sender_photos || [],
        "status": orderData.status?.value,
    }
}